function ShopcartHovercard() {

    var _timeoutId = null;
    var _timeoutInterval = 750;

    this.init = function () {
        $(document).on("cart-hovercard", _load);

        $(document).on("mouseover", ".js__seq-shopcart-btn", function () {
            _timeoutId = window.setTimeout(_load, _timeoutInterval);
        });

        // Cancel hover action if mouse moved out within 2 sec
        $(document).on("mouseout", ".js__seq-shopcart-btn", function () {
            window.clearTimeout(_timeoutId);
            $(".js__seq-shopcart-hovercard").fadeOut(120);
        });
    };

    var _load = function () {
        $.ajax({
            url: "/utilities/shopping-cart",
            /*
            * Refer to index.php for details about why a GET function uses POST method
            */
            method: "POST",
            data: {
                shoppingCart: localStorage.getItem("VSV2_SEQ_ShoppingCart"),
                hc: 1
            },
            success: function (response) {
                response = JSON.parse(response);
                $(".js__seq-shopcart-hovercard-products-wrapper").html(response.html);
                $(".js__seq-shopcart-hovercard").fadeIn(120);
            },
            error: function (response) {
                alert("ERROR; Could not Fetch Shopping Cart details");
            }
        });
    };
}

var schc = new ShopcartHovercard();
schc.init();
module.exports = schc;