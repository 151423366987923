function isTouchDevice() {
    return (("ontouchstart" in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
}

global.globalSurfaceConfig = {
    surface: null,
    surfaceContainer: null,

    scaleFactor: 1,
    bgScaleFactor: 1,
    oldScaleCoefficient: 1,
    scaleCoefficient: 1,
    ratio: window.innerWidth / window.innerHeight,
    width: 0,
    height: 0,
    effectiveWidth: 0,

    isTouchEnabled: isTouchDevice(),

    currency: null,
    language: null,

    tutorialLaunched: false,

    isClosed: false
};

import "./surface_builder";
import "./surface_static";