function ShelfDiscounts() {

    var $_wrapper = $("#js__discounts-wrapper");
    var $_dsId = $(".js__discounts-datasrc-id");

    this.init = function () {

        $(document).on("click", ".js__discounts-btn-add", _appendLine);
        $(document).on("click", ".js__discounts-btn-delete", _deleteLine);
        $(document).on("change", ".js__discounts-datasrc-select", _handleDsChange);
    };

    var _handleDsChange = function () {

        var dsId = this.value;

        if (dsId == undefined)
            return;

        /*
        * If in new Shelf form and Discounts wrapper is still empty,
        * skip showing the warning message as it is not relevant;
        * Only change input value, which is the default for change event.
        */
        if (!document.querySelector(".js__edit-entity-form") &&
            $_wrapper.children().length === 0) {
            $_dsId.val(dsId);
            return;
        }

        /*
        * If changing Datasource in the Edit Shalf form,
        * the case becomes sensitive for multiple reasons:
        * 1. Changing DS will result in different Products being available in the Shelf Builder
        * 2. Previous Discounts will cease to exist, because those were bound to Products
        *    belonging to the older set
        * For these reasons, pop up a Warning (requiring confirmation) to User,
        * before proceeding further and potentially making the Hell break loose. 
        */
        if ($_dsId.val() !== "" || $_wrapper.get(0).innerHTML.trim() === "") {
            var warning = "WARNING: Redefining Dataset for an existing Shelf MAY RESULT IN DATA LOSS.";
            warning += "\r\n";
            warning += "Before confirming, make sure to understand the following risks:";
            warning += "\r\n";
            warning += "\r\n";
            warning += "1. Changing Datasource means changing Products pool."
            warning += "\r\n";
            warning += "2. Thus, changing DS on a previously built/managed Shelf will bring the Shelf in an INCONSISTENT STATE.";
            warning += "\r\n";
            warning += "3. Changing DS will delete all of the previously defined Discounts (if any)."
            warning += "\r\n";
            warning += "4. The abovementioned effects will take place ONLY if you Submit the form. Then, potential data mashup would become persistant."

            if (!confirm(warning)) {
                var oldDsId = $_dsId.val();
                this.value = oldDsId;
                return;
            }
        }

        $_dsId.val(dsId);
        $_wrapper.empty();

    };

    var _appendLine = function () {

        if ($_dsId.val() === "") {
            alert("Please select a Datasource before proceeding");
            return;
        }

        globalCallbackHandler.handle("show-loader");

        $.ajax({
            url: "/utilities/discounts",
            method: "GET",
            data: {
                dataSourceId: $_dsId.val()
            },
            success: function (response) {
                globalCallbackHandler.handle("hide-loader");
                response = JSON.parse(response);
                $_wrapper.append(response.html);
            },
            error: function (response) {
                alert("Could not add Discount; An Error occurred.");
                globalCallbackHandler.handle("hide-loader");
            }
        });
    };

    var _deleteLine = function () {

        if (!confirm("Are you sure you want to proceed?"))
            return false;

        var $btn = $(this);
        $btn.parents(".js__discounts-item").hide();
        $btn.parents(".js__discounts-item")
            .find(".js__discounts-del-flag")
            .val(1);

        $btn.parents(".js__discounts-item")
            .find(".form-control")
            .attr("data-parsley-excluded", true);
    };

}

var sd = new ShelfDiscounts();
sd.init();
module.exports = sd;