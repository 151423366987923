function SurfaceControls() {

    this.init = function () {
        document.querySelector(".js__surface-ctrl-exportpng").addEventListener("click", _surfaceToPNG);
        document.querySelector(".js__surface-ctrl-undo").addEventListener("click", _surfaceUndo);
        document.querySelector(".js__surface-ctrl-redo").addEventListener("click", _surfaceRedo);
    };

    var _surfaceToPNG = function () {

        if (!window.localStorage) {
            alert("This function is not supported by your Browser.");
            return;
        }

        var filename = window.prompt("Input filename", "surface-export-" + Date.now());
        if (filename != null && filename != "") {
            var link = document.createElement("a");
            link.download = filename + ".png";
            link.href = _surface.toDataURL("png");
            link.click();
            link.remove();
        }
    };

    var _surfaceUndo = function () {
        globalCallbackHandler.handle("surface-states-undo");
    };

    var _surfaceRedo = function () {
        globalCallbackHandler.handle("surface-states-redo");
    };

    var _surface = globalSurfaceConfig.surface;
}

var sc = new SurfaceControls();
sc.init();
module.exports = sc;