function CallbackHandler() {

    this.handle = function (eventType, payload) {

        switch (eventType) {
            case "show-loader":
                _handleToggleLoader(true);
                break;
            case "hide-loader":
                _handleToggleLoader(false);
                break;
            case "toast":
                _handleToast(payload);
                break;
            case "update-surface-json":
                _handleSurfaceJsonUpdate();
                break;
            case "surface-states-append":
                _handleSurfaceStateUpdate();
                break;
            case "surface-states-undo":
                _handleSurfaceStateUndo();
                break;
            case "surface-states-redo":
                _handleSurfaceStateRedo();
                break;
            case "console-debug":
                _consoleDebug(payload.message, payload.type);
                break;
            default: return;
        }

    };

    var _handleToggleLoader = function (toggle = true) {
        import(/* webpackMode: 'lazy' */ "./components/loader")
            .then(Loader => {
                Loader.toggle(toggle);
            });
    };

    var _handleToast = function (payload) {
        import(/* webpackMode: 'lazy' */ "./util/toasts")
            .then(Toast => {
                Toast.toast(payload.type, payload.message);
            });
    };

    var _handleSurfaceJsonUpdate = function () {
        var JSONer = require("./surface/util/json");
        document.querySelector(".js__surface-json").value =
            JSONer.toJSON.call();
    };

    var _handleSurfaceStateUpdate = function () {
        import(/* webpackMode: 'lazy' */ "./surface/util/states")
            .then(Stater => {
                Stater.append.call();
            });
    };

    var _handleSurfaceStateUndo = function () {
        import(/* webpackMode: 'lazy' */ "./surface/util/states")
            .then(Stater => {
                Stater.undo.call();
            });
    };

    var _handleSurfaceStateRedo = function () {
        import(/* webpackMode: 'lazy' */ "./surface/util/states")
            .then(Stater => {
                Stater.redo.call();
            });
    };

    var _consoleDebug = function (message, type = "INFO") {
        var d = new Date(),
            time = [
                String("00" + d.getHours()).slice(-2),
                String("00" + d.getMinutes()).slice(-2),
                String("00" + d.getSeconds()).slice(-2)
            ].join(":");

        var style = "";
        switch (type) {
            case "ERROR":
                style = "background-color: crimson; color: white; padding: 2px 5px";
                break;
            case "WARNING":
                style = "background-color: darkorange; color: white; padding: 2px 5px";
                break;
            case "INFO":
                style = "background-color: deepskyblue; color: white; padding: 2px 5px";
                break;
            case "SUCCESS":
                style = "background-color: darkgreen; color: white; padding: 2px 5px";
                break;
            case "DEBUG":
                style = "background-color: darkslategrey; color: white; padding: 2px 5px";
                break;
            case "LIGHT":
                style = "background-color: lightgrey; padding: 2px 5px";
                break;
            default: break;
        }

        console.log(`%c[${time}]: ${message}`, style);
    };
}

global.globalCallbackHandler = new CallbackHandler();