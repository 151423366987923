function ShopcartModal() {

    var $_modal = $("#js__seq-shopcart-modal");

    this.init = function () {

        $_modal.on("show.bs.modal", function () {

            $.ajax({
                url: "/utilities/shopping-cart",
                /*
                * Refer to index.php for details about why a GET function uses POST method
                */
                method: "POST",
                data: {
                    shoppingCart: localStorage.getItem("VSV2_SEQ_ShoppingCart")
                },
                beforeSend: function () {
                    globalCallbackHandler.handle("show-loader");
                },
                success: function (response) {
                    response = JSON.parse(response);
                    $(".js__seq-shopcart-products").html(response.html);

                    document.dispatchEvent(new CustomEvent("selectpicker-init"));

                    //import(/* webpackMode: 'lazy' */ "bootstrap-input-spinner")
                    //.then(InputSpinner => {
                    /*$(".js__custom-numeric").inputSpinner();
                    $(".js__custom-numeric.js__custom-numeric-readonly").on("keydown", function (event) {
                        event.preventDefault();
                    });
                    $(".js__custom-numeric.js__custom-numeric-readonly").on("focus", function () {
                        $(this).blur();
                    });*/

                    /*
                    * Inside the Shopping Cart, there should be no option to increment
                    * Product quantity (only decrement remains);
                    * This is in relation to SHEL-109 from 22.12.2020.
                    * Hide using JS the "+" control.
                    */
                    /*$(".js__seq-shopcart-product-quantity")
                        .next(".input-group")
                        .find(".input-group-append")
                        .find(".btn-increment")
                        .hide();*/
                    //});

                    globalCallbackHandler.handle("hide-loader");
                },
                error: function (response) {
                    alert("ERROR; Could not Fetch Shopping Cart details");
                    globalCallbackHandler.handle("hide-loader");
                }
            });
        });

        $_modal.on("hidden.bs.modal", function () {
            // html should not be embedded here as string, but let it be as it is not subject to frequent change
            $(".js__seq-shopcart-products").html("<div class=\"p-3 w-100 text-center\"><div class=\"d-flex lds-facebook util_relative-center\"><div></div><div></div><div></div></div></div>");
        });
    };
}

var scm = new ShopcartModal();
scm.init();
module.exports = scm;