function Termination() {

    this.init = function () {

        if ($(".js__seq-termination").length === 0)
            return;

        /*
        * 09.04.2021
        *
        * Shopping Cart & related collections were previously stored in Cookies,
        * and their reset in the Termination process was performed in the BE;
        * Since these were transferred into LocalStorage, reset them here using JS.
        */
        document.dispatchEvent(new CustomEvent("cart-reset"));
        document.dispatchEvent(new CustomEvent("shlv-limits-reset"));
        document.dispatchEvent(new CustomEvent("shlv-product-considerations-reset"));
        document.dispatchEvent(new CustomEvent("shlv-extras-considerations-reset"));

        globalStatsFactory.fire({
            a: "terminate",
            ats: Math.floor(Date.now() / 1000),
            uid: $(".js__seq-termination-uid").val()
        });
    };

}

var t = new Termination();
t.init();
module.exports = t;