function ProductTemplateImporter() {

    this.init = function () {

        var _module = this;

        $(document).on("change", ".js__xlsx-product-template", function () {

            var file = this.files[0];
            var $input = $(this);
            /*
            * Use FormData object over XHR2 to send file
            * async to the Server
            */
            var formData = new FormData();
            formData.append("productTemplate", file);

            globalCallbackHandler.handle("show-loader");

            $.ajax({
                method: "POST",
                url: "/utilities/products-template",
                data: formData,
                contentType: false,
                processData: false,

                success: function (response) {
                    response = JSON.parse(response);
                    $(".js__products-listing").html(response.html);
                    globalCallbackHandler.handle("hide-loader");

                    /*
                    * Excel with Products list is uploaded before DS form submission;
                    * Therefore, once Tpl is successfully uploaded,
                    * Server also returns the exact (unique) Tpl filename;
                    * Fill this data in the hidden input, so that Products can,
                    * when DS form is submitted, be linked to the DataSource.
                    */
                    $(".js__xlsx-product-tplid").val(response.meta.templateId);
                },

                error: function (response) {
                    alert("An error occured during file upload!");
                    globalCallbackHandler.handle("hide-loader");

                    if (response.status === 417) {
                        response = JSON.parse(response.responseText);

                        var validationMessage = "FAILED CRITERIA:\r\n\r\n";
                        response.items.forEach(function (item) {
                            validationMessage += item.criteria
                                + " for Property: "
                                + item.property
                                + " in Cell: "
                                + item.origin
                                + "\r\n";
                        });
                        alert(validationMessage);

                        $input.val("");
                    }
                }
            });
        });
    };
}

var pri = new ProductTemplateImporter();
pri.init();
module.exports = pri;