function Multilines() {

    this.init = function () {

        $(".js__multilines").each(function (i, v) {

            var $elem = $(v);

            var mlObject = {
                $el: $elem,
                $ctrlAddLine: $elem.find(".js__multilines-addline-btn"),
                $linesWrapper: $elem.find(".js__multilines-wrapper"),
                $lineTemplate: $elem.find(".js__multilines-line-template")
            };

            mlObject.$ctrlAddLine.on("click", _appendLine.bind(mlObject));

            _collection.push(mlObject);

        });

    };

    var _collection = [];

    var _appendLine = function () {
        var clone = this.$lineTemplate.clone();
        clone.get(0).removeAttribute("style");
        this.$linesWrapper.append(clone);
    };

}

var ml = new Multilines();
ml.init();
module.exports = ml;