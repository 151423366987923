function Jsoner() {

    this.toJSON = function () {
        var json = _surface.toJSON();
        _decorateObjsCenterOffset(json);
        return JSON.stringify(json);
    };

    this.fromJSON = function (json) {
    };

    var _decorateObjsCenterOffset = function (json) {

        var cp = _surface.getCenter(); // Surface center point

        Array.prototype.forEach.call(json.objects, function (object) {
            object.cpOffset = {
                left: object.left - cp.left,
                top: cp.top - object.top
            };
        });
    };

    var _surface = globalSurfaceConfig.surface;

}

var j = new Jsoner();
module.exports = j;