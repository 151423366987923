function ProductMouseEvents() {

    this.init = function () {

        document.addEventListener("dragstart", function (e) {
            if (!e.target.classList.contains("js__product-main-image"))
                return false;

            _handleDragStart.bind(e.target).call();
        });

        document.addEventListener("dragend", function (e) {
            if (!e.target.classList.contains("js__product-main-image"))
                return false;

            _handleDragEnd.bind(e.target).call();
        });
    };

    var _handleDragStart = function () {
        var $products = $(".js__products-list .product .js__product-main-image");
        $products.removeClass("product-dragging");
        $(this).addClass("product-dragging");
    };

    var _handleDragEnd = function () {
        var $products = $(".js__products-list .product .js__product-main-image");
        $products.removeClass("product-dragging");
    };
}

var pme = new ProductMouseEvents();
pme.init();
module.exports = pme;