function SurfaceContainerKeyboardEvents() {

    this.init = function () {
        _surfaceContainer.tabIndex = 1000;
        _surfaceContainer.addEventListener("keyup", _handleKeyUp, false);
        _surfaceContainer.addEventListener("keydown", _handleKeyDown, false);
    };

    var _handleKeyUp = function (e) {
        switch (e.keyCode) {
            case 18: // "alt" key
                fabric.Object.prototype.selectable = true;
                break;
            case 27: // "esc" key
                _surface.discardActiveObject();
                _surface.renderAll();
                break;
            case 46: // "del" key
                _deleteObjectFromSurface();
                break;
            case 219: // [
                document.querySelector(".js__surface-ctrl-z-backwards").click();
                break;
            case 221: // ]
                document.querySelector(".js__surface-ctrl-z-forward").click();
                break;
            case 71: // g
                document.querySelector(".js__surface-ctrl-group").click();
                break;
            case 72: // h
                document.querySelector(".js__surface-ctrl-ungroup").click();
                break;
            case 67: // c
                document.querySelector(".js__surface-ctrl-clone").click();
                break;
            case 90: // z
                if (e.ctrlKey)
                    document.querySelector(".js__surface-ctrl-undo").click();
                break;
            case 89: // y
                if (e.ctrlKey)
                    document.querySelector(".js__surface-ctrl-redo").click();
                break;
            default:
                break;
        }
    };

    var _handleKeyDown = function (e) {
        switch (e.keyCode) {
            case 18: // "alt" key
                _surface.discardActiveObject();
                _surface.renderAll();
                fabric.Object.prototype.selectable = false;
                break;
            case 27: // "esc" key
                _surface.discardActiveObject();
                _surface.renderAll();
                break;
            case 107: // "+" key
                document.querySelector(".js__surface-ctrl-zoom-in").click();
                break;
            case 109: // "-" key
                document.querySelector(".js__surface-ctrl-zoom-out").click();
                break;
            case 37: // arr left
                //SurfaceObjectControls.objectMoveLeft();
                break;
            case 38: // arr up
                //SurfaceObjectControls.objectMoveUp();
                break;
            case 39: // arr right
                //SurfaceObjectControls.objectMoveRight();
                break;
            case 40: // arr down
                //SurfaceObjectControls.objectMoveDown();
                break;
            default:
                break;
        }
    };

    var _deleteObjectFromSurface = function () {
        _surface.removeActiveObjects();
        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _surface = globalSurfaceConfig.surface;
    var _surfaceContainer = globalSurfaceConfig.surfaceContainer;
}

var scke = new SurfaceContainerKeyboardEvents();
scke.init();
module.exports = scke;