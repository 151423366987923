var DeviceDetector = require("../../util/device_detection");
var ShelfLimitsManager = require("./shelf_limits_manager");
var ShelfDiscounts = require("./shelf_discounts");
var ProductConsiderationsManager = require("./product_considerations_manager");
var ExtraConsiderationsManager = require("./extra_considerations_manager");

function ShopcartManager() {

    this.init = function () {

        _import();

        _cart.ssid = $(".js__seq-id").val();
        _cart.sid = $(".js__seq-sessid").val();

        if (document.querySelector(".js__seq-currency"))
            _cart.cur = $(".js__seq-currency").val();

        _cart.dvc = DeviceDetector.device();
        _cart.os = DeviceDetector.os();
        _cart.ua = DeviceDetector.browser();

        _cart.max_i = 0;
        if (document.querySelector(".js__seq-limit-items"))
            _cart.max_i = +$(".js__seq-limit-items").val();

        _cart.max_f = 0;
        if (document.querySelector(".js__seq-limit-funds"))
            _cart.max_f = +$(".js__seq-limit-funds").val();

        _export();
        _updateLabels();

        /*
        * If Sequencing meta is in the DOM, load data from the input;
        * After setting property 'shfl' to the Cart, delete the input so
        * that it is not loaded into Cart again.
        * 
        * Do this after the _export call (above), so that 'shfl' does not get
        * persisted in the local storage (needed only with the 1st saveCart call).
        */
        if (document.querySelector(".js__seq-sequencing")) {
            _cart.shfl = $(".js__seq-sequencing").val();
            $(".js__seq-sequencing").remove();
        }

        // cart-start added in relation to SHEL-356
        $(document).on("cart-start", _start);
        $(document).on("cart-add-product", _add);
        $(document).on("cart-remove-product", _remove);
        $(document).on("cart-open-product", function () {

            _cart.cons++;

            if (_cart.tcons > 0)
                return;

            _cart.tcons = Math.floor(Date.now() / 1000) - _cart.st - _cart.vt - _cart.it;
            _export();
        });

        $(document).on("cart-set-budget", function () {
            _cart.max_f = +$(".js__seq-limit-funds").val();
            _updateLabels();
        });

        $(document).on("cart-set-quantity", function () {
            _cart.max_i = +$(".js__seq-limit-items").val();
            _updateLabels();
        });

        $(document).on("cart-validate-limits", _validateLimits);
        $(document).on("cart-checkout", _checkout);
        $(document).on("cart-save", _saveCart);
        $(document).on("cart-reset", _reset);
        $(document).on("cart-set-user", _setUserId);

        $(document).on("cart-sh-land", _shLand);
        $(document).on("cart-sh-leave", _shLeave);

        /*
        * In relation to SHEL-346 development and Shopcart InstrTime Manager:
        * 
        * This event is triggered on instructions close and is used to update
        * cumulative instructions time on Cart level ('it' property of the Cart object).
        */
        $(document).on("cart-instr-update", _instrTimeUpdate);
    };

    var _start = function () {

        /*
        * If opening the first Shelf within the Sequence, set the start time tstamp;
        * If it was set previously, make an early exit.
        * If making early exit, just perform CartStart reset from the LocalStorage.
        */
        if (_cart.st > 0)
            return;

        var start = localStorage.getItem("VSV2_SEQ_CartStart");

        // safety, plan B
        if (!start)
            start = Math.floor(Date.now() / 1000);

        _cart.st = +start;
        _export();
    };

    /*
    * Import Cart object from Local Storage;
    * Deserialize it before use.
    */
    var _import = function () {

        var storedCart = localStorage.getItem("VSV2_SEQ_ShoppingCart");

        if (!storedCart) {
            ShelfLimitsManager.bootstrap();
            ProductConsiderationsManager.bootstrap();
            ExtraConsiderationsManager.bootstrap();
            return;
        }

        var storedCart = JSON.parse(storedCart);

        if (storedCart.ssid !== $(".js__seq-id").val()) {
            document.dispatchEvent(new CustomEvent("cart-reset"));
            document.dispatchEvent(new CustomEvent("shlv-limits-reset"));
            document.dispatchEvent(new CustomEvent("shlv-product-considerations-reset"));
            document.dispatchEvent(new CustomEvent("shlv-extra-considerations-reset"));
            return;
        }

        if (storedCart.sid !== $(".js__seq-sessid").val()) {
            document.dispatchEvent(new CustomEvent("cart-reset"));
            document.dispatchEvent(new CustomEvent("shlv-limits-reset"));
            document.dispatchEvent(new CustomEvent("shlv-product-considerations-reset"));
            document.dispatchEvent(new CustomEvent("shlv-extra-considerations-reset"));
            return;
        }

        ShelfLimitsManager.bootstrap();
        ProductConsiderationsManager.bootstrap();
        ExtraConsiderationsManager.bootstrap();

        _cart = storedCart;

        _toggleProductsLockState(true);

        _updateLabels();
    };

    /*
    * Write Cart object to Local Storage;
    * Serialize before write.
    */
    var _export = function () {
        localStorage.setItem("VSV2_SEQ_ShoppingCart", JSON.stringify(_cart));
    };

    var _reset = function () {
        /*
        * Delete the Cart from Local Storage.
        * After checking out, Cart need no longer be preserved.
        * 
        * Same goes for the last ShLeave tstamp;
        * These were preserved for the JS to be able to properly calculate
        * void/vacuum periods between prev Shelf close & next Shelf open;
        * On reset, that value also becomes redundant.
        * 
        * In addition, reset Instr. times since these are also sequence-level
        * (shopcart-level).
        */
        localStorage.removeItem("VSV2_SEQ_ShoppingCart");
        localStorage.removeItem("VSV2_SEQ_ShLeave");
        localStorage.removeItem("VSV2_SEQ_InstrTimes");
        localStorage.removeItem("VSV2_SEQ_Timespent");
        localStorage.removeItem("VSV2_SEQ_CartStart");
    };

    var _shLand = function () {

        var lastShLeaveTstamp = localStorage.getItem("VSV2_SEQ_ShLeave");

        var shLandTstamp = Math.floor(Date.now() / 1000);

        if (lastShLeaveTstamp == null)
            return;

        lastShLeaveTstamp = JSON.parse(lastShLeaveTstamp);

        if (typeof lastShLeaveTstamp !== "object" || lastShLeaveTstamp === null) {
            localStorage.removeItem("VSV2_SEQ_ShLeave");
            return;
        }

        var keys = Object.keys(lastShLeaveTstamp);
        if (keys.length !== 1 || keys[0] !== _cart.ssid) {
            localStorage.removeItem("VSV2_SEQ_ShLeave");
            return;
        }

        var vacuumTimeIncrement = shLandTstamp - lastShLeaveTstamp[keys[0]];
        _cart.vt += vacuumTimeIncrement;

        localStorage.removeItem("VSV2_SEQ_ShLeave");

        _export();
    };

    var _shLeave = function () {
        var shLeaveObj = {};
        shLeaveObj[_cart.ssid] = Math.floor(Date.now() / 1000);
        localStorage.setItem("VSV2_SEQ_ShLeave", JSON.stringify(shLeaveObj));

        /*
        * This event will result in the 'sh-close' message.
        */
        document.dispatchEvent(new CustomEvent("surface-close"));
    };

    /*
    * Add Product to Cart Products' catalog.
    */
    var _add = function () {

        var evt = arguments[0];

        if (evt == undefined)
            return;

        var prodId = evt.detail.id,
            quantity = evt.detail.qty,
            quantityCalc = evt.detail.qtyCalc,
            unit = evt.detail.u,
            price = evt.detail.p;

        var input = evt.detail.input,
            labelTotalVal = evt.detail.labelTotalVal,
            resetValue = evt.detail.resetValue,
            resetTotal = evt.detail.resetTotal,
            qtyChangeStatObj = evt.detail.qtyChangeStatObj;

        var addShallow = evt.detail.shallow;

        var tstamp = Math.floor(Date.now() / 1000);

        var _previousProductQuantity = 0;

        if (!_cart.prods.hasOwnProperty(prodId)) {
            /*
            * Did not exist previously with any quantity inside the Cart;
            * Build the newProduct object.
            */
            var newProduct = {
                id: prodId,
                qty: quantity,
                qtyCalc: quantityCalc,
                u: unit,
                p: price,
                baseval: quantity * price,
                calcval: 0,
                purch: Object.keys(_cart.prods).length + 1,
                tpurch: tstamp - _cart.st - _cart.vt - _cart.it
            };

            ShelfDiscounts.discountProduct(newProduct);

            /*
            * Product quantity is updated;
            * There are quantity & price diffs compared to the previous Cart state;
            * Pass these differences to ShelfLimitsManager, where qtys and funds
            * will be accordingy accounted for over the previous states (per-shelf).
            */
            var areShelfLimitsValid = _shelfProductStateUpdate({
                id: prodId,
                qty: newProduct.qtyCalc,
                qtyChange: newProduct.qtyCalc - _previousProductQuantity,
                p: newProduct.p
            });

            if (!areShelfLimitsValid)
                return;

            _cart.prods[prodId] = newProduct;

            if (!_validateLimits()) {
                delete _cart.prods[prodId];
                return;
            }

            if (_cart.tbuy === null)
                _cart.tbuy = tstamp - _cart.st - _cart.vt - _cart.it;

            var action = "add";
            if (addShallow)
                action = "add-shallow";

            // Fire Add to Cart stat (first add)
            globalStatsFactory.fire({
                a: action,
                ats: tstamp,
                pid: prodId,
                qty: quantity,
                qty_calc: newProduct.qtyCalc - _previousProductQuantity
            });

            globalCallbackHandler.handle("console-debug", {
                message: `Add Product`,
                type: "DEBUG"
            });

            globalCallbackHandler.handle("console-debug", {
                message: `Cart TBFB: ${_cart.tbuy}, Cart IT: ${_cart.it}`,
                type: "DEBUG"
            });

            _recalculate();
            globalCallbackHandler.handle("toast", {
                type: "INFO",
                message: $(".js__seq-notif-cart-ok").val()
            });
            _export();
            document.dispatchEvent(new CustomEvent("sequence-appendix-allnavs"));
            _toggleProductsLockState(true, [prodId]);
            return;
        }

        /*
        * A certain quantity of this Product DID previously exist in the Cart;
        * Clone the current Product state into a separate variable to
        * proceed with validations; if valid, override the old state with the new one.
        */
        var cartProduct = JSON.parse(JSON.stringify(_cart.prods[prodId])),
            cartProductBackup = JSON.parse(JSON.stringify(_cart.prods[prodId]));

        _previousProductQuantity = cartProduct.qtyCalc;
        var previousProductRealQuantity = cartProduct.qty;

        cartProduct.qty = quantity;
        cartProduct.qtyCalc = quantityCalc;
        cartProduct.baseval = quantity * price;

        if (cartProduct.qty === 0)
            _remove(prodId);

        ShelfDiscounts.discountProduct(cartProduct);

        /*
        * Product quantity is updated;
        * There are quantity & price diffs compared to the previous Cart state;
        * Pass these differences to ShelfLimitsManager, where qtys and funds
        * will be accordingy accounted for over the previous states (per-shelf).
        */
        var areShelfLimitsValid = _shelfProductStateUpdate({
            id: prodId,
            qty: cartProduct.qtyCalc,
            qtyChange: cartProduct.qtyCalc - _previousProductQuantity,
            p: cartProduct.p
        });

        if (!areShelfLimitsValid) {
            document.dispatchEvent(new CustomEvent("cart-input-reset-value", {
                detail: {
                    input: input,
                    resetValue: resetValue,
                    labelTotalVal: labelTotalVal,
                    resetTotal: resetTotal
                }
            }));
            return;
        }

        _cart.prods[prodId] = cartProduct;

        if (!_validateLimits()) {
            /*
            * If allowed to implement the newest state, limits would have been overflown;
            * To prevent limit-breaking invalid state, rollback Product to a previous state.
            */
            _cart.prods[prodId] = cartProductBackup;
            document.dispatchEvent(new CustomEvent("cart-input-reset-value", {
                detail: {
                    input: input,
                    resetValue: resetValue,
                    labelTotalVal: labelTotalVal,
                    resetTotal: resetTotal
                }
            }));
            return;
        }

        if (_cart.tbuy === null)
            _cart.tbuy = tstamp - _cart.st - _cart.vt - _cart.it;

        var qtyRealDelta = +(cartProduct.qty - previousProductRealQuantity);

        /*
        * 09.08.2022.
        * SHEL-363
        * 
        * If quantity delta = 0, there is no need to perform a real update action;
        * Skip stats message, skip toast, display only specific debug info and exit early.
        * Use real quantities (instead of qtyCalc), because qtyCalc would not cover the case of bulk products.
        */
        if (qtyRealDelta === 0) {
            globalCallbackHandler.handle("console-debug", {
                message: `Add Product - No Qty Delta`,
                type: "DEBUG"
            });
            return;
        }

        /*
        * 12.11.2022.
        * SHEL-367
        * 
        * 1. If qtyChangeStatObj was set in the custom event
        * 2. If validations are passed & qty is efectively different than it was
        * 3. DO: Execute quantity change.
        */
        _cartItemQuantityChange(qtyChangeStatObj, input, quantity);

        /*
        * 20.11.2022
        * SHEL-381
        *
        * Introduced new signal type 'add-shallow', used only for logging purposes.
        * Refer to Stats controller for more details.
        */
        var action = "add";
        if (addShallow)
            action = "add-shallow";

        // Fire Add to Cart stat (subsequent adds, required with SHEL-110)
        globalStatsFactory.fire({
            a: action,
            ats: tstamp,
            pid: prodId,
            qty: quantity,
            qty_calc: cartProduct.qtyCalc - _previousProductQuantity
        });

        globalCallbackHandler.handle("console-debug", {
            message: `Add Product`,
            type: "DEBUG"
        });

        globalCallbackHandler.handle("console-debug", {
            message: `Cart TBFB: ${_cart.tbuy}, Cart IT: ${_cart.it}`,
            type: "DEBUG"
        });

        _recalculate();
        globalCallbackHandler.handle("toast", {
            type: "INFO",
            message: $(".js__seq-notif-cart-ok").val()
        });
        _export();
        document.dispatchEvent(new CustomEvent("sequence-appendix-allnavs"));
        _toggleProductsLockState(true, [prodId]);
    };

    /*
    * Remove Product from Cart Products' catalog.
    */
    var _remove = function () {

        var evt = arguments[0];
        var prodId = evt.detail.id;

        if (!_cart.prods.hasOwnProperty(prodId))
            return;

        /*
        * Product is removed;
        * Qty diff is -qtyCalc, while price diff is: -calcval
        * compared to the previous Cart state;
        * Pass these decrements to ShelfLimitsManager, where qtys and funds
        * will be accordingy deducted from the previous states (per-shelf).
        */
        var areShelfLimitsValid = _shelfProductStateUpdate({
            id: prodId,
            qty: 0,
            qtyChange: 0 - _cart.prods[prodId].qtyCalc,
            p: _cart.prods[prodId].p
        });

        if (!areShelfLimitsValid)
            return;

        var removeQty = 0 - _cart.prods[prodId].qtyCalc;

        /*
        * Handle the gap that will remain in the purchase numbers ordering
        * after a Cart Product has been deleted.
        */
        var purchNumberToBeRemoved = _cart.prods[prodId].purch;
        _redeterminePurchaseNumbers(purchNumberToBeRemoved);

        delete _cart.prods[prodId];

        if (!_validateLimits())
            return;

        if (typeof globalStatsFactory == "undefined")
            return;

        // Fire Remove from Cart stat
        globalStatsFactory.fire({
            a: "remove",
            ats: Math.floor(Date.now() / 1000),
            pid: prodId,
            qty: removeQty,
            qty_calc: removeQty
        });

        _recalculate();
        _export();
        document.dispatchEvent(new CustomEvent("sequence-appendix-allnavs"));
        globalCallbackHandler.handle("toast", {
            type: "INFO",
            message: $(".js__seq-notif-cart-removed").val()
        });

        /*
        * If Product was previously blocked when added to Cart (because of such Configuration),
        * emit the multiblock event here using the Product ID;
        * It will unblock the Product because event-bound handler toggles block state.
        */
        _toggleProductsLockState(false, [prodId]);
    };

    var _cartItemQuantityChange = function (qtyChangeStatObj, input, quantity) {

        if (qtyChangeStatObj === null)
            return;

        if (typeof globalStatsFactory == "undefined")
            return;

        /*
        * 12.11.2022.
        * SHEL-367
        * 
        * BUG specifics: cartUp/cartDown would fire even if subsequently Cart validations would fail.
        * Repro steps:
        * 1. Set Cart limit to 5
        * 2. Add product X, quantity = 2
        * 3. Add product Y, quantity = 1
        * 4. Open Cart, change qty of product X to quantity = 5
        * 5. Result: validations have failed, quantity input reset to value = 2; Anyhow, cartUp was fired.
        * 
        * Fire quantity changed (cartUp/cartDown) event;
        * After 'cartUp/cartDown', fire 'cart-save' custom event to update Cart.
        */
        globalStatsFactory.fire(qtyChangeStatObj);

        /*
        * Update quantity input if quantity update was successfull.
        * Until this point, input had old value & props so that reset event could do a rollback in case
        * of failed validations etc...
        */
        document.dispatchEvent(new CustomEvent("cart-update-input", {
            detail: {
                input: input,
                value: quantity
            }
        }));

        _saveCart();
    };

    var _storeProducts = function () {
        /*
        * JSON stringify+parse makes a precise copy of the Object,
        * instead of making variable _prodsCollection a ref to _cart.prods.
        */
        _prodsCollection = JSON.parse(JSON.stringify(_cart.prods));
    };

    var _rollbackProducts = function () {
        _cart.prods = _prodsCollection;
    };

    /*
    * Recalculates Cart total value based on the Products
    * included & Discounts, if any is active.
    */
    var _recalculate = function () {
        _cart.val = _getCartItemsTotal();
        _updateLabels();
    };

    var _updateLabels = function () {
        var prodsCount = _getCartItemsCount();

        if (prodsCount > 0)
            $(".js__seq-shopcart-qty").html(prodsCount).show();
        else
            $(".js__seq-shopcart-qty").hide();

        $(".js__seq-shopcart-currency").html($(".js__seq-currency").data("symbol"));
        $(".js__seq-shopcart-total").html(_cart.val.toFixed(2));

        // Also update limitations in the Shopping Cart Modal
        $(".js__seq-limit-items-lbl").html(prodsCount);
        $(".js__seq-limit-funds-lbl").html(_cart.val.toFixed(2));
        $(".js__seq-limit-funds-total-lbl").html(_cart.max_f);

        // Disable speedrunner btn (if exists) if there are Products in the Cart
        var disableState = prodsCount > 0;

        /*
        * 05.11.2021
        * SHEL-286
        * 
        * In case when Sequence is a Decision Tree task, change the logic of how
        * the Speedrunner btn is being disabled;
        * Instead of disabling based on the count of Products in the Cart,
        * disable based on count of Products * bought on the current Shelf *.
        * 
        * Tasktype is as of now stored in the Sequence metadata in .js__seq-tasktype hidden input.
        */
        if (+$(".js__seq-tasktype").val() === 4)
            disableState = ShelfLimitsManager.countProductsOnShelf() > 0;

        document.dispatchEvent(new CustomEvent("speedrunner-btn-toggle-state", {
            detail: {
                disableState: disableState
            }
        }))

        /*
        * If Cart limits are set & fully reached (or overflown, which should not even be possible in the new version),
        * Cart item and/or funds limit should be indicated in red color;
        * If user bought below the configured limit, repaint the label in white (default color).
        */
        _updateLabelsIndicationColor(
            _cart.max_i > 0 && _cart.max_i <= _getCartItemsCount(),
            _cart.max_f > 0 && _cart.max_f <= _getCartItemsTotal()
        );
    };

    var _updateLabelsIndicationColor = function (itemsReachedOrOverflown, fundsReachedOrOverflown) {
        $(".js__seq-limit-items-wrapper").toggleClass("text-primary", itemsReachedOrOverflown);
        $(".js__seq-limit-funds-wrapper").toggleClass("text-primary", fundsReachedOrOverflown);
    };

    var _setUserId = function () {

        var evt = arguments[0];
        var userid = evt.detail.userid;

        /*
        * User id may only be set once to the Cart;
        * If an override is attempted, block such an attempt.
        */
        if (_cart.userid != null)
            return;

        _cart.userid = userid;
        _export();
        document.dispatchEvent(new CustomEvent("sequence-appendix-allnavs"));
    };

    /*
    * Calculate total number of items in the Cart
    */
    var _getCartItemsCount = function () {
        var total = 0;
        for (var prod in _cart.prods) {
            var product = _cart.prods[prod];
            total += product.qtyCalc;
        }
        return total;
    };

    /*
    * Calculate total value of items in the Cart
    */
    var _getCartItemsTotal = function () {
        var total = 0;
        for (var prod in _cart.prods) {
            var product = _cart.prods[prod];
            total += product.calcval;
        }
        return total;
    };

    var _validateLimits = function () {

        var limitsInvalid = false;

        if (_cart.max_i > 0 &&
            _cart.max_i < _getCartItemsCount()) {
            globalCallbackHandler.handle("toast", {
                type: "ERROR",
                message: $(".js__seq-notif-cart-limit-qty-not-ok").val()
            });
            limitsInvalid = true;
        }

        if (_cart.max_f > 0 &&
            _cart.max_f < _getCartItemsTotal()) {
            globalCallbackHandler.handle("toast", {
                type: "ERROR",
                message: $(".js__seq-notif-cart-limit-budget-not-ok").val()
            });
            limitsInvalid = true;
        }

        if (limitsInvalid) {
            _rollbackProducts();

            var evt = new CustomEvent("shlv-limits-rollback");
            document.dispatchEvent(evt);
        } else {
            _storeProducts();

            var evt = new CustomEvent("shlv-limits-update");
            document.dispatchEvent(evt);
        }

        /*
        * In certain cases (when Cart limit is lower than the Surface limit), 
        * Surface limit may be on the upper boundary (and indicated red);
        * But still, Cart limit would be overflown by accepting such change, thus the change is reverted by this exact validation;
        * Anyways, once the change is reverted, Shelf limits are also rolled back, 
        * and there's no need for the Surface-limit labels to remain colored in red.
        * 
        * Emit this Event to instruct Surface limits Manager to repaint the labels.
        */
        document.dispatchEvent(new CustomEvent("shlv-limits-indicate"));

        return !limitsInvalid;
    };

    var _toggleProductsLockState = function (toggle, productIds = null) {

        var productsToLock = Object.keys(_cart.prods);

        if (productIds !== null)
            productsToLock = productIds;

        if (!document.querySelector(".js__seq-lock-added-products"))
            return;

        var lockProductsEvent = new CustomEvent("surface-multiblock-products", {
            detail: {
                block: toggle,
                products: productsToLock
            }
        });
        document.dispatchEvent(lockProductsEvent);
    };

    var _saveCart = function () {

        /*
        * If Cart start time is equal to end time,
        * it means that Cart is not realistic object, 
        * as it was never really initiated and used.
        * In such cases, exit before saving the Cart in the BE.
        */
        if (_cart.st === _cart.et)
            return;

        globalStatsFactory.fire($.extend({
            a: "saveCart",
            ats: Math.floor(Date.now() / 1000),
        }, _cart));
    };

    var _checkout = function () {
        _cart.et = Math.floor(Date.now() / 1000);
        _cart.status = "Completed";
        /*
        * Also, reset product<->shelf distributions and considerations;
        * If Cart is checked out, these should also be reset.
        */
        document.dispatchEvent(new CustomEvent("shlv-limits-reset"));
        document.dispatchEvent(new CustomEvent("shlv-product-considerations-reset"));
        document.dispatchEvent(new CustomEvent("shlv-extra-considerations-reset"));
    }

    var _shelfProductStateUpdate = function (product) {
        return ShelfLimitsManager.updateProductState(product);
    };

    var _redeterminePurchaseNumbers = function (purchNumber) {
        /*
        * When a Product is removed from the Shopping Cart,
        * there will be a gap left in the purchase numbers array;
        * Traverse through all products and decrement all the purch numbers
        * which are greater than the purch no. that has just been removed.
        */
        for (var productId in _cart.prods)
            if (_cart.prods[productId].purch > purchNumber)
                _cart.prods[productId].purch--;
    };

    var _instrTimeUpdate = function () {
        var e = arguments[0];
        _cart.it = e.detail.time;
        _export();
    };

    var _prodsCollection = {};

    var _cart = {
        /*
        * Sequence/Shelfset Id;
        */
        ssid: null,
        /*
        * Session ID;
        * Refers to the User session inside the Application;
        * Atomic stats will also be bound to the Session ID,
        * as well as the Session, which will be serialized & flushed 
        * to Stats once the Sequence is completed.
        */
        sid: null,
        /*
        * User Id originates from the external source and is passed into
        * the Session/Sequence/Cart via URL parameter;
        * Set to null by default;
        * If parameter is provided in the URL, override with proper value.
        */
        userid: null,
        /*
        * Cart status (Active by default);
        * When shopping is completed (by using the 'Finish' control),
        * change Cart status to "Completed".
        */
        status: "Active",
        /*
        * Sequencing (order) of the Shelves;
        * This will only be kept Cart-level until the first 'saveCart' is sent;
        * Later on, unset this property to ease the network load.
        */
        shfl: null,
        /*
        * Sequence opening tstamp;
        * Use it also as Cart start time.
        */
        st: 0,
        /*
        * Sequence closing tstamp;
        * Use it also as Cart end time.
        * When shopping is completed (by using the 'Finish' control),
        * will take the current timestamp as value.
        */
        et: 0,
        /*
        * Represends total time (in seconds) spent void/vacuum between
        * one Shelf is closed, until the next one is loaded;
        * Typically, this is 2-3 seconds, but may also be up to as much as 10 seconds,
        * as it depends on CPU power and network.
        * In Sequences containing multiple Shelves, especially if backwards/side navigation is enabled,
        * there may be dozens of such transitions, whereas over 100 seconds may be spent in a 'vacuum'.
        * In turn, this causes the values such as 'tcons' and 'tbuy' to become severely inaccurate,
        * ultimately 'TIME BEFORE FIRST BUY' in the Report happens to be 
        * significantly higher than total number of seconds spent on all the Shelves combined.
        * 
        * To prevent that, a 'normalization' value is introduced here.
        */
        vt: 0,
        /*
        * Instruction times
        * This represents total time spent by User watching Tutorial & reading Shopping instructions;
        * Requested via SHEL-346, these times should be deducted from all measured times.
        */
        it: 0,
        /*
        * Time elapsed until the first Product is being considered
        * (by clicking it & viewing details in the Modal).
        */
        tcons: 0,
        /*
        * Time elapsed until the first Product is being bought
        * (added to Cart).
        */
        tbuy: null,
        /*
        * Counter for total considerations;
        * Increment each time the Product is considered on the Surface.
        */
        cons: 0,
        /*
        * Items count & funds limitations;
        * These originate from Sequence configuration.
        */
        max_i: null,
        max_f: null,
        /*
        * Currency;
        * Currency originates from the Shelf-level, so always
        * set to Cart the currency of the first Shelf in the 
        * Sequence.
        */
        cur: null,
        /*
        * This property is reserved for the type of device the Session
        * was initiated on (desktop, mobile, tablet, smarttv, wearable, embedded...).
        */
        dvc: null,
        /*
        * Below properties closely determine which is the exact enviroment of the Session;
        * "os" stands for Op. System, while "ua" precisely tracks down the exact Browser.
        */
        os: null,
        ua: null,
        /*
        * Total Cart value
        */
        val: 0,
        /*
        * Products added to Cart
        */
        prods: {},
    };

}

var scm = new ShopcartManager();
scm.init();
module.exports = scm;