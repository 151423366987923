
var Dropzone = require("dropzone");

window.Dropzone.options.dropzoneForm = {
    paramName: "dataSourceFiles",
    previewsContainer: "#js__dropzone-preview",
    addRemoveLinks: true,
    acceptedFiles: "image/*,video/*",
    autoProcessQueue: false,
    uploadMultiple: true,
    parallelUploads: 500,
    maxFiles: 500,
    maxFilesize: 50, // in MBs
    autoDiscover: false,

    init: function () {

        var myDropzone = this;
        var $form = $(this.element).parents("form");

        $form.on("submit", function (e) {
            /*
            * Actually prevent form submit if there are files stacked in the DZ;
            * First, initiate upload of the DS files;
            * Once multiupload is complete, success callback will be triggered;
            * Submit the form from withing the success callback.
            * 
            * If there are no files in the DZ, do a regular Form submit action.
            */
            e.preventDefault();
            e.stopPropagation();

            /*
            * SHEL-380
            * Inform user (again) that the template file attached is invalid.
            * User can cancel form submission ah this point, or proceed without products.
            */
            if (!_validate())
                return false;

            globalCallbackHandler.handle("show-loader");

            if (myDropzone.getQueuedFiles().length > 0)
                myDropzone.processQueue();
            else
                $form[0].submit();

            globalCallbackHandler.handle("toast", {
                type: "INFO",
                message: "Accepted: " + myDropzone.getAcceptedFiles().length + " files; Uploading..."
            });
        });

        this.on("sendingmultiple", function () {
            $form.find("button[type='submit']")
                .prop({ "disabled": true })
                .html("Uploading, please wait...");
        });

        this.on("successmultiple", function (files, response) {
            /*
            * Multiupload success callback;
            * At this point, all the files were uploaded and processed on the server side.
            * Now it is safe to submit the Form & perform insert/update of the DS.
            * Afterwards, move files BE-side from the temp dir to the actual DS dir.
            */
            alert("Successfully uploaded " + files.length + " images & videos. Press OK to proceed.");
            $form[0].submit();
        });

        this.on("errormultiple", function (files, response) {
            globalCallbackHandler.handle("hide-loader");
        });

        this.on("addedfile", function (file) {
            // stub; implement if needed
        });
    }
};

var _validate = function (e) {

    if (document.querySelector(".js__xlsx-product-template").value === "")
        return true;

    if (document.querySelector(".js__xlsx-product-tplid").value !== "")
        return true;

    var warning = "WARNING:\n\n";
    warning += "It seems like your Excel upload has failed.\n";
    warning += "This warning arises in cases when Products template contained some errors, so products contained in the file could not be consumed by the system.\n\n";
    warning += "If you proceed, modifications to your Dataset will *NOT INCLUDE* whatever modifications were included in the Template file.\n";
    warning += "To fix this problem, please click Cancel, then fix and re-attach your Products Template file.\n";
    warning += "You can also choose to Confirm and ignore this message.\n\n"
    warning += "Do you want to proceed?";

    return confirm(warning);
};

var $dz = $("#dropzone-form");

if ($dz.length > 0) {

    var url = "/uploads/data-sources";
    url += "/" + $(".js__datasource-hash").val();
    url += "/files";

    var myDz = new window.Dropzone("#dropzone-form", {
        url: url
    });
}

function DropzoneComponent() {
    this.filenames = [];
    this.init = function () { };
    this.init();
}

module.exports = new DropzoneComponent();