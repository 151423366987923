function ShelfDiscounts() {

    this.init = function () {
        _loadDiscounts();
    };

    this.discountProduct = function (product, targetQuantity = null) {

        if (!_discounts.hasOwnProperty(product.id)) {
            product.calcval = product.qty * product.p;
            return;
        }

        if (targetQuantity === null)
            targetQuantity = product.qty;

        var discountPercent = _discounts[product.id].percent;
        var discountPack = _discounts[product.id].quantity
        var packItems = Math.floor(targetQuantity / _discounts[product.id].quantity);
        var restItems = targetQuantity % _discounts[product.id].quantity;

        product.calcval = packItems * discountPack * product.p * ((100 - discountPercent) / 100);
        product.calcval += restItems * product.p;
    };

    /*
    * Discounts are stored in the DOM as JSON serialized obj;
    * Read from the DOM and populate _discounts variable.
    */
    var _loadDiscounts = function () {

        if (!document.querySelector(".js__seq-discounts"))
            return;

        _discounts = JSON.parse(document.querySelector(".js__seq-discounts").value);
    };

    /*
    * Discounts active for the given Shelf;
    * Format of a Discount object:
    * productId, quantity, percentage.
    */
    var _discounts = {};

}

var sd = new ShelfDiscounts();
sd.init();
module.exports = sd;