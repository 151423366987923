function ProductListFiltering() {

    var _searchTimeoutId = null;
    var _searchTimeoutInterval = 500;

    this.init = function () {
        $(document).on("change", ".js__prodlist-sorting", _search);

        $(document).on("input", ".js__prodlist-keyword", function () {
            clearTimeout(_searchTimeoutId);
            _searchTimeoutId = setTimeout(function () {
                _search();
            }, _searchTimeoutInterval);
        });
    };

    var _search = function () {
        var dsId = $(".js__prodlist-dsid").val();
        var keyword = $(".js__prodlist-keyword").val();
        var sorting = $(".js__prodlist-sorting").val();

        globalCallbackHandler.handle("show-loader");

        $.ajax({
            url: "/utilities/products/filtered",
            method: "GET",
            data: {
                dsId: dsId,
                keyword: keyword,
                sorting: sorting
            },
            success: function (response) {
                response = JSON.parse(response);
                $(".js__products-list").html(response.html);
                globalCallbackHandler.handle("hide-loader");
            },
            error: function (response) {
                alert("ERROR while performing async operation");
                globalCallbackHandler.handle("hide-loader");
            }
        });
    };

}

var plf = new ProductListFiltering();
plf.init();
