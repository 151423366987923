function IntroModal() {

    this.init = function () {
        $(document).on("intro-modal-launch", _launch);
    };

    var _launch = function () {
        $("#js__seq-info-modal").modal("show");
    };
}

var im = new IntroModal();
im.init();
module.exports = im;