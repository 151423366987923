function VideoPlayer() {

    var _videojs = null;

    this.init = function () {

        import(/* webpackMode: 'lazy' */ "video.js")
            .then(_ => {
                _videojs = _.default;
            });

        $(document).on("open-product-modal-event", function (e) {
            var player = $("#js__modal-video-player");

            if (player.length === 0)
                return;

            _startVideoPlayer();
        });

        $(document).on("close-product-modal-event close-extras-modal-event", function (e) {
            _destroyIframeVideos();

            var player = $("#js__modal-video-player");
            if (player.length === 0)
                return;
            _destroyVideoPlayer();
        });


    };

    var _startVideoPlayer = function () {

        var options = {
            controls: false,
            autoplay: true,
            loop: !!+$(".js__product_loop-video").val(),
            muted: true,
            fluid: true,
            preload: "auto"
        };

        _videojs("js__modal-video-player", options);

    };

    var _destroyVideoPlayer = function () {
        var player = _videojs("js__modal-video-player");
        player.dispose();
    };

    var _destroyIframeVideos = function () {
        $(".js__modal-iframe")
            .contents()
            .find("video")
            .each(function () {
                this.currentTime = 0;
                this.pause();
            });
    };
}

var videoPlayer = new VideoPlayer();
videoPlayer.init();
module.exports = videoPlayer;