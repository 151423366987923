function SurfaceObjectControls() {

    var _maxScale = 1.8;
    var _minScale = 0.2;
    var _scaleStep = 0.05;

    this.init = function () {
        document.querySelector(".js__surface-ctrl-lock").addEventListener("click", _objectLock);
        document.querySelector(".js__surface-ctrl-unlock").addEventListener("click", _objectUnlock);

        document.querySelector(".js__surface-ctrl-block").addEventListener("click", _objectBlock);
        document.querySelector(".js__surface-ctrl-unblock").addEventListener("click", _objectUnblock);

        document.querySelector(".js__surface-ctrl-grow").addEventListener("click", _objectGrow);
        document.querySelector(".js__surface-ctrl-shrink").addEventListener("click", _objectShrink);

        document.querySelector(".js__surface-ctrl-z-forward").addEventListener("click", _objectForward);
        document.querySelector(".js__surface-ctrl-z-backwards").addEventListener("click", _objectBackwards);

        document.querySelector(".js__surface-ctrl-clone").addEventListener("click", _objectClone);

        document.querySelector(".js__surface-ctrl-group").addEventListener("click", _objectGroup);
        document.querySelector(".js__surface-ctrl-ungroup").addEventListener("click", _objectUngroup);

        document.querySelector(".js__surface-ctrl-skewx-up").addEventListener("click", _objectSkewXUp);
        document.querySelector(".js__surface-ctrl-skewx-down").addEventListener("click", _objectSkewXDown);
        document.querySelector(".js__surface-ctrl-skewy-up").addEventListener("click", _objectSkewYUp);
        document.querySelector(".js__surface-ctrl-skewy-down").addEventListener("click", _objectSkewYDown);

        document.querySelector(".js__surface-ctrl-shadowl").addEventListener("click", _objectShadowLeft);
        document.querySelector(".js__surface-ctrl-shadowr").addEventListener("click", _objectShadowRight);
        document.querySelector(".js__surface-ctrl-shadow-remove").addEventListener("click", _objectShadowRemove);

        document.querySelector(".js__surface-ctrl-stack").addEventListener("click", _objectsStack);
        document.querySelector(".js__surface-ctrl-opacity").addEventListener("input", _objectChangeOpacity);
        document.querySelector(".js__surface-ctrl-reposition").addEventListener("click", _objectReposition);

        document.querySelector(".js__surface-ctrl-add-vpanel").addEventListener("click", _selectVideoPanelModal);
        document.querySelector(".js__surface-ctrl-pin-vpanel").addEventListener("click", _pinVideoPanel);

        document.querySelector(".js__surface-ctrl-add-extras").addEventListener("click", _selectExtrasModal);
        document.querySelector(".js__surface-ctrl-pin-extras").addEventListener("click", _pinExtras);

        document.querySelector(".js__surface-upload-metafile").addEventListener("click", _metafileModal);
    };

    var _objectLock = function () {
        /*
        * Lock Object(s);
        * Will result in obj movement & scaling being supressed.
        */
        [].forEach.call(_surface.getActiveObjects(), function (obj) {
            obj.lockObject();
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectUnlock = function () {
        /*
        * Unock Object(s);
        * Will result in obj movement & scaling being reinitiated.
        */
        [].forEach.call(_surface.getActiveObjects(), function (obj) {
            obj.unlockObject();
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectBlock = function () {
        /*
        * Block state supresses dynamic behavior (stats) for Surface items
        * which are Products;
        * Will only have effect if selection is a Product;
        * Will grey-out the item(s) to indicate the block state.
        */
        [].forEach.call(_surface.getActiveObjects(), function (obj) {

            if (obj.type === "group") {
                [].forEach.call(obj.getObjects(), function (innerObj) {

                    if (!innerObj.isProduct())
                        return;

                    innerObj.blockObject();
                });
            }

            if (!obj.isProduct())
                return;

            obj.blockObject();
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectUnblock = function () {
        /*
        * Block state supresses dynamic behavior (stats) for Surface items
        * which are Products;
        * Will only have effect if selection is a Product;
        * Will remove the grey-out filter to indicate the unblock/default state.
        */
        [].forEach.call(_surface.getActiveObjects(), function (obj) {

            if (obj.type === "group") {
                [].forEach.call(obj.getObjects(), function (innerObj) {

                    if (!innerObj.isProduct())
                        return;

                    innerObj.unblockObject();
                });
            }

            if (!obj.isProduct())
                return;

            obj.unblockObject();
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectGrow = function () {
        /*
        * Grow an item on the Surface using the default increment/decrement step;
        * Only do so if item is not locked (meaning it's scaling is locked).
        */
        [].forEach.call(_surface.getActiveObjects(), function (obj) {
            if (obj.isLocked())
                return;

            obj.set("scaleX", Math.min(obj.scaleX + _scaleStep, _maxScale));
            obj.set("scaleY", Math.min(obj.scaleY + _scaleStep, _maxScale));
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectShrink = function () {
        /*
        * Shrink an item on the Surface using the default increment/decrement step;
        * Only do so if item is not locked (meaning it's scaling is locked).
        */
        [].forEach.call(_surface.getActiveObjects(), function (obj) {
            if (obj.isLocked())
                return;

            obj.set("scaleX", Math.max(obj.scaleX - _scaleStep, _minScale));
            obj.set("scaleY", Math.max(obj.scaleY - _scaleStep, _minScale));
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectForward = function () {
        // Manages Z-index of the Object
        [].forEach.call(_surface.getActiveObjects(), function (obj) {
            _surface.bringForward(obj);
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectBackwards = function () {
        // Manages Z-index of the Object
        [].forEach.call(_surface.getActiveObjects(), function (obj) {
            _surface.sendBackwards(obj);
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectClone = function () {
        [].forEach.call(_surface.getActiveObjects(), function (obj) {
            _surface.discardActiveObject();
            /*
             * Unlike fabric.util.object.clone, clone function creates clean copy of the object,
             * with no references attached.
             * It is necessary to set object properties like eventListeners and visibilityControls anew,
             * copying them explicitly from the origin object.
             */
            obj.clone(function (theClone) {
                /*
                 * Find the the selected object top-left point's coordinates
                 * and set them to clone object as top left coordinates
                 */
                theClone.set("left", obj.getAbsoluteCenterPoint().x - (obj.width * obj.scaleX) / 2 + 10);
                theClone.set("top", obj.getAbsoluteCenterPoint().y - (obj.height * obj.scaleY) / 2 + 0);
                theClone.setCoords();

                // clone important object properties
                theClone.__eventListeners = obj.__eventListeners;
                theClone._controlsVisibility = obj._controlsVisibility;

                _surface.add(theClone);
            });
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectGroup = function () {
        var objs = _surface.getActiveObjects();

        if (objs.length === 0)
            return;

        _surface.discardActiveObject();
        var oLeft = 9999,
            oTop = 9999;
        /*
         * Find the the selected group top-left point's coordinates;
         * Push object clones into new array.
         */
        var clones = [];
        [].forEach.call(objs, function (obj) {
            if (obj.type !== "group") { // groups can't form other groups (also, priceboxes are groups!)
                oLeft = Math.min(obj.getAbsoluteCenterPoint().x - (obj.width * obj.scaleX) / 2, oLeft);
                oTop = Math.min(obj.getAbsoluteCenterPoint().y - (obj.height * obj.scaleY) / 2, oTop);
                var aClone = fabric.util.object.clone(obj);
                aClone.set({
                    active: true
                });
                clones.push(aClone);
            }
        });

        // create Group using array of obj clones, top left coordinates
        var theGroup = new fabric.Group(clones, {
            subTargetCheck: true,
            selection: true
        });

        // remove origin object
        objs.filter(function (obj) {
            if (obj.type !== "group")
                _surface.remove(obj);
        });

        _surface.add(theGroup);
        _surface.setActiveObject(theGroup);

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectUngroup = function () {
        var activeObject = _surface.getActiveObject();

        if (activeObject.type !== "group")
            return;

        var items = activeObject.getObjects();
        activeObject._restoreObjectsState();
        _surface.remove(activeObject);

        for (var i = 0; i < items.length; i++) {
            _surface.add(items[i]);
            // TODO: 
            // SurfaceObjectEvents.initializeObjectEvents(items[i]);
        }

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectSkewXUp = function () {

        [].forEach.call(_surface.getActiveObjects(), function (obj) {

            if (obj.isLocked())
                return;

            var skewX = obj.skewX;
            obj.set("skewX", skewX + 1).setCoords();
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectSkewXDown = function () {
        [].forEach.call(_surface.getActiveObjects(), function (obj) {

            if (obj.isLocked())
                return;

            var skewX = obj.skewX;
            obj.set("skewX", skewX - 1).setCoords();
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectSkewYUp = function () {
        [].forEach.call(_surface.getActiveObjects(), function (obj) {

            if (obj.isLocked())
                return;

            var skewY = obj.skewY;
            obj.set("skewY", skewY + 1).setCoords();
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectSkewYDown = function () {
        [].forEach.call(_surface.getActiveObjects(), function (obj) {

            if (obj.isLocked())
                return;

            var skewY = obj.skewY;
            obj.set("skewY", skewY - 1).setCoords();
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectShadowLeft = function () {
        [].forEach.call(_surface.getActiveObjects(), function (obj) {
            obj.placeShadow("L");
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectShadowRight = function () {
        [].forEach.call(_surface.getActiveObjects(), function (obj) {
            obj.placeShadow("R");
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectShadowRemove = function () {

        [].forEach.call(_surface.getActiveObjects(), function (obj) {
            obj.shadow = null; // remove shadow
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectsStack = function () {
        var stackDirection = $(".js__surface-ctrl-stack-direction").val();

        if (stackDirection === "") {
            alert("Please pick the direction first");
            return;
        }

        var offsetLeft, offsetTop;

        switch (stackDirection) {
            case "Left":
                offsetLeft = 7;
                offsetTop = 0;
                break;
            case "Right":
                offsetLeft = -7;
                offsetTop = 0;
                break;
            case "Downwards-Left":
                offsetLeft = 7;
                offsetTop = -3;
                break;
            case "Downwards-Right":
                offsetLeft = -7;
                offsetTop = -3;
                break;
            case "Upwards-Left":
                offsetLeft = 7;
                offsetTop = 3;
                break;
            case "Upwards-Right":
                offsetLeft = -7;
                offsetTop = 3;
                break;
            default:
                break;
        }

        var stackedGroup = [];

        var obj = _surface.getActiveObject();

        if (!obj || obj.type === "group" || !obj.isProduct())
            return;

        stackedGroup.push(obj);
        _surface.discardActiveObject();

        obj.clone(function (theClone1) {
            theClone1.set("left", obj.getAbsoluteCenterPoint().x - (obj.width * obj.scaleX) / 2 + offsetLeft);
            theClone1.set("top", obj.getAbsoluteCenterPoint().y - (obj.height * obj.scaleY) / 2 + offsetTop);
            theClone1.setCoords();

            theClone1.__eventListeners = obj.__eventListeners;
            theClone1._controlsVisibility = obj._controlsVisibility;

            stackedGroup.push(theClone1);

            theClone1.clone(function (theClone2) {
                theClone2.set("left", obj.getAbsoluteCenterPoint().x - (obj.width * obj.scaleX) / 2 + 2 * offsetLeft);
                theClone2.set("top", obj.getAbsoluteCenterPoint().y - (obj.height * obj.scaleY) / 2 + 2 * offsetTop);
                theClone2.setCoords();

                theClone2.__eventListeners = obj.__eventListeners;
                theClone2._controlsVisibility = obj._controlsVisibility;

                stackedGroup.push(theClone2);

                var stackedGroupObj = new fabric.Group(stackedGroup, {
                    subTargetCheck: true,
                    selection: true
                });

                _surface.remove(obj);
                _surface.add(stackedGroupObj);

                _surface.renderAll();
                globalCallbackHandler.handle("surface-states-append");
            });
        });
    };

    var _objectChangeOpacity = function (e) {
        var input = e.target;
        var opacity = parseInt(input.value);
        opacity = Math.max(input.attributes.min.value, Math.min(opacity, 100)) / 100;

        [].forEach.call(_surface.getActiveObjects(), function (obj) {
            if (obj.type === "group") {
                [].forEach.call(obj.getObjects(), function (groupElement) {
                    if (!groupElement.isProduct())
                        return;

                    groupElement.set("opacity", opacity);
                });
            } else {
                if (!obj.isProduct())
                    return;

                obj.set("opacity", opacity);
            }
        });

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _objectReposition = function () {
        var obj = _surface.getActiveObject();

        if (!document.querySelector(".js__surface-ctrl-offsetx") || !document.querySelector(".js__surface-ctrl-offsety"))
            return;

        var oLeft = parseInt(document.querySelector(".js__surface-ctrl-offsetx").value);
        var oTop = parseInt(document.querySelector(".js__surface-ctrl-offsety").value) - (obj.height * obj.scaleY);
        obj.set({
            left: oLeft,
            top: oTop
        }).setCoords();

        _surface.renderAll();
        globalCallbackHandler.handle("surface-states-append");
    };

    var _selectVideoPanelModal = function () {
        $("#js__vpanel-selection-modal").modal("show");
    };

    var _pinVideoPanel = function () {
        var id = $(".js__vpanel-selection").val();

        if (id === null) {
            $(".js__vpanel-selection").addClass("error");
            alert("Please select an option in the selectbox");
            return;
        }

        var length = +$(".js__vpanel-selection option:selected").data("length");
        var fps = +$(".js__vpanel-selection option:selected").data("fps");

        import(/* webpackMode: 'lazy' */ "../util/video_panels_manager")
            .then(VpManager => {
                VpManager.pinToSurface(id, length, fps);
            });

        $(".js__vpanel-selection").removeClass("error");
        $("#js__vpanel-selection-modal").modal("hide");
    };

    var _selectExtrasModal = function () {
        $("#js__extras-selection-modal").modal("show");
    };

    var _pinExtras = function () {

        var type = $(".js__extras-selection").val();
        var url = $(".js__extras-url").val();
        var text = $(".js__extras-text").val();

        if (type === null) {
            $(".js__extras-selection").addClass("error");
            alert("Please select an option in the selectbox");
            return;
        }

        switch (type) {
            case "IFRAME":
                if (url === "") {
                    $(".js__extras-url").addClass("error");
                    alert("Please input URL for IFrame");
                    return;
                }

                $(".js__extras-url").removeClass("error");
                import(/* webpackMode: 'lazy' */ "../util/extras_manager")
                    .then(ExtrasManager => {
                        ExtrasManager.pinToSurface("EXTRAS." + type, url, text);
                    });
                $("#js__extras-selection-modal").modal("hide");
                break;
            case "WEBVIDEO":
                if (url === "") {
                    $(".js__extras-url").addClass("error");
                    alert("Please input URL for Video");
                    return;
                }

                $(".js__extras-url").removeClass("error");
                import(/* webpackMode: 'lazy' */ "../util/extras_manager")
                    .then(ExtrasManager => {
                        ExtrasManager.pinToSurface("EXTRAS." + type, url, text);
                    });
                $("#js__extras-selection-modal").modal("hide");
                break;
            case "CLICKCATCH":
                if (text === "") {
                    $(".js__extras-text").addClass("error");
                    alert("Please input ID text for Click Catcher");
                    return;
                }

                $(".js__extras-text").removeClass("error");
                import(/* webpackMode: 'lazy' */ "../util/extras_manager")
                    .then(ExtrasManager => {
                        ExtrasManager.pinToSurface("EXTRAS." + type, url, text);
                    });
                $("#js__extras-selection-modal").modal("hide");
                break;
            default: break;
        }
    };

    var _metafileModal = function () {
        $("#js__surface-metafile-modal").modal("show");
    };

    var _surface = globalSurfaceConfig.surface;
}

var sc = new SurfaceObjectControls();
sc.init();
module.exports = sc;