function ShelfsetTaskTypes() {

    var $toggle_ = $(".js__shelfset-tasktype-selection");

    this.init = function () {

        if ($toggle_.length === 0)
            return;

        $toggle_.on("change", _presetOptions);
    };

    var $confQuantityLimit_ = $(".js__shelfset-limit-qty");
    var $confAsideNavigation_ = $(".js__shelfset-aside-nav");
    var $confBackwardsNavigation_ = $(".js__shelfset-backwards-nav");
    var $confHideCartPanel_ = $(".js__shelfset-cart-hidden");
    var $confLockAddedProduct_ = $(".js__shelfset-lock-added-product");
    var $confDisableTooltips_ = $(".js__shelfset-disable-tooltips");

    var _presetOptions = function () {
        switch (+this.value) {
            case 1: // custom vs task single
            case 2: // custom vs task multiple
                $confQuantityLimit_.val(0);
                $confAsideNavigation_.prop("checked", false);
                $confBackwardsNavigation_.prop("checked", false);
                $confHideCartPanel_.prop("checked", false);
                $confLockAddedProduct_.prop("checked", false);
                $confDisableTooltips_.prop("checked", false);
                tinymce.get("js__shelfset-infm-instr1").setContent("");
                tinymce.get("js__shelfset-infm-instr2").setContent("");
                tinymce.get("js__shelfset-infm-instr3").setContent("");
                tinymce.get("js__shelfset-infm-instr4").setContent("");
                tinymce.get("js__shelfset-infm-instr5").setContent("");
                break;
            case 3: // findability task
                $confQuantityLimit_.val(1);
                $confAsideNavigation_.prop("checked", false);
                $confBackwardsNavigation_.prop("checked", false);
                $confHideCartPanel_.prop("checked", false);
                $confLockAddedProduct_.prop("checked", false);
                $confDisableTooltips_.prop("checked", true);
                tinymce.get("js__shelfset-infm-instr1").setContent("");
                tinymce.get("js__shelfset-infm-instr2").setContent("");
                tinymce.get("js__shelfset-infm-instr3").setContent("");
                tinymce.get("js__shelfset-infm-instr4").setContent("");
                tinymce.get("js__shelfset-infm-instr5").setContent("");
                break;
            case 4: // decision tree task
                $confQuantityLimit_.val(0);
                $confAsideNavigation_.prop("checked", false);
                $confBackwardsNavigation_.prop("checked", false);
                $confHideCartPanel_.prop("checked", true);
                $confLockAddedProduct_.prop("checked", true);
                $confDisableTooltips_.prop("checked", false);
                break;
            case 5: // navigation task
                $confQuantityLimit_.val(0);
                $confAsideNavigation_.prop("checked", true);
                $confBackwardsNavigation_.prop("checked", false);
                $confHideCartPanel_.prop("checked", false);
                $confLockAddedProduct_.prop("checked", false);
                $confDisableTooltips_.prop("checked", false);
                tinymce.get("js__shelfset-infm-instr1").setContent("");
                tinymce.get("js__shelfset-infm-instr2").setContent("");
                tinymce.get("js__shelfset-infm-instr3").setContent("");
                tinymce.get("js__shelfset-infm-instr4").setContent("");
                tinymce.get("js__shelfset-infm-instr5").setContent("");
                break;
            default:
                $confQuantityLimit_.val(0);
                $confAsideNavigation_.prop("checked", false);
                $confBackwardsNavigation_.prop("checked", true);
                $confHideCartPanel_.prop("checked", false);
                $confLockAddedProduct_.prop("checked", false);
                $confDisableTooltips_.prop("checked", false);
                tinymce.get("js__shelfset-infm-instr1").setContent("");
                tinymce.get("js__shelfset-infm-instr2").setContent("");
                tinymce.get("js__shelfset-infm-instr3").setContent("");
                tinymce.get("js__shelfset-infm-instr4").setContent("");
                tinymce.get("js__shelfset-infm-instr5").setContent("");
                break;
        }
    };

}

var sstt = new ShelfsetTaskTypes();
sstt.init();
module.exports = sstt;