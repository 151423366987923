function ButtonDeleteDsImg() {

    this.init = function () {
        $(document).on("click", ".js__btn-delete-dsimg", _deleteDsImg);
    };

    var _deleteDsImg = function () {

        if (!confirm("Are you sure? This file will be lost permanently."))
            return false;

        var $wrapper = $(this).parents(".js__ds-file-item");

        var datasourceId = $wrapper.data("datasource-id"),
            fileName = $wrapper.data("img-filename");

        $.ajax({
            url: `/data-sources/${datasourceId}/files/${fileName}`,
            method: "DELETE",
            success: function (response) {
                $wrapper.remove();
            },
            error: function (response) {
                alert("ERROR; Could not complete request");
            }
        });

    };
}

var bdsi = new ButtonDeleteDsImg();
bdsi.init();
module.exports = bdsi;