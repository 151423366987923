function Navbar() {

    var $_navbar = $("#main-navbar");

    this.init = function () {
        $(document).on("click", ".js__btn-navbar-toggle", _toggleNavbar);
    };

    var _toggleNavbar = function () {
        $_navbar.toggleClass("active");
    };

}

var n = new Navbar();
n.init();
module.exports = n;