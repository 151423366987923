function SurfaceZoomPanControls() {

    var _surface = globalSurfaceConfig.surface;

    var _module = this;

    var _maxZoom = 6;
    var _zoomStepsCount = 10;
    var _zoomStep = 0.5;
    var _zoomStartLevel = 1;
    /*
    * Imagine Surface as a 3x3 Matrix;
    * Sector 5 is center-center;
    */
    var _zoomStartSector = 5;

    var _isFrontend = false;

    var _totalPanX = 0;
    var _totalPanY = 0;

    var _pausePanning = false;
    var _pinchZoomLevel = 1;

    this.init = function () {
        /*
        * Override default zoom configurations only if Shelf is displayed in frontend/static mode.
        * For edit mode stick to default/optimal presets.
        */
        if (document.getElementById("js__surface-static"))
            _isFrontend = true;

        _presetZoomConfiguration();
        _presetStartZoom();

        $(document).on("click", ".js__surface-ctrl-zoom-in", _module.surfaceZoomIn);
        $(document).on("click", ".js__surface-ctrl-zoom-out", _module.surfaceZoomOut);

        $(document).on("click", ".js__surface-pan-up", _surfacePanUp);
        $(document).on("click", ".js__surface-pan-down", _surfacePanDown);
        $(document).on("click", ".js__surface-pan-left", _surfacePanLeft);
        $(document).on("click", ".js__surface-pan-right", _surfacePanRight);

        /*
        * If Zoom is not Enabled for the specific Surface,
        * exit early - before initiating frontend events related to
        * zooming & panning & zooming initialization.
        */
        if (+document.querySelector(".js__surface-zoom-enabled").value === 0 &&
            _isFrontend === true)
            return;

        _surface.on("mouse:wheel", function (opt) {
            var delta = (((opt.e.deltaY || -opt.e.wheelDelta || opt.e.detail) >> 10) || 1) * 100;
            var zoom = this.getZoom();
            zoom = zoom - 100 / delta * _zoomStep;

            if (zoom > _maxZoom) zoom = _maxZoom;
            zoom = Math.max(1, zoom);

            /*
            * If zoom is unchanged, exit here from the function;
            * This premature exit will serve not to prevent zoom defaults,
            * meaning that mousewheel will in these cases not zoom the Surface,
            * but make a page scroll up/down instead.
            * 
            * These two if blocks serve to also fix SHEL-195.
            */
            if (_surface.getZoom() === _maxZoom && delta < 0) {
                _module.surfaceZoomIn();
                return;
            }
            if (_surface.getZoom() === 1 && delta > 0) {
                _module.surfaceZoomOut(false);
                return;
            }

            this.zoomToPoint({
                x: opt.e.offsetX,
                y: opt.e.offsetY
            }, zoom);
            opt.e.preventDefault();
            opt.e.stopPropagation();

            _surfaceProcessCentering(zoom);
            _updateZoomAndPanCtrlsState();
        });

        _surfaceProcessPanning();

        // only support gesture events for static surface
        if (!_isFrontend)
            return;

        _surface.on("touch:gesture", function (e) {

            if (!globalSurfaceConfig.isTouchEnabled)
                return;

            if (e.e.touches && e.e.touches.length == 2) {

                _pausePanning = true;

                var point = new fabric.Point(e.self.x, e.self.y);

                if (e.self.state == "start")
                    _pinchZoomLevel = self.canvas.getZoom();

                var delta = 0;

                if (e.self.scale > 1)
                    delta = _pinchZoomLevel * e.self.scale;
                else
                    delta = -(_pinchZoomLevel / e.self.scale);

                /*
                * Pinch zoom delta is too jumpy and does not reflect the setting of zoom steps count;
                * In order to slow it down, introduced a retardation coefficient (16),
                * which is hardcoded to a value which best simulates zoom steps setting.
                */
                _pinchZoomLevel += delta / _zoomStepsCount / 16;

                if (_pinchZoomLevel > _maxZoom) _pinchZoomLevel = _maxZoom;
                _pinchZoomLevel = Math.max(1, _pinchZoomLevel);

                /*
                * If zoom is unchanged, exit here from the function;
                * This premature exit will serve not to prevent zoom defaults,
                * meaning that mousewheel will in these cases not zoom the Surface,
                * but make a page scroll up/down instead.
                */
                if (_pinchZoomLevel === _surface.getZoom())
                    return;

                this.zoomToPoint(point, _pinchZoomLevel);
                _surfaceProcessCentering(_pinchZoomLevel);
                _updateZoomAndPanCtrlsState();

                _pausePanning = false;
            }
        });

        _surface.on("touch:drag", function (e) {

            if (!globalSurfaceConfig.isTouchEnabled)
                return;

            /*
            * This block handles Surface touch-panning;
            * Use 1 finger to pan the previously zoomed-in Surface in any direction.
            */
            if (!_pausePanning &&
                undefined != e.e.layerX &&
                undefined != e.e.layerY) {

                var currentX = e.e.layerX;
                var currentY = e.e.layerY;
                var xChange = currentX - _totalPanX;
                var yChange = currentY - _totalPanY;

                if ((Math.abs(currentX - _totalPanX) <= 50) &&
                    (Math.abs(currentY - _totalPanY) <= 50)) {
                    var delta = new fabric.Point(xChange, yChange);
                    _surface.relativePan(delta);
                }

                _totalPanX = e.e.layerX;
                _totalPanY = e.e.layerY;

                _surfaceProcessCentering(_pinchZoomLevel);
                _updateZoomAndPanCtrlsState();
            }
        })
    };

    this.surfaceZoomIn = function () {
        var zoom = Math.min(_maxZoom, _surface.getZoom() + _zoomStep);
        _surface.zoomToPoint(new fabric.Point(_surface.width / 2, _surface.height / 2), zoom);
        _surfaceProcessCentering(zoom);
        _updateZoomAndPanCtrlsState();
        _surface.renderAll();
    };

    this.surfaceZoomOut = function (allowOutOfBoundsZoom = true) {
        /*
        * Sometimes Editors place elements on the very edges of the Surface,
        * and once they deselect these, they can no longer select them to
        * bring them back to the Surface;
        * In that regard, it is useful to allow Editor to zoom out
        * beyond zoom=1, to be able to see arround the Surface's edges
        * and find these stray items.
        * Allow zoom out to a minimum of 80%.
        * Only using the Control!
        * This behavior is not implemented for the mousewheel roll.
        */
        var zoomOutOfBoundsCoefficient = 0;
        if (allowOutOfBoundsZoom === true)
            zoomOutOfBoundsCoefficient = -0.2;

        /*
        * If Zoom control (button) explicitly states that zoom below 1 should be prevented,
        * (such as the case of Static Surface zoom out control),
        * override previously set coefficient.
        */
        if ($(this).data("allow-extra-unzoom") == false)
            zoomOutOfBoundsCoefficient = 0;

        var zoom = Math.max(1 + zoomOutOfBoundsCoefficient, _surface.getZoom() - _zoomStep);
        _surface.zoomToPoint(new fabric.Point(_surface.width / 2, _surface.height / 2), zoom);
        _surfaceProcessCentering(zoom);
        _updateZoomAndPanCtrlsState();
        _surface.renderAll();
    };

    var _presetZoomConfiguration = function () {

        _maxZoom = +$(".js__surface-zoom-max-level").val();
        _zoomStepsCount = +$(".js__surface-zoom-steps").val();

        if (_maxZoom === 1)
            _zoomStep = 0; // no zoom if max zoom level is 1
        else
            _zoomStep = (_maxZoom - 1) / _zoomStepsCount;

        _zoomStartLevel = +$(".js__surface-zoom-start-level").val();
        _zoomStartSector = +$(".js__surface-zoom-focal-sector").val();
    };

    var _presetStartZoom = function () {
        /*
        * If special zoom configuration is defined for the Shelf,
        * within the static view these properties will be output as
        * data attributes;
        * Collect this data from the DOM element's dataset and
        * preset/override-default zoom configuration.
        */
        _zoomStartLevel = +$(".js__surface-zoom-start-level").val();
        _zoomStartSector = +$(".js__surface-zoom-focal-sector").val();

        function range(low, high, step) {
            function rangeRec(low, high, step, vals) {
                if (low > high) return vals;
                vals.push(low);
                return rangeRec(low + step, high, step, vals);
            }
            return rangeRec(low, high, step, []);
        }

        /*
        * Determine initial zoom level,
        * having the defined constraints in mind.
        */
        var initialZoomLevel;
        if (_maxZoom === 1)
            initialZoomLevel = 1;
        else
            initialZoomLevel = range(1, _maxZoom, _zoomStep).reduce(function (prev, curr) {
                return (Math.abs(curr - _zoomStartLevel) < Math.abs(prev - _zoomStartLevel) ? curr : prev);
            });

        var point = new fabric.Point(_surface.width / 2, _surface.height / 2);
        switch (_zoomStartSector) {
            case 1:
                point = new fabric.Point(_surface.width / 6, _surface.height / 6);
                break;
            case 2:
                point = new fabric.Point(_surface.width / 2, _surface.height / 6);
                break;
            case 3:
                point = new fabric.Point(5 * _surface.width / 6, _surface.height / 6);
                break;
            case 4:
                point = new fabric.Point(_surface.width / 6, _surface.height / 2);
                break;
            case 5:
                point = new fabric.Point(_surface.width / 2, _surface.height / 2);
                break;
            case 6:
                point = new fabric.Point(5 * _surface.width / 6, _surface.height / 2);
                break;
            case 7:
                point = new fabric.Point(_surface.width / 6, 5 * _surface.height / 6);
                break;
            case 8:
                point = new fabric.Point(_surface.width / 2, 5 * _surface.height / 6);
                break;
            case 9:
                point = new fabric.Point(5 * _surface.width / 6, 5 * _surface.height / 6);
                break;
            default: break;
        }

        _surfaceZoomInToPoint(point, initialZoomLevel);
    };

    var _surfaceZoomInToPoint = function (point, zoom) {
        _surface.zoomToPoint(point, zoom);
        _surfaceProcessCentering(zoom);
        _updateZoomAndPanCtrlsState();
        _surface.renderAll();
    };

    var _surfaceProcessPanning = function () {
        _surface.on("mouse:down", function (opt) {
            var evt = opt.e;
            if (evt.altKey === true || _isFrontend) {
                this.isDragging = true;
                this.selection = false;
                this.lastPosX = evt.clientX;
                this.lastPosY = evt.clientY;
            }
        });

        _surface.on("mouse:move", function (opt) {
            if (this.isDragging) {

                var e = opt.e;
                var offsetX = 0;
                var offsetY = 0;
                var newPosX = 0;
                var newPosY = 0;

                if (e instanceof MouseEvent) { // desktop
                    offsetX = (e.clientX - this.lastPosX) /* * this.getZoom()*/;
                    offsetY = (e.clientY - this.lastPosY) /* * this.getZoom()*/;
                    newPosX = e.clientX;
                    newPosY = e.clientY;
                } else if (e instanceof TouchEvent) { // tablet, mobile
                    offsetX = (e.touches[0].clientX - this.lastPosX) /* * this.getZoom()*/;
                    offsetY = (e.touches[0].clientY - this.lastPosY) /* * this.getZoom()*/;
                    newPosX = e.touches[0].clientX;
                    newPosY = e.touches[0].clientY;
                }

                if (!isNaN(offsetX) &&
                    !isNaN(offsetY) &&
                    offsetX != 0 &&
                    offsetY != 0) {
                    this.viewportTransform[4] += offsetX;
                    this.viewportTransform[5] += offsetY;
                    _totalPanX += offsetX;
                    _totalPanY += offsetY;
                    _surfaceProcessCentering(_surface.getZoom());
                    _updateZoomAndPanCtrlsState();
                    this.requestRenderAll();
                    this.lastPosX = newPosX;
                    this.lastPosY = newPosY;
                }

                globalSurfaceConfig.pan = {};
                globalSurfaceConfig.pan.x = _totalPanX;
                globalSurfaceConfig.pan.y = _totalPanY;
            }
        });

        _surface.on("mouse:up", function (opt) {

            /*
            * 14.04.2021
            * SHEL-177
            * 
            * This was a very tricky one:
            * At some times (real reason was difficult to hunt down) object click
            * event was not triggered;
            * Instead, only mousedown was fired from the Surface.
            * As a workaround, use a custom-made surface function to detect the object
            * sitting behind pointer coordinates, and programatically fire
            * a click event with it being the target.
            * This way, a Product details popup will launch, if other conditions are OK.
            */
            if (Math.abs(this.lastPosX - opt.e.x) < 5 &&
                Math.abs(this.lastPosY - opt.e.y) < 5 &&
                opt.target === null) {

                var targets = this.findByCoordinates(opt.absolutePointer.x, opt.absolutePointer.y);

                if (targets.length > 0) {
                    var tgt = targets[0];
                    tgt.fire("mouseup", {
                        target: tgt
                    });
                }
            }

            this.isDragging = false;
            this.selection = true;
            this.renderAll();
            var objects = this.getObjects();
            for (var i = 0; i < objects.length; i++) {
                objects[i].setCoords();
            }

            document.dispatchEvent(new CustomEvent("surface-click-reenable"));
        });
    };

    /*
     * This function is used to prevent zooming/unzooming
     * and/or panning actions from offsetting surface out of bounds.
     */
    var _surfaceProcessCentering = function () {
        var vpt = _surface.viewportTransform;
        if (vpt[4] >= 0) {
            _surface.viewportTransform[4] = 0;
        } else if (vpt[4] < _surface.getWidth() - _surface.getWidth() * _surface.getZoom()) {
            _surface.viewportTransform[4] = _surface.getWidth() - _surface.getWidth() * _surface.getZoom();
        }
        if (vpt[5] >= 0) {
            _surface.viewportTransform[5] = 0;
        } else if (vpt[5] < _surface.getHeight() - _surface.getHeight() * _surface.getZoom()) {
            _surface.viewportTransform[5] = _surface.getHeight() - _surface.getHeight() * _surface.getZoom();
        }
    };

    var _updateZoomAndPanCtrlsState = function () {
        $(".js__surface-ctrl-zoom-in").toggleClass("disabled", _surface.getZoom() === _maxZoom);
        $(".js__surface-ctrl-zoom-out").toggleClass("disabled", _surface.getZoom() <= 1);
        $(".js__surface-panning-controls").toggle(_surface.getZoom() > 1);
    };

    var _surfacePanUp = function () {
        _surface.viewportTransform[5] += 50 * _surface.getZoom();
        _surfaceProcessCentering();
        _surface.requestRenderAll();
    };

    var _surfacePanDown = function () {
        _surface.viewportTransform[5] -= 50 * _surface.getZoom();
        _surfaceProcessCentering();
        _surface.requestRenderAll();
    };

    var _surfacePanLeft = function () {
        _surface.viewportTransform[4] += 50 * _surface.getZoom();
        _surfaceProcessCentering();
        _surface.requestRenderAll();
    };

    var _surfacePanRight = function () {
        _surface.viewportTransform[4] -= 50 * _surface.getZoom();
        _surfaceProcessCentering();
        _surface.requestRenderAll();
    };
}

var zp = new SurfaceZoomPanControls();
zp.init();
module.exports = zp;