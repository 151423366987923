function ButtonMassDownload() {

    this.init = function () {
        $(document).on("click", ".js__project-mass-dl", function () {
            var message = "Please note: Pay attention to the selection of Projects below.\r\n";
            message += "Performing this action on an insufficiently narrowed down set of Projects may have significant performance implications.";
            if (!confirm(message))
                return false;

            var $keyword = $(".dataTables_filter").find("input"),
                keyword = $keyword.val();

            window.location.href = "/downloads/reports/envelopes/mass/search?keyword=" + keyword;
        });
    };
}

var bmd = new ButtonMassDownload();
bmd.init();