function ShopcartControls() {

    this.init = function () {
        $(document).on("click", ".js__seq-shopcart-add-btn", _addItemToCart);
        $(document).on("click", ".js__seq-shopcart-remove-btn", _removeItemFromCart);

        $(document).on("click", ".js__seq-navlink", _processSeqNavigation);

        $(document).on("change", ".js__seq-shopcart-product-quantity", _changeCartItemQuantity);

        $(document).on("cart-input-reset-value", _resetCartinputValue);
        $(document).on("cart-update-input", _updateInput);
    };

    var _addItemToCart = function () {

        if (!document.querySelector(".js__seq-shopcart-product-qty"))
            return;

        var isBulkProduct = +$(".js__product-isbulk").val() === 1;

        /*
        * If bulk Product, bulk input must be specially validated for numeric value;
        * Run the check dynamically with Parsley.
        * If value is invalid, prevent adding to Cart.
        */
        if (isBulkProduct && !_isValidBulkQuantity())
            return;

        var selectedQuantity = 1;
        if (isBulkProduct) {
            // bulk product - qty comes from a text input
            selectedQuantity = +$(".js__seq-shopcart-product-qty").val();
        } else {
            // standard product - qty comes from dropdown select
            selectedQuantity = +$(".js__seq-shopcart-product-qty").selectpicker("val");
        }

        var event = new CustomEvent("cart-add-product", {
            detail: {
                id: $(".js__product-id").val(),
                u: $(".js__product-unit").val(),
                p: +$(".js__product-price").val().replace(/,/g, ''),
                qty: selectedQuantity,
                qtyCalc: +$(".js__product-isbulk").val() === 1 ? 1 : selectedQuantity,
                shallow: false
            }
        });
        document.dispatchEvent(event);

        document.dispatchEvent(new CustomEvent("cart-save"));

        document.dispatchEvent(new CustomEvent("survey-product-consideration", {
            detail: {
                pid: $(".js__product-id").val(),
                qty: +$(".js__seq-shopcart-product-qty").val()
            }
        }));

        document.dispatchEvent(new CustomEvent("hide-product-modal-static"));
    };

    var _removeItemFromCart = function () {

        var productId = this.dataset.productId;

        var event = new CustomEvent("cart-remove-product", {
            detail: {
                id: productId
            }
        });
        document.dispatchEvent(event);

        document.dispatchEvent(new CustomEvent("cart-save"));

        $(this).parents(".js__seq-shopcart-product").slideUp(300, () => { $(this).remove(); });

    };

    var _resetCartinputValue = function () {
        var evt = arguments[0];

        var input = evt.detail.input,
            resetValue = evt.detail.resetValue,
            labelTotalVal = evt.detail.labelTotalVal,
            resetTotal = evt.detail.resetTotal;

        setTimeout(function () {

            input.value = resetValue;
            if (input.tagName === "SELECT")
                $(input).selectpicker("val", resetValue);

            $(input).data("initval", resetValue);
            $(input).prop("data-initval", resetValue);
            input.dataset.initval = resetValue;

            $(labelTotalVal).html(resetTotal);
        }, 500);
    };

    var _changeCartItemQuantity = function () {

        if (this.classList.contains("bootstrap-select"))
            return;

        var productId = this.dataset.productId;
        var price = +this.dataset.price;
        var quantity = +this.value;
        var isBulk = +this.dataset.productIsBulk;

        var that = this;

        var productId = that.dataset.productId,
            $labelTotalVal = $(".js__seq-shopcart-product-total[data-id='" + productId + "']"),
            labelTotalVal = $labelTotalVal.first();

        $labelTotalVal.html((price * quantity).toFixed(2));

        /*
        * SHEL-367
        * 12.11.2022.
        * 
        * Propagate calculated qty change stat object to Shopcart Manager;
        * From there, stat event will be fired but *only* in case of successful validations.
        * Refer to other comment blocks tagged with this item (SHEL-367) for more details
        */
        var qtyChangeStatObj = _processQuantityChange(that);

        /*
        * Timeout interval is used to retardate async function execution
        * when user is typing inside the input type="text";
        * This delay is unnecessary when 'change' originates from the select/dropdown element,
        * so remove it to speed the action up.
        */
        _changeTimeoutInterval = 1000;
        if (that.tagName === "SELECT")
            _changeTimeoutInterval = 1;

        clearTimeout(_changeTimeoutId);
        _changeTimeoutId = setTimeout(function () {
            var event = new CustomEvent("cart-add-product", {
                detail: {
                    id: productId,
                    qty: quantity,
                    qtyCalc: isBulk === 1 ? 1 : quantity,
                    p: price,
                    shallow: true,
                    /*
                    * These below are used to reset input value;
                    * This happens when Cart limits would be exceeded 
                    * if new value was allowed inside the quantity input/select. 
                    */
                    input: that,
                    resetValue: that.dataset.initval,
                    labelTotalVal: labelTotalVal,
                    resetTotal: (price * that.dataset.initval).toFixed(2),
                    /*
                    * SHEL-367
                    * Propagate qty change stat object to Shopcart Manager, from where it will only be communicated to Stats
                    * in case when validation was successfully
                    */
                    qtyChangeStatObj: qtyChangeStatObj
                }
            });
            document.dispatchEvent(event);
        }, _changeTimeoutInterval);
    };

    var _updateInput = function () {
        var evt = arguments[0];
        var input = evt.detail.input,
            value = evt.detail.value;

        $(input).data("initval", value);
        $(input).prop("data-initval", value);
        input.dataset.initval = value;
    };

    var _processQuantityChange = function (input) {

        var productId = input.dataset.productId;
        var quantity = +input.value;
        var diff = quantity - +input.dataset.initval;

        if (diff === 0)
            return;

        var action = "cartUp";
        if (diff < 0)
            action = "cartDown";

        var qtyCalc = diff;

        return {
            a: action,
            ats: Math.floor(Date.now() / 1000),
            pid: productId,
            ad: Math.abs(diff),
            qty_calc: qtyCalc
        };
    };

    var _isValidBulkQuantity = function () {

        var $balkQtyInput = $(".js__bulkqty-numeric");

        if ($balkQtyInput.length === 0)
            return true;

        $balkQtyInput.parsley({
            errorClass: "error",
            errorTemplate: "<li class='alert alert-sm alert-danger'></li>"
        });

        var isValid = $balkQtyInput.parsley().validate();
        return isValid === true;
    };

    var _processSeqNavigation = function (e) {

        /*
        * Prevent default (redirect)
        * in order to retardate until all sh-leave actions are done.
        * In relation with SHEL-285.
        */
        e.preventDefault();

        /*
        * Speedrunner button has the same class for the sake of URL decorations,
        * but click on it should not trigger Shelf Leave event;
        * If this btn is clicked, exit early.
        */
        if (this.classList.contains("js__seq-speedrunner-btn"))
            return;

        /*
        * When navigating through Sequence (and actually navigating away from the currently open Shelf),
        * emit the following event: "cart-sh-leave";
        * By emitting this event, the Shelf unload tstamp will be preserved in the LocalStorage,
        * and will further on be used to properly handle "vacuum" time spent between Shelves
        * (time after one Shelf is left, but before the next one could be opened).
        */
        document.dispatchEvent(new CustomEvent("cart-sh-leave"));

        globalCallbackHandler.handle("show-loader");
        // redirect after 1500 ms retardation
        var redirUrl = this.href;
        setTimeout(function () {
            window.location.href = redirUrl;
        }, 1500);
    };

    var _changeTimeoutId = null;
    var _changeTimeoutInterval = 1000;

}

var scc = new ShopcartControls();
scc.init();
module.exports = scc;