
function SurfaceObjectsConstructor() {

    this.construct = function () {

        if (_surface === null) {
            console.error("Surface unbound to Objects Constructor");
            return;
        }

        if (globalSurfaceConfig.scaleCoefficient <= 0) {
            console.error("Scale Coefficient unassigned to Objects Constructor");
            return;
        }

        /*
        * 04.02.2021
        *
        * A new meta (textarea) is introduced in which up-to-date versions
        * of Product titles & appearances are stored;
        * This is forced by the fact that product titles/appearances embedded in Surface objects
        * may over time become deprecated - thus, sync is needed.
        * Load fresh meta from the containing DOM element.
        * Afterwards, remove that element from DOM.
        */
        _loadNewestProductMeta();

        [].forEach.call(_surface.getObjects(), function (object) {
            _constructObject(object);
        });

        _staticInstantiateVideoPanels();
        _staticInstantiateExtras();

        _surface.renderAll();
    };

    var _constructObject = function (object) {

        /*
        * When Surface was previously saved, it was saved in a way that all the Objects
        * were decorated to contain info about their offset from the then's central point;
        * Using new Surface central point and scaleCoefficient,
        * calculate new offset from the CP;
        * Add it up to the new CP and that way get the new left point for the Object.
        * 
        * Since Surface is built using height constraint 
        * (which means that effectiveWidth is being calculated; this is due to fact that Surface
        * Configuration is only allowed in landscape mode),
        * only perform this logic to x axis (left-right) offsets.
        */
        var cp = _surface.getCenter(); // Surface center point

        var xOffset = 0;
        /*
        * Deprecated on: 08.12.2020
        * Refer to: SHEL-82
        * Turns out there is no case in which * operator should be used between cpoffset and scale;
        * Always use / operator to accuratelly offset object in regard to Surface center.
        */
        /*if (object.cpOffset.left < 0)
            xOffset = object.cpOffset.left / globalSurfaceConfig.scaleCoefficient;
        else if (object.cpOffset.left > 0)
            xOffset = object.cpOffset.left * globalSurfaceConfig.scaleCoefficient;*/
        xOffset = object.cpOffset.left / globalSurfaceConfig.scaleCoefficient;

        object.set({
            left: cp.left + xOffset,
            top: object.top / globalSurfaceConfig.scaleCoefficient,
            scaleX: object.scaleX / globalSurfaceConfig.scaleCoefficient,
            scaleY: object.scaleY / globalSurfaceConfig.scaleCoefficient,
            /*
            * This property is important as it allows to handle clicks
            * accuratelly in cases when target object is not rectangular;
            * Only by clicking the actual Object, events will get emitted;
            * If instead clicked on Object's rectangular placeholder,
            * no events will be fired.
            */
            perPixelTargetFind: true,
            /*
            * Below properties are 'edit mode' properties;
            * These should be disallowed (set to FALSE) if in Static view,
            * and allowed (set to TRUE) if Shelf is in the Builder mode.
            */
            resize: _resizeAllowed,
            selectable: _selectionAllowed,
            /*
            * If Surface works over HTTPS, loading images my be and CORS-related issue;
            * With "anonymous", that problem may be worked around.
            */
            crossOrigin: "anonymous",
        }).setCoords();

        /*
        * Surface Object may contain a deprecated Product title & appearance;
        * Check whether a newer metadata is available, and update the
        * productTitle & appearance meta properties if so.
        */
        _updateProductMeta(object);

        /*
        * For Products, uniscaling is enforced;
        * That way, Product images are prevented from getting destorted
        * and are forced to maintain their ratio/quality.
        * Unlike Products, Video Panels and Special Objects do not fall under
        * same restriction;
        * For those, unlock non-uniscaling.
        */
        if (object.isVideoPanel() || object.isExtras())
            object.set({
                lockUniScaling: false,
            });

        /*
        * Subtarget check is an important property for Fabric's Groups;
        * It enables the exact group's subelement to emit events when interacted with,
        * instead of the entire Group doing so.
        * Therefore, enable this property (set to TRUE) for Groups.
        */
        if (object.type === "group")
            object.set({ subTargetCheck: true });
    };

    var _updateProductMeta = function (object) {

        if (!object.isProduct())
            return;

        if (!_productMeta.hasOwnProperty(object.meta.id))
            return;

        object.meta.productTitle = _productMeta[object.meta.id].title;
        object.meta.appearance = _productMeta[object.meta.id].appearance;
        object.meta.group = _productMeta[object.meta.id].group;
    };

    var _loadNewestProductMeta = function () {
        var prodsMetadata = document.querySelector(".js__surface-prod-meta");

        if (prodsMetadata === null)
            return;

        _productMeta = JSON.parse(prodsMetadata.value);
        /*
        * After JSON containing fresh versions of Product meta is loaded,
        * meta textarea is no longer needed;
        * Remove the redundant DOM node.
        */
        prodsMetadata.parentNode.removeChild(prodsMetadata);
    };

    var _staticInstantiateVideoPanels = function () {

        if (!_isStaticDisplayOnly)
            return;

        Array.prototype.forEach.call(_surface.getVideoPanels(), function (object) {
            import(/* webpackMode: 'lazy' */ "./util/video_panels_manager")
                .then(VpManager => {
                    VpManager.play(object);
                });
        });
    };

    var _staticInstantiateExtras = function () {

        if (!_isStaticDisplayOnly)
            return;

        Array.prototype.forEach.call(_surface.getExtras(), function (object) {
            object.set({ opacity: 0.01 });
        });
    };

    this.setIsStaticDisplayOnly = function (isStaticDisplayOnly) {
        _resizeAllowed = !isStaticDisplayOnly;
        _selectionAllowed = !isStaticDisplayOnly;
        _isStaticDisplayOnly = isStaticDisplayOnly;

        if (isStaticDisplayOnly) {
            import(/* webpackMode: 'lazy' */ "./events/static_object_events")
                .then(ObjectEvents => {
                    ObjectEvents.init();
                });
        } else {
            import(/* webpackMode: 'lazy' */ "./events/builder_object_events")
                .then(ObjectEvents => {
                    ObjectEvents.init();
                });
        }

        return this;
    };

    var _surface = globalSurfaceConfig.surface;

    var _productMeta = {};

    var _isStaticDisplayOnly = false;
    var _resizeAllowed = true;
    var _selectionAllowed = true;
}

var soc = new SurfaceObjectsConstructor();
module.exports = soc;