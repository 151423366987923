function SkipReasonsModal() {

    var _redirectUrl = "";

    this.init = function () {
        $(document).on("click", ".js__seq-speedrunner-btn", _launchModal);
        $(document).on("click", ".js__seq-skipreason-confirm", _submit);

        $(document).on("speedrunner-btn-toggle-state", _toggleBtn);

        // On modal close, reset reasons dropdown & reset error class if previously set
        $("#js__seq-skipreasons-modal").on("hide.bs.modal", function () {
            $(".js__seq-skipreason").selectpicker("val", "");
            $(".js__seq-skipreason").selectpicker("setStyle", "error", "remove");
        });
    };

    var _launchModal = function () {
        _redirectUrl = $(this).attr("href");
        $("#js__seq-skipreasons-modal").modal("show");
    };

    var _submit = function () {
        var reasonId = $(".js__seq-skipreason").selectpicker("val");

        /*
        * SHEL-379
        * 05.11.2022
        * 
        * Skip reason is mandatory;
        * If not selected, stop execution and indicate error.
        */
        if (reasonId === null) {
            $(".js__seq-skipreason").selectpicker("setStyle", "error", "add");
            return;
        }

        $("#js__seq-skipreasons-modal").modal("hide");

        _redirectUrl = _redirectUrl.replace(/nobuy=([^&]+)?/, "nobuy=" + reasonId);
        globalCallbackHandler.handle("show-loader");
        $("#js__seq-skipreasons-ty-modal").modal("show");

        setTimeout(function () {
            document.dispatchEvent(new CustomEvent("cart-sh-leave"));
        }, 1500);

        setTimeout(function () {
            window.location.href = _redirectUrl;
        }, 2500);
    };

    var _toggleBtn = function () {

        var evt = arguments[0];

        if (evt == undefined)
            return;

        var disableState = evt.detail.disableState;

        $(".js__seq-speedrunner-btn")
            .prop("disabled", disableState)
            .toggleClass("disabled", disableState);
    };
}

var srm = new SkipReasonsModal();
srm.init();