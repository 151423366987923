function BtnSequenceCounters() {

    var $_modal = null;

    this.init = function () {

        $(document).on("click", ".js__btn-seq-counters", function (e) {

            var seqId = $(this).data("sequenceId");
            $_modal = $("#js__sequence-counters-modal");

            $.ajax({
                url: "/downloads/reports/counters/" + seqId,
                method: "GET",
                beforeSend: function () {
                    globalCallbackHandler.handle("show-loader");
                },
                success: function (response) {

                    response = JSON.parse(response);

                    $_modal.find(".modal-body").html(response.html);
                    $_modal.modal("show");

                    globalCallbackHandler.handle("hide-loader");
                },
                error: function (response) {
                    alert(response.responseText);
                    globalCallbackHandler.handle("hide-loader");
                }
            })
        });

    };

}

var btnsc = new BtnSequenceCounters();
btnsc.init();
module.exports = btnsc;