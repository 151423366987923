function Tutorial() {

    var _currentStep = 1;
    var _totalSteps = 1;
    var _direction = "TO_RIGHT";

    this.init = function () {

        if ($(".js__seq-tutorial").length === 0)
            return;

        /*
        * When Tutorial is launched, upper and bottom control bars are visible;
        * Still, navigation controls inside these should not be active, although they are not explicitly disabled;
        * Prevent propagation of the click event in case when Tutorial is open.
        */
        $(document).on("click mouseover mouseout", ".js__seq-ctrl", (e) => {
            if (globalSurfaceConfig.tutorialLaunched) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                return false;
            }
        });

        $(document).on("sequence-tutorial-start", () => {
            $(".js__seq-overlayblock").fadeOut(500);

            // Prevent modal from automatically popping out on Shelves other than the 1st one in the Sequence
            if (+$(".js__seq-index").val() > 1) {

                /*
                * 05.11.2021
                * SHEL-293
                * 
                * Specifically for Decision Tree task, on Shelves with indexes 2 & 3,
                * although Tutorial will not be automatically launched, still automatically emit the event
                * that will launch the Info (Task Instructions) modal.
                */
                if (+$(".js__seq-tasktype").val() === 4 && (+$(".js__seq-index").val() === 2 || +$(".js__seq-index").val() === 3))
                    document.dispatchEvent(new CustomEvent("intro-modal-launch"));

                return;
            }

            // Also prevent on the 1st Shelf, if navigated to it backwards (indicated by si=1 in the URL)
            if (window.location.href.indexOf("?si=") > 0)
                return;

            $("#js__seq-tutorial-modal").modal("show");
        });

        $(document).on("click", ".js__seq-tutorial-launch", _launch);
        $(document).on("click", ".js__seq-tutorial-close", _close);
        $(document).on("click", ".js__seq-tutorial-prev", _prev);
        $(document).on("click", ".js__seq-tutorial-next", _next);

        $("#js__seq-tutorial-modal").on("shown.bs.modal", () => {
            document.dispatchEvent(new CustomEvent("cart-instr-begin"));
        });

        $("#js__seq-tutorial-modal").on("hidden.bs.modal", () => {
            $(".js__seq-overlayblock").hide();

            if (!globalSurfaceConfig.tutorialLaunched)
                document.dispatchEvent(new CustomEvent("intro-modal-launch"));

            /*
            * Emit event in relation to SHEL-346;
            * Refer to ShopCart Manager for more information.
            */
            document.dispatchEvent(new CustomEvent("cart-instr-end"));
        });

        _totalSteps = $(".js__seq-tutorial-step").length;
    };

    var _launch = function () {

        globalSurfaceConfig.tutorialLaunched = true;
        _toggleModalsDisabledState();

        _toStart();
        $(".js__seq-tutorial").fadeIn(120);
        $(".js__seq-overlayblock").show();
    };

    var _close = function () {

        globalSurfaceConfig.tutorialLaunched = false;
        _toggleModalsDisabledState();

        $("#js__seq-tutorial-modal").modal("hide");
        /*
        * Emit event in relation to SHEL-346;
        * Refer to ShopCart Manager for more information.
        * 
        * SHEL-351
        * Emit on Tutorial close because modal close is not covering the case of
        * Tutorial actually being watched;
        */
        document.dispatchEvent(new CustomEvent("cart-instr-end"));

        $(".js__seq-tutorial").fadeOut(120, () => {
            $(".js__seq-overlayblock").hide();
            _toStart();
            document.dispatchEvent(new CustomEvent("intro-modal-launch"));
        });
    };

    var _toggleModalsDisabledState = function () {
        $("[data-toggle='modal']").prop("disabled", globalSurfaceConfig.tutorialLaunched);
    };

    var _toStart = function () {
        _currentStep = 1;
        _direction = "TO_RIGHT";
        _toStep();

        setTimeout(function () {
            /*
            * Emit event in relation to SHEL-346;
            * Refer to ShopCart Manager for more information.
            * 
            * Emit (with delay) after Tutorial modal is closed (at least 500ms delay) & User 
            * was redirected to the 1st screen of the Turorial;
            * From that point on, record 'it' until Tutorial is closed
            * ('Close' button or 'Next' button from within the last Tutorial step,
            * both of which will trigger the _close function).
            */
            document.dispatchEvent(new CustomEvent("cart-instr-begin"));
        }, 1000);
    };

    var _toStep = function (step = 0) {

        if (step === 0)
            step = _currentStep;

        if ($(".js__seq-tutorial-step[data-step='" + step + "']").length === 0) {

            if (_direction == "TO_RIGHT") {
                _currentStep++;
                return;
            }

            if (_direction = "TO_LEFT") {
                _currentStep--;
                return;
            }

            return;
        }

        // next step should be ignored if 'url-redirect' is set in the URL
        if (step === 7 && window.location.href.indexOf("url-redirect") > 0) {

            if (_direction == "TO_RIGHT") {
                _toStep(8);
                return;
            }

            if (_direction = "TO_LEFT") {
                _toStep(6);
                return;
            }

            return;
        }

        $(".js__surface-controls-container").removeClass("forward");
        $(".js__surface-cart-container").removeClass("forward");
        if (step >= 3) {
            $(".js__surface-controls-container").addClass("forward");
            $(".js__surface-cart-container").addClass("forward");
        }

        $(".js__seq-tutorial-step").removeClass("active");
        $(".js__seq-tutorial-step[data-step='" + step + "']").addClass("active");

        _handleAbsBoxes();
    };

    var _next = function () {
        _currentStep++;

        /*
        * If navigating over the last possible step, it means that the automatic next step
        * occurred, due to _direction being "TO_RIGHT";
        * In such cases, automatically close the Tutorial section.
        */
        if (_currentStep > _totalSteps) {
            _close();
            return;
        }

        _direction = "TO_RIGHT";
        _toStep();
    };

    var _prev = function () {
        _currentStep--;

        /*
        * If e.g. fast subsequent clicks could produce invalid step number, exit early;
        * Generally, this block should be unreachable.
        */
        if (_currentStep < 1)
            return;

        _direction = "TO_LEFT";
        _toStep();
    };

    var _handleAbsBoxes = function () {
        var $activeStep = $(".js__seq-tutorial-step.active");
        var $absBoxes = $activeStep.find(".js__seq-tutorial-absbox");

        // exit early if current step doesn't contain abs box(es)
        if ($absBoxes.length === 0)
            return;

        $absBoxes.each((i, absBox) => {
            var $absBox = $(absBox);
            var $target = $($(absBox).data("target"));

            /*
            * Invalid target for the abs box position;
            * Navigate 1 additional step in the current direction (rightwards or leftwards).
            */
            if ($target.length === 0) {
                $absBox.hide();
                if (_direction == "TO_RIGHT") {
                    _next();
                    return;
                }
                if (_direction = "TO_LEFT") {
                    _prev();
                    return;
                }
                return;
            }

            switch ($absBox.data("position")) {
                case "BL":
                    $absBox.css({
                        "top": $target.offset().top + $target.outerHeight() + 30,
                        "left": $target.offset().left + $target.outerWidth() / 2 - $absBox.width()
                    });
                    break;
                case "BR":
                    $absBox.css({
                        "top": $target.offset().top + $target.outerHeight() + 30,
                        "left": $target.offset().left + $target.outerWidth() / 2
                    });
                    break;
                case "TL":
                    $absBox.css({
                        "top": $target.offset().top - 30 - $absBox.height(),
                        "left": $target.offset().left + $target.outerWidth() / 2 - $absBox.width()
                    });
                    break;
                case "TR":
                    $absBox.css({
                        "top": $target.offset().top - 30 - $absBox.height(),
                        "left": $target.offset().left // removed because out of bounds in specific case: + $target.outerWidth() / 2
                    });
                    break;
                default:
                    $absBox.hide();
                    if (_direction == "TO_RIGHT") {
                        _next();
                        return;
                    }
                    if (_direction = "TO_LEFT") {
                        _prev();
                        return;
                    }
                    return;
            }
        });
    };
}

var t = new Tutorial();
t.init();
module.exports = t;