var CookieManager = require("./cookie_manager");

function DatatableState() {

    var tableStates_ = {};
    var cookieName_ = "vsv2__table_states";

    this.init = function () {
        let tableStatesMemorized = CookieManager.get(cookieName_);

        if (!tableStatesMemorized)
            return;

        tableStatesMemorized = JSON.parse(tableStatesMemorized);
        tableStates_ = tableStatesMemorized;
    };

    this.pushState = function (tableName, state) {
        tableStates_[tableName] = state;
        CookieManager.set(cookieName_, JSON.stringify(tableStates_));
    };

    this.popState = function (tableName) {

        if (tableStates_[tableName] == undefined)
            return null;

        return tableStates_[tableName];
    };
};

var dts = new DatatableState();
dts.init();
module.exports = dts;