function Completion() {

    this.init = function () {

        if ($(".js__seq-complete").length === 0)
            return;

        globalCallbackHandler.handle("show-loader");

        _rewriteForRedirectUrl();

        var UrlAppendixManager = require("./url_appendix_manager");
        var $redirBtn = $(".js__seq-redirect-url-btn");
        UrlAppendixManager.appendixForRedirectUrl.bind($redirBtn).call();

        _hijackForRedirectUrl();

        document.dispatchEvent(new CustomEvent("cart-checkout"));
        document.dispatchEvent(new CustomEvent("cart-save"));
        document.dispatchEvent(new CustomEvent("cart-reset"));

        /*
        * After performing the checkout and emiting a Cart save event,
        * trigger a Session reset (clear User Session states from the BE Session);
        * This action will also clear the Cart from Local Storage.
        */
        _resetSession();
    };

    var _rewriteForRedirectUrl = function () {
        var $redirBtn = $(".js__seq-redirect-url-btn");
        var redirUrl = $redirBtn.attr("href");

        /*
        * If default completion redirect is set, prevent it's override;
        * If not set, take a look if entry url contained a 'redir' param
        * and use the URL bound to it as a completion redirect URL.
        */
        if (redirUrl !== "")
            return;

        var redirects = document.querySelector(".js__seq-redirects");

        if (!redirects) {
            $redirBtn.attr("href", window.location.origin);
            return;
        }

        var redirs = JSON.parse(redirects.value);

        var UrlParamsManager = require("./url_params_manager");
        var redirName = UrlParamsManager.getRedir();

        if (redirName === null) {
            $redirBtn.attr("href", window.location.origin);
            return;
        }

        if (!redirs.hasOwnProperty(redirName) ||
            redirs[redirName] == undefined) {
            $redirBtn.attr("href", window.location.origin);
            return;
        }

        $redirBtn.attr("href", redirs[redirName]);
    };

    var _hijackForRedirectUrl = function () {
        /*
        * If in EyeSee App (determined by pt=true parameter in the URL),
        * instead of allowing the click on the redirect button to go @ it's href,
        * serve the video instead (set video time to 1, id to "finish").
        */
        $(document).on("click", ".js__seq-redirect-url-btn", function (e) {

            if (!_inEyeSeeApp())
                return;

            e.preventDefault();
            _inEyeSeeAppSpecialEnding();

        });
    };

    var _resetSession = function () {
        $.ajax({
            url: "/surfaces/sequences/reset/seq",
            method: "POST",
            async: false,
            success: function () {
                /*
                * 09.04.2021
                *
                * Shopping Cart & related collections were previously stored in Cookies,
                * and their reset in the Termination process was performed in the BE;
                * Since these were transferred into LocalStorage, reset them here using JS.
                */
                document.dispatchEvent(new CustomEvent("cart-reset"));
                document.dispatchEvent(new CustomEvent("shlv-limits-reset"));
                document.dispatchEvent(new CustomEvent("shlv-product-considerations-reset"));

                /*
                * SHEL-252
                * 09.09.2021
                * 
                * It was occurring that users were able to click "Finish" button
                * before the Session & LocalStorage were cleared (lines above);
                * Now "Finish" is hidden behind the Loading splash;
                * Retardate loading hide with additional 1000ms of delay, just in case.
                */
                setTimeout(() => {
                    globalCallbackHandler.handle("hide-loader");
                }, 1000);
            }
        });
    };

    var _inEyeSeeApp = function () {
        return window.location.href.indexOf("pt=true") >= 0;
    };

    var _inEyeSeeAppSpecialEnding = function () {
        window.innerVideoTime = 1;
        window.videoId = "finish";
        window.parent.postMessage("eyesee_end_task", "*");
    };

}

var sc = new Completion();
sc.init();
module.exports = sc;