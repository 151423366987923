require("../styles/main.scss");

import "bootstrap";
import "parsleyjs";

import "./callback_handler";

import "./navbar";

import "./util/validation";
import "./util/table";
import "./util/richtext";
import "./util/video_player";

import "./components/datasource_form";
import "./components/logout_form";
import "./components/btn_delete";
import "./components/btn_clone";
import "./components/btn_sequence_counters";
import "./components/btn_mass_download";
import "./components/btn_reset_shelf_form";
import "./components/selectpicker";
import "./components/multiselector";
import "./components/btn_delete_ds_img";
import "./components/multiedit";

import "./sections/shelf_discounts";
import "./sections/product_tpl_importer";
import "./sections/product_sidebar_filtering";
import "./sections/multilines";
import "./sections/shelfset_task_types";
import "./sections/shelf_set_envelope_selector";

import "./surface/surface";