var UAParser = require("ua-parser-js");

function DeviceDetection() {

    this.device = function () {
        var device = new UAParser().getDevice();

        if (device.type === undefined)
            return "Desktop";

        return `${device.type} [${device.vendor} ${device.type}]`;
    };

    this.os = function () {
        var os = new UAParser().getOS();
        return `${os.name} ${os.version}`;
    };

    this.browser = function () {
        var browser = new UAParser().getBrowser();
        return `${browser.name} ${browser.version}`;
    };
}

module.exports = new DeviceDetection();