function BtnResetShelfForm() {

    this.init = function () {

        if ($(".js__btn-reset-shelf-form").length === 0)
            return;

        $(document).on("click", ".js__btn-reset-shelf-form", function () {

            if (!confirm("Are you sure? This will revert all inputs to their default state (as when creating a new Shelf)?"))
                return;

            var _$shelfForm = $(".js__edit-entity-form");
            _$shelfForm.find("input[name='title']").val("");
            _$shelfForm.find("textarea[name='description']").val("");
            _$shelfForm.find("select[name='dataSourceId']").selectpicker("val", "");
            _$shelfForm.find("select[name='shelfTemplateId']").selectpicker("val", "");
            _$shelfForm.find("select[name='currencyId']").selectpicker("val", "");
            _$shelfForm.find("input[name='decimals'][value='2']").prop("checked", true).attr("checked", true);
            _$shelfForm.find("input[name='allowAddMultiple']").prop("checked", false).attr("checked", false);
            _$shelfForm.find("input[name='hideTitles']").prop("checked", false).attr("checked", false);
            _$shelfForm.find("input[name='hideDescriptions']").prop("checked", false).attr("checked", false);
            _$shelfForm.find("input[name='allowMagnify']").prop("checked", false).attr("checked", false);
            _$shelfForm.find("input[name='zoomEnabled']").prop("checked", true).attr("checked", true);
            _$shelfForm.find("select[name='zoomMaxLevel']").val(6);
            _$shelfForm.find("select[name='zoomStepsCount']").val(4);
            _$shelfForm.find("select[name='zoomStartLevel']").val(1);
            _$shelfForm.find("select[name='zoomStartSector']").val(5);
        });

    };

}

var brsf = new BtnResetShelfForm();
brsf.init();