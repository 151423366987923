require("bootstrap-select");

function Selectpicker() {

    this.init = function () {

        $(document).on("selectpicker-init", _bootstrap);

        _bootstrap();
    };

    var _bootstrap = function () {

        var options = {
            dropupAuto: false
        };

        if (!$("body").hasClass("body-sequence"))
            options = $.extend(options, {
                liveSearch: true,
                liveSearchPlaceholder: "Start typing to search...",
                liveSearchNormalize: true
            });

        $(".js__selectpicker").selectpicker(options);
    };

    this.reset = function ($selectPicker) {

        $selectPicker = (arguments.length) === 0
            /*
            * If function is called without the parameter, 
            * reset all Selectpickers
            */
            ? $(".js__selectpicker")
            : $selectPicker;

        if ($selectPicker.prop("multiple")) {
            $selectPicker.selectpicker("deselectAll");
        } else {
            $selectPicker.selectpicker("val", "");
        }

    };
}

var sp = new Selectpicker();
sp.init();
module.exports = new Selectpicker();