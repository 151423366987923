function Validation() {

    this.init = function () {
        $(".js__validated-form").parsley({
            errorClass: "error",
            errorTemplate: "<li class='alert alert-sm alert-danger'></li>"
        });
    };

}

var v = new Validation();
v.init();
module.exports = v;