require("../vendor/multiselect");

function Multiselect() {

    this.init = function () {
        $(".js__custom-multiselect").multiselect({
            search: {
                left: '<input type="text" class="form-control" placeholder="Type 3 or more characters..." />',
                right: '<input type="text" class="form-control" placeholder="Type 3 or more characters..." />',
            },
            fireSearch: function (value) {
                return value.length > 2;
            },
            sort: {
                right: function (a, b) {
                    return +a.getAttribute("sorting") < +b.getAttribute("sorting") ? -1 : 1;
                }
            },

            beforeMoveToRight: function ($left, $right, $options) {
                var sorting = $right.find("option").length;
                $options.each(function (i, v) {
                    var $opt = $(v);
                    $opt.prop("sorting", sorting + i + 1).attr("sorting", sorting + i + 1);
                });
                return true;
            },

            afterMoveToLeft: _afterMove,
            afterMoveToRight: _afterMove,
            afterMoveUp: _afterMove,
            afterMoveDown: _afterMove
        });
    };

    var _afterMove = function ($left, $right, $options) {

        $("#set_shelves_to").find("option").each(function (i, v) {
            var $opt = $(v);
            var title = $opt.html().trim();
            var regex = /\[#(\d+)\]/i;
            title = title.replace(regex, "");
            $opt.html(`[#${i + 1}] ${title}`);
            $opt.prop("sorting", i + 1).attr("sorting", i + 1);;
        });

        $("#set_shelves").find("option").each(function (i, v) {
            var $opt = $(v);
            var title = $opt.html().trim();
            var regex = /\[#(\d+)\]/i;
            title = title.replace(regex, "");
            $opt.html(`${title}`);
            $opt.removeProp("sorting").removeAttr("sorting");
        });

    };

    this.init();
}

module.exports = new Multiselect();