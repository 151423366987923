function SurfaceContainerMouseEvents() {

    this.init = function () {

        if (_surfaceContainer === null)
            return;

        if (_surface === null)
            return;

        _surfaceContainer.addEventListener("dragenter", _handleDragEnter, false);
        _surfaceContainer.addEventListener("dragover", _handleDragOver, false);
        _surfaceContainer.addEventListener("dragleave", _handleDragLeave, false);
        _surfaceContainer.addEventListener("drop", _handleDrop, false);
    };

    var _handleDrop = function (e) {
        e.preventDefault();
        var product = document.querySelector(".js__products-list .product .js__product-main-image.product-dragging");

        if (product === null) {
            _surfaceContainer.className = "";
            return false;
        }

        /*
        * Shelf Template defines the default scaling for Products
        * dropped onto the Surfaces built using the Template;   
        * It means that Products may, by default, be placed scaled x1 relative to
        * their original size;
        * Anyways, shall the need be, these might also be placed scaled x0.5 or x1.5,
        * shall the User define so.
        * 
        * DEPRECATED 01.09.2020
        * Instead, it is assumed that Product Images and Background Image are scaled proportionally;
        * Thus, as products scale coefficient, will from now on use bgScaleFactor.
        */
        //var shelfProductsScale = document.querySelector(".js__surface-products-scale").value;
        var shelfProductsScale = globalSurfaceConfig.bgScaleFactor;

        /*
        * Pay attention to properties 'left' & 'top';
        * when zoom level = 1 and no panning is performed,
        * it might take e.layerX & e.layerY for it's values;
        * BUT, since zooming&panning distorts transformation matrix of the Surface,
        * it gets more complex that that.
        * What it needs are ABSOLUTE coordinates of the mouse pointer in the moment
        * when Product is dropped onto the Surface,
        * and the getPointer() call is retrieving exactly what it needs.
        */
        var newProduct = new fabric.Image(product, {
            width: product.naturalWidth,
            height: product.naturalHeight,
            left: globalSurfaceConfig.surface.getPointer(e).x,
            top: globalSurfaceConfig.surface.getPointer(e).y,
            lockScalingFlip: true,
            lockUniScaling: true,
            scaleX: shelfProductsScale,
            scaleY: shelfProductsScale,
            /*
            * This property is important as it allows to handle clicks
            * accuratelly in cases when target object is not rectangular;
            * Only by clicking the actual Object, events will get emitted;
            * If instead clicked on Object's rectangular placeholder,
            * no events will be fired.
            */
            perPixelTargetFind: true,
            /*
            * Below properties are 'edit mode' properties;
            * These should be disallowed (set to FALSE) if in Static view,
            * and allowed (set to TRUE) if Shelf is in the Builder mode.
            */
            resize: true,
            selectable: true,
            /*
            * If Surface works over HTTPS, loading images my be and CORS-related issue;
            * With "anonymous", that problem may be worked around.
            */
            crossOrigin: "anonymous",
            /*
            * Custom object properties;
            * This object gets serialized and stored in DB;
            * Custom props are used to relate Surface objects to DB Products.
            */
            meta: {
                type: "PRODUCT",
                id: product.attributes["data-product-id"].value,
                productTitle: product.attributes["data-product-title"].value,
                isDisplayOnly: product.attributes["data-product-display-only"].value,
                group: product.attributes["data-product-group"].value
            }
        }, function (img) {
            img.resizeFilters.push(new fabric.Image.filters.Resize({
                resizeType: "sliceHack",
                scaleX: 1,
                scaleY: 1
            }));
            _surface.trigger("object:modified", {
                target: newProduct
            });
        });
        /*
         * This call will init & bind Object events on the object level.
         * (other object events are bound on the Canvas->Object level)
         */
        import(/* webpackMode: 'lazy' */ "./builder_object_events")
            .then(ObjectEvents => {
                ObjectEvents.initObjectEvents(newProduct);
            });

        globalCallbackHandler.handle("surface-states-append");

        _surface.add(newProduct);
        _surfaceContainer.classList.remove("over");
        _surface.renderAll();

        /*
         * This is 'intermediate' serialization of Surface.
         * Surface is serialized on form submit, when all the object modifications are also
         * taken into account and pushed to DB.
         */
        globalCallbackHandler.handle("update-surface-json");

        return false;
    };

    var _handleDragOver = function (e) {
        if (e.preventDefault)
            e.preventDefault();

        e.dataTransfer.dropEffect = "copy";
        return false;
    };

    var _handleDragEnter = function (e) {
        _surfaceContainer.classList.add("over");
    };

    var _handleDragLeave = function (e) {
        _surfaceContainer.classList.remove("over");
    };

    var _surface = globalSurfaceConfig.surface;
    var _surfaceContainer = globalSurfaceConfig.surfaceContainer;
}

var scme = new SurfaceContainerMouseEvents();
scme.init();
module.exports = scme;