function UrlParamsManager() {

    var _queryParams = "";
    var _params = {};

    this.init = function () {

        /*
        * Through this script, load Prod Appedix Manager as well;
        * These two are used in combo, where appenxix manager handles the
        * considerations & products decoration for the URLs.
        */
        require("./url_appendix_manager")

        _loadUriParams();
        _handle();
    };

    var _handle = function () {
        _handleSequenceNavHrefs();
        _handleBudget();
        _handleMaxQuantity();
        _handleUserId();
        _handleNextStep();
        _handleCompletionRedir();
    };

    this.getUriParams = function () {
        return window.location.search.substring(1);
    };

    this.getRedir = function () {
        var url = new URL(window.location);
        return url.searchParams.get("redir");
    };

    var _loadUriParams = function () {

        if (window.location.search.length > 0)
            _queryParams = window.location.search.substring(1);

        // first, reload _params
        _params = {};

        if (_queryParams === "")
            return;

        _queryParams.split("&").map(function (param) {
            let temp = param.split("=");
            _params[temp[0]] = temp[1];
        });
    };

    var _handleMaxQuantity = function () {
        if (_params.hasOwnProperty("maxi") &&
            _params["maxi"] != undefined) {

            $(".js__seq-limit-items").val(_params["maxi"]);

            var event = new CustomEvent("cart-set-quantity");
            document.dispatchEvent(event);
        }
    };

    var _handleBudget = function () {
        if (_params.hasOwnProperty("budget") &&
            _params["budget"] != undefined) {

            $(".js__seq-limit-funds").val(_params["budget"]);

            $(".js__seq-limit-funds-total-lbl").text(_params["budget"]);
            $(".js__seq-limit-funds-total-lbl").parent().show();

            var event = new CustomEvent("cart-set-budget");
            document.dispatchEvent(event);
        }
    };

    var _handleUserId = function () {
        if (!_params.hasOwnProperty("userid") ||
            _params["userid"] == undefined)
            return;

        var event = new CustomEvent("cart-set-user", {
            detail: {
                userid: _params["userid"]
            }
        });
        document.dispatchEvent(event);
    };

    var _handleNextStep = function () {
        if (!_params.hasOwnProperty("url-redirect") ||
            _params["url-redirect"] == undefined)
            return;

        var nextStepUrl = _params["url-redirect"];

        if (!_validURL(nextStepUrl))
            return;

        var $next = $(".js__seq-next-btn");
        $next.attr("href", nextStepUrl);

        document.dispatchEvent(new CustomEvent("sequence-appendix-next"));

        _handleSequenceNavHrefs($next);
    };

    var _handleCompletionRedir = function () {
        if (_params.hasOwnProperty("redir") &&
            _params["redir"] != undefined)
            ; // stub
    };

    var _handleSequenceNavHrefs = function () {

        var $navLinks = $(".js__seq-navlink");
        if (arguments[0] != undefined)
            $navLinks = arguments[0];

        $navLinks.each(function (index, elem) {
            var $link = $(elem);
            var href = $link.attr("href");

            if (href == undefined)
                return;

            var concat = "?";
            if (href.indexOf("?") !== -1)
                concat = "&";

            /*
            * When navigated from one Shelf within the Sequence to another,
            * these URL params are also propagated;
            * But, these actually should not be embedded into the next page's buttons' hrefs.
            * Reset the "url-redirect", "si", "nobuy" URL params after landing on the next/prev/other Shelf.
            */
            _queryParams = _queryParams.replace(/[\?&]?url-redirect=[^&]+/, "");
            _queryParams = _queryParams.replace(/[\?&]?si=[^&]+/, "");
            _queryParams = _queryParams.replace(/[\?&]?nobuy=[^&]+/, "");

            if (_queryParams.replace(/^&/, "").length > 0 && _queryParams.replace(/^&/, "") != undefined)
                href += concat + _queryParams.replace(/^&/, "");

            $link.attr("href", href);
        });

        /*
        * SHEL-154
        *
        * Cart URL appendix/decoration is implemented on Sequence navigation ctrls;
        * Note: Considerations could stack rapidly and eventually break address bar length limitation.
        */
        document.dispatchEvent(new CustomEvent("sequence-appendix-allnavs"));
    };

    var _validURL = function (str) {
        let url;
        try {
            url = new URL(str);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    };

}

var upm = new UrlParamsManager();
module.exports = upm;