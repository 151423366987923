fabric.util.object.extend(fabric.Object.prototype, {

    getAbsoluteCenterPoint: function () {
        var point = this.getCenterPoint();
        if (!this.group)
            return point;
        var groupPoint = this.group.getAbsoluteCenterPoint();
        return {
            x: point.x + groupPoint.x,
            y: point.y + groupPoint.y
        };
    },

    lockObject: function () {
        this.lockMovementX = true;
        this.lockMovementY = true;
        this.lockScalingX = true;
        this.lockScalingY = true;
        this.lockRotation = true;
        this.hasControls = false;
    },

    unlockObject: function () {
        this.lockMovementX = false;
        this.lockMovementY = false;
        this.lockScalingX = false;
        this.lockScalingY = false;
        this.lockRotation = false;
        this.hasControls = true;
    },

    blockObject: function () {
        var brightness = new fabric.Image.filters.Brightness({
            brightness: 1
        });
        this.filters.push(brightness);
        this.applyFilters();
        this.set({
            opacity: 1,
            hoverCursor: "default",
            meta: Object.assign(this.meta, {
                isBlocked: true
            })
        });
    },

    unblockObject: function () {
        delete this.meta.isBlocked;

        var _obj = this;
        this.filters.forEach(function (filter, index) {
            if (filter.type == "Brightness")
                delete _obj.filters[index];
        });

        this.applyFilters();

        /*
        * Unblock action will automatically revert opacity back to 0.01,
        * as it was initially set by the Autozone function;
        * This will cause problems with manually built Shelves - on those,
        * opacity should be reverted back to 1 instead;
        * 
        * To be able to properly determine opacity to be rolled back to, ultimately there should be
        * a new property inside "meta" section called e.g. "origOp",
        * and unblock function should revert object back to the value stored inside it.
        */
        this.set({
            opacity: 0.01
        });
    },

    toggleBlockObject: function (block) {
        if (block === true)
            this.blockObject();
        else
            this.unblockObject();
    }
});

fabric.Object.prototype.toObject = (function (toObject) {
    return function (propertiesToInclude) {
        propertiesToInclude = (propertiesToInclude || []).concat(
            [
                "meta",
                "cpOffset",
                "subTargetCheck",
                // properties below used to persist obj lock state
                "lockMovementX",
                "lockMovementY",
                "lockScalingX",
                "lockScalingY",
                "lockRotation",
                "hasControls"
            ]
        );
        return toObject.apply(this, [propertiesToInclude]);
    };
})(fabric.Object.prototype.toObject);

fabric.Object.prototype.getZIndex = function () {
    return this.canvas.getObjects().indexOf(this);
};

fabric.Object.prototype.isProduct = function () {
    return this.meta && this.meta.type === "PRODUCT";
};

fabric.Object.prototype.isVideoPanel = function () {
    return this.meta && this.meta.type === "VIDEOPANEL";
};

fabric.Object.prototype.isExtras = function () {
    return this.meta && this.meta.type.indexOf("EXTRAS") === 0;
};

fabric.Object.prototype.isLocked = function () {
    return this.lockMovementX
        || this.lockMovementY
        || this.lockScalingX
        || this.lockScalingY;
};

fabric.Object.prototype.isBlocked = function () {
    return this.meta && this.meta.isBlocked;
};

fabric.Object.prototype.getProductDetails = function () {

    if (!(this.meta && this.meta.type === "PRODUCT"))
        return null;

    return {
        id: this.meta.id,
        title: this.meta.productTitle,
        appearance: !!+this.meta.appearance,
        group: this.meta.group
    };
};

fabric.Object.prototype.getPositions = function () {

    if (!this.isProduct())
        return null;

    var objWidth = (this.width * this.scaleX) / 2;
    var objHeight = (this.height * this.scaleY) / 2;

    return {
        "x": parseInt(this.getAbsoluteCenterPoint().x - objWidth / 2),
        "y": parseInt(this.getAbsoluteCenterPoint().y - objHeight / 2),
        "width": objWidth,
        "height": objHeight
    };
};

fabric.Object.prototype.placeShadow = function (direction) {
    var properties = {
        color: "#333",
        blur: 20,
        offsetY: 5,
        opacity: 0.6,
        fillShadow: true,
        strokeShadow: true
    };

    switch (direction) {
        case "L":
            properties.offsetX = -5;
            break;
        case "R":
            properties.offsetX = 5;
            break;
        default: return;
    }

    this.setShadow(properties);
};

/*
 * Styling for the selections;
 * Default is overriden for the sake of better observability and easier use.
 */
fabric.Object.prototype.borderColor = "#ff0aa5";
fabric.Object.prototype.cornerColor = "#6709ff";
fabric.Object.prototype.cornerSize = 6;
fabric.Object.prototype.transparentCorners = false;
fabric.Object.prototype.lockUniScaling = true;

fabric.Canvas.prototype.removeActiveObjects = function () {
    [].forEach.call(this.getActiveObjects(), function (object) {
        object.canvas.remove(object);
    });
};

fabric.Canvas.prototype.getVideoPanels = function () {
    var collection = [];
    [].forEach.call(this.getObjects(), function (object) {
        if (object.isVideoPanel())
            collection.push(object);
    });
    return collection;
};

fabric.Canvas.prototype.getExtras = function () {
    var collection = [];
    [].forEach.call(this.getObjects(), function (object) {
        if (object.isExtras())
            collection.push(object);
    });
    return collection;
};

fabric.Canvas.prototype.getBlockUnblockTargetsByProductIds = function (productIds) {

    var collection = {};
    var that = this;

    [].forEach.call(this.getObjects(), function (object) {
        if (object.type == "group") {
            [].forEach.call(object._objects, function (obj) {

                if (!obj.isProduct())
                    return;

                if (productIds.indexOf(obj.getProductDetails().id) >= 0) {

                    if (obj.getProductDetails().group && obj.getProductDetails().group !== "") {
                        collection = { ...collection, ...that.findObjectsByGroup(obj.getProductDetails().group) };
                        return;
                    }

                    if (!collection.hasOwnProperty(obj.getProductDetails().id))
                        collection[obj.getProductDetails().id] = [];

                    collection[obj.getProductDetails().id].push(obj);
                }
            });
            return;
        }

        if (!object.isProduct())
            return;

        if (object.isProduct() && productIds.indexOf(object.getProductDetails().id) >= 0) {

            if (object.getProductDetails().group && object.getProductDetails().group !== "") {
                collection = { ...collection, ...that.findObjectsByGroup(object.getProductDetails().group) };
                return;
            }

            if (!collection.hasOwnProperty(object.getProductDetails().id))
                collection[object.getProductDetails().id] = [];

            collection[object.getProductDetails().id].push(object);
        }
    });

    return collection;

};

fabric.Canvas.prototype.findObjectsByGroup = function (group) {

    var collection = {};

    [].forEach.call(this.getObjects(), function (object) {
        if (object.type == "group") {
            [].forEach.call(object._objects, function (obj) {
                if (obj.isProduct() && obj.getProductDetails().group == group) {
                    if (!collection.hasOwnProperty(obj.getProductDetails().id))
                        collection[obj.getProductDetails().id] = [];

                    collection[obj.getProductDetails().id].push(obj);
                }
            });
            return;
        }

        if (object.isProduct() && object.getProductDetails().group == group) {
            if (!collection.hasOwnProperty(object.getProductDetails().id))
                collection[object.getProductDetails().id] = [];

            collection[object.getProductDetails().id].push(object);
        }
    });

    return collection;
};

fabric.Canvas.prototype.findByCoordinates = function (x, y) {

    var match = [];

    if (x > this.width || y > this.height)
        return match;

    [].forEach.call(this.getObjects(), function (object) {
        if (object.left <= x &&
            object.top <= y &&
            object.left + object.width * object.scaleX >= x &&
            object.top + object.height * object.scaleY >= y)
            match.push(object);
    });

    return match;
};

fabric.Canvas.prototype.findNearestByCoordinates = function (x, y) {

    var shortestDist = 9999999;
    var nearestObject = null;

    [].forEach.call(this.getObjects(), function (object) {

        var objWidth = (object.width * object.scaleX) / 2;
        var objHeight = (object.height * object.scaleY) / 2;
        var objCX = object.getAbsoluteCenterPoint().x;
        var objCY = object.getAbsoluteCenterPoint().y;

        var points = {
            "tl": {
                x: objCX - objWidth / 2,
                y: objCY - objHeight / 2
            },
            "tr": {
                x: objCX + objWidth / 2,
                y: objCY - objHeight / 2
            },
            "bl": {
                x: objCX - objWidth / 2,
                y: objCY + objHeight / 2
            },
            "br": {
                x: objCX + objWidth / 2,
                y: objCY + objHeight / 2
            },
            "c": {
                x: objCX,
                y: objCY
            }
        };

        for (var pt in points) {

            var point = points[pt];

            var ptDist = Math.sqrt(
                Math.pow(point.x - x, 2) +
                Math.pow(point.y - y, 2)
            );

            if (ptDist <= shortestDist) {
                shortestDist = ptDist;
                nearestObject = object;
            }
        }

    });

    return nearestObject;

};