var ShelfDiscounts = require("./shelf_discounts");

function ShelfLimitsManager() {

    this.init = function () {
        /*
        * This event must be bound in any case;
        * Other stuff depends on the presence of Shelf ID in DOM,
        * but reset function is also called from the Completion/Termination page,
        * to which (DOM) this information is not embedded.
        */
        $(document).on("shlv-limits-reset", _reset);

        if (!document.querySelector(".js__surface-shelf-id"))
            return;

        _currentShelfId = document.querySelector(".js__surface-shelf-id").value;

        $(document).on("shlv-limits-update", _updateFullCollection);
        $(document).on("shlv-limits-rollback", _rollbackState);
        $(document).on("shlv-limits-indicate", _updateLabelsIndicationColor);

        _loadLimits();
    };

    this.bootstrap = function () {
        _import();
    };

    this.countProductsOnShelf = function () {
        return Object.keys(_productsBoughtOnShelf).length;
    };

    this.updateProductState = function (productNewState) {

        var productId = productNewState.id;

        var tstamp = Math.floor(Date.now() / 1000);

        productNewState = $.extend(
            productNewState,
            { tstamp: tstamp }
        );

        ShelfDiscounts.discountProduct(productNewState);
        /*
        * p & qty are only used at this place to calculate discounted price,
        * because limits need to be validated against discounted prices
        */
        delete productNewState.p;
        delete productNewState.qty;

        _updatesForCollection = [];

        var productOnShelf = null;

        if (!_productsBoughtOnShelf.hasOwnProperty(productId)) {

            /*
            * As to how quantity change upwards or downwards affects the collections,
            * refer to the eloquent comments below; 
            * those are applicable to this block as well.
            */
            if (productNewState.qtyChange >= 0) {
                _productsBoughtOnShelf[productId] = productNewState;
                _updatesForCollection.push($.extend(
                    _productsBoughtOnShelf[productId],
                    { shelfId: _currentShelfId }
                ));
            } else {
                _deductQuantityRecursiveLIFO(productId, -productNewState.qtyChange);
            }

            productOnShelf = _productsBoughtOnShelf[productId];

        } else {

            productOnShelf = _productsBoughtOnShelf[productId];
            /*
            * This block will be reached in 2 cases:
            * 1. Quantity diff is > 0, meaning that qty for the Product has increased
            * 2. Quantity diff = 0, which either suggests that Price diff also = 0,
            * but is also possible that price may increase or decrease even though qty is the same;
            * The thing is, quantity is actually qtyCalc, so for bulk-type Products unchanged qtyCalc
            * may still come along with a increase/decrease of Price.
            */
            if (productNewState.qtyChange >= 0) {
                /*
                * If validation is to fail after this point, we should provide a correct state
                * to be used as a rollback state;
                * Create the 'temp' property on the object and store it's rollback values there
                * (for the Quantity and Price)
                */
                productOnShelf.temp = {
                    qtyChange: productOnShelf.qtyChange,
                    calcval: productOnShelf.calcval
                }
                productOnShelf.qtyChange += productNewState.qtyChange;
                productOnShelf.calcval = productNewState.calcval;

                _updatesForCollection.push($.extend(
                    productOnShelf,
                    { shelfId: _currentShelfId }
                ));
            } else {
                /*
                * Obviously, this block is reached if Product quantity has decreased;
                * Also, reached if Product is removed from the Cart.
                * The result should be decrease in the quantity of Product in the following way:
                * 1. If Product was added on the same Shelf
                * 1.1. If decrease is less than the amount of Products on the Shelf ->
                *   reduce the quantity of Product for this Shelf
                * 1.2. If decrease is exactly the ammount of Products on the Shelf ->
                *   remove Product from the Shelf's collection of items, release space
                * 1.3. If decrease is more that the amount of Products on the Shelf ->
                *   first remove all the quantity belonging to this Shelf,
                *   then start removing from other Shelves as well, using LIFO principle 
                *   (determined by the tstamp of Product's first induction into Cart from that Shelf)
                *   until the desired level of decrease is reached.
                * 2. If Product was only added on other Shelves ->
                *   remove from other Shelves, using LIFO principle 
                *   (determined by the tstamp of Product's first induction into Cart from that Shelf)
                *   until the desired level of decrease is reached.
                */
                if (productOnShelf.qtyChange + productNewState.qtyChange > 0) {
                    // case 1.1
                    productOnShelf.qtyChange += productNewState.qtyChange;
                    productOnShelf.calcval = productNewState.calcval;
                    _updatesForCollection.push($.extend(
                        productOnShelf,
                        { shelfId: _currentShelfId }
                    ));
                } else if (productOnShelf.qtyChange + productNewState.qtyChange == 0) {
                    // case 1.2
                    delete _productDistributionPerShelves[productId][_currentShelfId];
                    delete _productsBoughtOnShelf[productId];
                    // empty array, since parent collection update is already performed by the delete ops above
                    _updatesForCollection = [];
                } else {
                    // case 1.3
                    delete _productDistributionPerShelves[productId][_currentShelfId];
                    delete _productsBoughtOnShelf[productId];
                    var quantityLeftToDeduct = - (productOnShelf.qtyChange + productNewState.qtyChange);
                    _deductQuantityRecursiveLIFO(productId, quantityLeftToDeduct);
                }

            }
        }

        var isStateValid = _validate();
        if (isStateValid) {
            if (productOnShelf && productOnShelf.hasOwnProperty("temp"))
                delete productOnShelf.temp;
        } else {
            /*
            * If the desired change would take the Shelf in an invalid state
            * (in regard to it's limitations),
            * skip updating the distributions in the Local Storage.
            * Instead, rollback the previous state (stored in 'temp'),
            * and reset 'temp' property value after rollback is done.
            */
            if (productOnShelf && productOnShelf.hasOwnProperty("temp")) {
                productOnShelf.qtyChange = productOnShelf.temp.qtyChange;
                productOnShelf.calcval = productOnShelf.temp.calcval;
                delete productOnShelf.temp;
                /*
                * If limits are set & fully reached (or overflown, which should not even be possible in the new version),
                * shelf item and/or funds limit should be indicated in red color;
                * If user bought below the configured limit, repaint the label in white (default color).
                */
                _updateLabelsIndicationColor();
                return;
            }

            delete _productsBoughtOnShelf[productId];
        }

        /*
        * If limits are set & fully reached (or overflown, which should not even be possible in the new version),
        * shelf item and/or funds limit should be indicated in red color;
        * If user bought below the configured limit, repaint the label in white (default color).
        */
        _updateLabelsIndicationColor();

        return isStateValid;
    };

    var _validate = function () {

        // validate updated quantity against the Shelf's items limit
        var isValidQuantity = _validateItemsLimit();
        if (!isValidQuantity) {
            globalCallbackHandler.handle("toast", {
                type: "ERROR",
                message: $(".js__seq-notif-shelf-limit-qty-not-ok").val()
            });
        }

        // validate updated price against the Shelf's funds limit
        var isValidBudget = _validateFundsLimit();
        if (!isValidBudget) {
            globalCallbackHandler.handle("toast", {
                type: "ERROR",
                message: $(".js__seq-notif-shelf-limit-budget-not-ok").val()
            });
        }

        return isValidQuantity && isValidBudget;
    };

    var _rollbackState = function () {
        _import();
    };

    var _updateFullCollection = function () {
        _updatesForCollection.forEach(function (updateObject) {

            if (!updateObject.hasOwnProperty("shelfId"))
                return;

            var productId = updateObject.id;
            var shelfId = updateObject.shelfId;

            // delete surplus properties
            delete updateObject.shelfId;
            if (updateObject.hasOwnProperty("temp"))
                delete updateObject.temp;

            if (!_productDistributionPerShelves.hasOwnProperty(productId))
                _productDistributionPerShelves[productId] = {};

            if (!_productDistributionPerShelves[productId].hasOwnProperty(_currentShelfId))
                _productDistributionPerShelves[productId][_currentShelfId] = {};

            _productDistributionPerShelves[productId][shelfId] = updateObject;

        });
        _updateLabels();
        _export();
        /*
        * After a new Product/Shelf relation was created,
        * re-export URL appendix decorations to 'Next' btn href.
        */
        document.dispatchEvent(new CustomEvent("sequence-appendix-next"));
    };

    var _updateLabels = function () {
        $(".js__seq-surface-limit-items-lbl").html(_shelfTotalItems());
        $(".js__seq-surface-limit-funds-lbl").html(_shelfTotalFunds());
    };

    var _updateLabelsIndicationColor = function () {
        $(".js__seq-surface-limit-items-wrapper").toggleClass("text-primary", _shelfLimitItems <= _shelfTotalItems());
        $(".js__seq-surface-limit-funds-wrapper").toggleClass("text-primary", _shelfLimitFunds <= _shelfTotalFunds());
    };

    var _shelfTotalItems = function () {
        var total = 0;
        for (var prod in _productsBoughtOnShelf) {
            var product = _productsBoughtOnShelf[prod];
            total += product.qtyChange;
        }
        return total;
    };

    var _shelfTotalFunds = function () {
        var total = 0;
        for (var prod in _productsBoughtOnShelf) {
            var product = _productsBoughtOnShelf[prod];
            total += product.calcval;
        }

        /*
        * Round the total money spent to max 2 decimals;
        * This is very important because of bad JS precision;
        * Otherwise, app could use inaccurate totals such as '4.259999999999999' instead of 4.26
        */
        return Math.round(total * 100) / 100;
    };

    var _validateItemsLimit = function () {

        if (_shelfLimitItems === 0)
            return true;

        return _shelfLimitItems >= _shelfTotalItems();
    };

    var _validateFundsLimit = function () {

        if (_shelfLimitFunds === 0)
            return true;

        return _shelfLimitFunds >= _shelfTotalFunds();
    };

    /*
    * This function is recursively used to deduct certain quantity from the Shelves
    * from which the given Product was added to Shopping Cart,
    * by following the principles:
    * 1. Remove as much as possible from the current Shelf, if any;
    * 2. Remove recursively from other Shelves (if any qty available for the given Product,
    *   using the LIFO principle - first remove from the Shelf from which the Product was last added in.)
    */
    var _deductQuantityRecursiveLIFO = function (productId, quantityToDeduct) {

        if (quantityToDeduct == 0)
            return;

        if (Object.values(_productDistributionPerShelves[productId]).length === 0)
            return;

        var lastShelfIn = Object
            .values(_productDistributionPerShelves[productId])
            .reduce((prev, curr) => prev.tstamp > curr.tstamp ? prev : curr);

        var lastShelfId = Object
            .keys(_productDistributionPerShelves[productId])
            .filter((key) => _productDistributionPerShelves[productId][key].tstamp == lastShelfIn.tstamp)[0];

        if (lastShelfIn.qtyChange > quantityToDeduct) {
            lastShelfIn.qtyChange -= quantityToDeduct;
            _updatesForCollection.push($.extend(
                lastShelfIn,
                { shelfId: lastShelfId }
            ));
            _updateFullCollection();
        } else if (lastShelfIn.qtyChange == quantityToDeduct) {

            delete _productDistributionPerShelves[productId][lastShelfId];

            _updatesForCollection = [];
            _updateFullCollection();
        } else {

            var quantityRemainingToDeduct = quantityToDeduct - lastShelfIn.qtyChange;

            delete _productDistributionPerShelves[productId][lastShelfId];

            _updatesForCollection = [];
            _updateFullCollection();

            _deductQuantityRecursiveLIFO(productId, quantityRemainingToDeduct);
        }
    };

    var _loadLimits = function () {
        /*
        * Limits are stored in the Surface/Shelf metadata hidden inputs;
        * Load this to preset the reference validation values.
        */
        var itemsLimitElem = document.querySelector(".js__surface-limit-items");
        if (itemsLimitElem)
            _shelfLimitItems = +itemsLimitElem.value;

        var fundsLimitElem = document.querySelector(".js__surface-limit-funds");
        if (fundsLimitElem)
            _shelfLimitFunds = +fundsLimitElem.value;
    };

    var _reset = function () {
        _productDistributionPerShelves = {};
        _export();
    };

    var _export = function () {
        localStorage.setItem("VSV2_SEQ_ProductsOnShelves", JSON.stringify(_productDistributionPerShelves));
    };

    var _import = function () {
        var pos = localStorage.getItem("VSV2_SEQ_ProductsOnShelves");

        /*
        * First, reset these two collections;
        * Import operation below will re-populate these accordingly.
        */
        _productDistributionPerShelves = {};
        _productsBoughtOnShelf = {};

        if (!pos) {
            _export();
            return;
        }

        /*
        * The entire (LocalStorage-based) collection of Shelf-Product distributions is 
        * organized first by Product IDs, and then by Shelf IDs;
        * For the given Shelf, it is convinient to turn these upside down and organize
        * collection in a way that current Shelf's Products are listed within the
        * ShelfId-indexed object.
        */

        _productDistributionPerShelves = JSON.parse(pos);

        for (var productId in _productDistributionPerShelves) {

            var productDistribution = _productDistributionPerShelves[productId];

            if (!productDistribution.hasOwnProperty(_currentShelfId))
                continue;

            _productsBoughtOnShelf[productId] = productDistribution[_currentShelfId];
        }
    };

    var _productDistributionPerShelves = {};
    var _productsBoughtOnShelf = {};
    var _currentShelfId = null;
    var _shelfLimitItems = 0;
    var _shelfLimitFunds = 0;

    var _updatesForCollection = [];
}

var slm = new ShelfLimitsManager();
slm.init();
module.exports = slm;