function LogoutForm() {

    this.init = function () {

        $(document).on("submit", ".js__logout-form", function () {

            if (!confirm("Are you sure you want to Sign Out?"))
                return false;

            return true;
        });
    };
}

var lf = new LogoutForm();
lf.init();