var $surface = $("#js__surface-builder");

if ($surface.length > 0) {
    import(/* webpackMode: 'lazy' */ "fabric-with-gestures")
        .then(Fabric => {
            require("./fabric_prototypes");
            var surfaceBuilder = new SurfaceBuilder();
            surfaceBuilder.init();
        });

    import(/* webpackMode: 'lazy' */ "./util/autosave")
        .then(Autosave => {
            Autosave.init();
        });
}

function SurfaceBuilder() {

    var _surfaceContainer = document.querySelector(".js__surface-container");
    var _surface = null;

    var _shelfTemplateBg = null;

    this.init = function () {

        if (_surfaceContainer === null)
            return;

        /*
        * On the Builder Surface, change default hover cursor for Objects;
        * This way indicate that Objects are "live" and modifications are allowed
        * to be performed on the Objects.
        */
        fabric.Object.prototype.hoverCursor = "grab";

        _shelfTemplateBg = document.getElementById("js__img-shelf-template");

        globalSurfaceConfig.width = _surfaceContainer.offsetWidth;
        globalSurfaceConfig.height = globalSurfaceConfig.width / globalSurfaceConfig.ratio;

        globalSurfaceConfig.scaleFactor = _shelfTemplateBg.naturalWidth / _shelfTemplateBg.naturalHeight;
        globalSurfaceConfig.effectiveWidth = globalSurfaceConfig.height * globalSurfaceConfig.scaleFactor;

        globalSurfaceConfig.oldScaleCoefficient = +document.querySelector(".js__surface-creation-scale").dataset.oldValue;

        // add -2 to account for top+bottom border
        $(".js__products-list-wrapper").height(globalSurfaceConfig.height - 2);

        _surface = new fabric.Canvas("js__surface-builder", {
            uniScaleTransform: false,
            preserveObjectStacking: true,
            targetFindTolerance: 2
        });
        _surface.setDimensions({
            width: globalSurfaceConfig.width,
            height: globalSurfaceConfig.height
        });

        globalSurfaceConfig.surface = _surface;
        globalSurfaceConfig.surfaceContainer = _surfaceContainer;

        _buildSurface();
        _presubmitSurfaceForm();
        _processScreenResize();
    };

    /*
    * Central Surface Builder function;
    * Calculates screen size constraints and eventually old work (JSON) to set-up the Shelf Builder.
    */
    var _buildSurface = function () {

        var shelfJson = $(".js__surface-json").val();

        function htmlDecode(value) {
            return $("<div/>").html(value).text();
        }

        shelfJson = htmlDecode(shelfJson);

        if (shelfJson == "") {
            _setBackgroundImage();
            //IrManager.bindSurface(_surface);
            //SpecialsManager.bindSurface(_surface);
            _bootstrapSurface();
            return;
        }

        _surface.loadFromJSON(JSON.parse(shelfJson), function () {
            _setBackgroundImage();
            globalSurfaceConfig.scaleCoefficient = globalSurfaceConfig.oldScaleCoefficient / globalSurfaceConfig.bgScaleFactor;

            //IrManager.bindSurface(_surface);
            //SpecialsManager.bindSurface(_surface);
            _bootstrapSurface();
        });
    };

    var _setBackgroundImage = function () {
        globalSurfaceConfig.bgScaleFactor = Math.max(
            globalSurfaceConfig.effectiveWidth / _shelfTemplateBg.naturalWidth,
            globalSurfaceConfig.height / _shelfTemplateBg.naturalHeight
        );
        $(".js__surface-creation-scale").val(globalSurfaceConfig.bgScaleFactor);

        fabric.Image.fromURL(
            _shelfTemplateBg.src,

            function (img) {

                img.set({
                    width: globalSurfaceConfig.effectiveWidth / globalSurfaceConfig.bgScaleFactor,
                    height: _surface.height / globalSurfaceConfig.bgScaleFactor,
                    left: _surface.getCenter().left,
                    top: _surface.getCenter().top,
                    originX: "center",
                    originY: "center",
                    offsetX: 0,
                    offsetY: 0,
                    scaleX: globalSurfaceConfig.bgScaleFactor,
                    scaleY: globalSurfaceConfig.bgScaleFactor,
                    crossOrigin: "anonymous"
                });

                _surface.setBackgroundImage(img);
                _surface.renderAll();

                /*
                * Center point is relevant for metafile Surface build;
                * Input relevant info into metafile form.
                */
                var centerPointData = _surface.getCenter().left;
                centerPointData += ";";
                centerPointData += _surface.getCenter().top;
                centerPointData += ";";
                centerPointData += _surface.width / _surface.backgroundImage.width;
                $(".js__surface-center-point").val(centerPointData);

                /*
                * When Surface is loaded from JSON (optional) and background image is set,
                * push current state into Surface States array (undo/redo call stack). 
                * This is an event-driven action, so fire global event to process it.
                */
                globalCallbackHandler.handle("surface-states-append");
            }
        );
    };

    var _bootstrapSurface = function () {

        var surfaceObjectsConstructor = require("./surface_objects_constructor");
        surfaceObjectsConstructor.setIsStaticDisplayOnly(false).construct();

        require("./events/surface_container_mouse_events");
        require("./events/product_mouse_events");
        require("./events/surface_container_keyboard_events");
        require("./controls/surface_zoom_controls");
        require("./controls/surface_controls");
        require("./controls/surface_object_controls");

        require("./components/vp_selection_modal");

        //AutosaveUtility.init.bind(this.surface).call();
    };

    /*
    * This private function handles some preprocessors bound to Surface Builder Form's submit action;
    * This will prepare JSONed Surface & Object positions/dimensions for DB preservation.
    */
    var _presubmitSurfaceForm = function () {
        $("#js__surface-builder-form").on("submit", function () {
            globalCallbackHandler.handle("update-surface-json");
            document.querySelector(".js__surface-obj-positions").value =
                JSON.stringify(_calculateObjectPositions());
        });
    };

    /*
    * Re-render Surface on Screen resize.
    */
    var _processScreenResize = function () {
        window.addEventListener("resize", function (e) {
            _surface.renderAll();
        });
    };

    /*
    * This function gets Surface Objects' positions;
    * On Surface submit, the value this function provides gets stored in a hidden input,
    * and sent to BE to persist in DB in JSON format.
    */
    var _calculateObjectPositions = function () {

        var positions = {};

        /*
        * This inner function performe the actual task of appending
        * Surface Object's position data into positions collection
        */
        function appendPosition(object) {
            var position = object.getPositions();

            if (position === null)
                return;

            if (!positions.hasOwnProperty(object.getProductDetails().id))
                positions[object.getProductDetails().id] = [];

            positions[object.getProductDetails().id].push(position);
        }

        [].forEach.call(_surface.getObjects(), function (obj) {
            if (obj.type === "group") {
                // If a Group, dive into it to process inner elements
                [].forEach.call(obj.getObjects(), function (objInner) {
                    appendPosition(objInner);
                });
            } else {
                appendPosition(obj);
            }
        });

        return positions;
    };

}