const { each } = require("jquery");

function MultiEdit() {

    this.init = function () {

        $(document).on("click", ".js__btn-multiedit", function () {

            var selections = [];
            $(".js__multiaction-select:checked").each(function (i, v) {
                selections.push($(v).val());
            });

            if (selections.length < 2) {
                alert("To perform multi action, please select 2 or more Items from the table");
                return false;
            }

            window.location.href = "/forms/"
                + $(this).data("entity")
                + "/multiedit?"
                + $(this).data("entity")
                + "="
                + selections.join(",");

        });
    };

}

var me = new MultiEdit();
me.init();
module.exports = me;