function BtnDelete() {

    this.init = function () {
        $(document).on("click", ".js__btn-delete", function () {

            if (!confirm("Are you sure you want to proceed with DELETE action?"))
                return false;

            var $button = $(this);
            var entity = $button.data("entity"),
                id = $button.data("id");

            _fireDeleteRequest(entity, id);
        });
    };

    var _fireDeleteRequest = function (entity, id) {

        globalCallbackHandler.handle("show-loader");

        $.ajax({
            url: `/${entity}/${id}`,
            type: "DELETE",
            success: function () {

                /*
                * Fire a DT reload event;
                * This will remove the deleted Record from the table,
                * and/or display an appropriate message if there are no Records left to show.
                */
                var reloadDatatablesEvent = new CustomEvent("datatable-reload-event", {});
                document.dispatchEvent(reloadDatatablesEvent);

                globalCallbackHandler.handle("hide-loader");
            },
            error: function () {
                alert("Could not complete the request! Please retry.");
                globalCallbackHandler.handle("hide-loader");
            }
        });
    };
}

var bd = new BtnDelete();
bd.init();
module.exports = bd;