/*
* If there is a hidden input marking Session/Cart completion landing,
* stop processing static Surface initialization;
* Instead, only load Stats, ShopCart, and execute Completion stat.
*/
var $seqCompletion = $(".js__seq-complete");
if ($seqCompletion.length > 0) {
    require("./sequence/stats_factory");
    require("./sequence/shopcart_manager");
    require("./sequence/completion");
}

/*
* If there is a hidden input marking Session/Cart termination landing,
* stop processing static Surface initialization;
* Instead, only load Stats end execute Termination stat.
* (Termination completes all the open Sessions/Carts of the given User)
*/
var $seqTermination = $(".js__seq-termination");
if ($seqTermination.length > 0) {
    require("./sequence/stats_factory");
    require("./sequence/termination");
}

var $surface = $("#js__surface-static");
if ($surface.length > 0) {
    import(/* webpackMode: 'lazy' */ "fabric-with-gestures")
        .then(Fabric => {
            require("./fabric_prototypes");
            var surfaceStatic = new SurfaceStatic();
            surfaceStatic.init();
        });
}

function SurfaceStatic() {

    var _surfaceContainer = document.querySelector(".js__surface-container");
    var _surface = null;

    var _isInSequence = false;

    var _shelfTemplateBg = null;

    var _surfaceOpeningTstamp = null;
    var _surfaceLoadedTstamp = null;

    var _shelfInstrTime = null;

    var _calcLog = "";

    this.init = function () {

        if (_surfaceContainer === null)
            return;

        /*
        * Show Loader first;
        * Keep it overlayed above the Surface until all the resources are loaded;
        * Otherwise, user actions may start coming in prematurely and mess the stats.
        */
        globalCallbackHandler.handle("show-loader");

        fabric.Object.prototype.hoverCursor = "pointer";

        /*
        * Determine if running a plain static mode (for sanity check)
        * or if User is running a Stats Sequence.
        */
        var statsSeqId = document.querySelector(".js__seq-id");
        if (statsSeqId)
            _isInSequence = true;

        _shelfTemplateBg = document.getElementById("js__img-shelf-template");

        globalSurfaceConfig.scaleFactor = _shelfTemplateBg.naturalWidth / _shelfTemplateBg.naturalHeight;

        /*
        * If in Stats Sequence, deduct sum of top & bottom bar height from the total screen height;
        * Reserve whatever height remains for the effective Surface inbetween those 2.
        */
        var effectiveHeight = window.innerHeight;
        if (_isInSequence) {
            var deductedHeight = document.querySelector(".js__surface-controls-container").clientHeight;

            if (document.querySelector(".js__surface-cart-container") !== null)
                deductedHeight += document.querySelector(".js__surface-cart-container").clientHeight;

            effectiveHeight -= deductedHeight;
        }

        globalSurfaceConfig.height = effectiveHeight;
        globalSurfaceConfig.effectiveWidth = globalSurfaceConfig.height * globalSurfaceConfig.scaleFactor;
        globalSurfaceConfig.width = globalSurfaceConfig.height * globalSurfaceConfig.ratio;

        /*
        * If effective width overflows screen boundaries, normalize the Surface:
        * First, set effective width to screen width;
        * Second, recalculate height based on the new effective width.
        */
        if (globalSurfaceConfig.effectiveWidth > window.innerWidth) {
            globalSurfaceConfig.effectiveWidth = window.innerWidth;
            globalSurfaceConfig.height = globalSurfaceConfig.effectiveWidth / globalSurfaceConfig.scaleFactor;
        }

        _calcLog += "Window width: " + window.innerWidth + "\n";
        _calcLog += "Window height: " + window.innerHeight + "\n";
        _calcLog += "Window ratio: " + globalSurfaceConfig.ratio + "\n";
        _calcLog += "-------\n";
        _calcLog += "BG width: " + _shelfTemplateBg.naturalWidth + "\n";
        _calcLog += "BG height: " + _shelfTemplateBg.naturalHeight + "\n";
        _calcLog += "BG ratio (w/h): " + globalSurfaceConfig.scaleFactor + "\n";
        _calcLog += "BG scale (to fit window): " + globalSurfaceConfig.bgScaleFactor + "\n";
        _calcLog += "-------\n";

        /*
        * If in EyeSee Desktop App (embedded in a native placeholder), 
        * but not in the Web App (embedded in an Iframe/web placeholder)
        * normalize screen in regard to EyeSee native Placeholder's width constraint.
        */
        if (_inEyeSeeApp() && !_inEyeSeeWP())
            _normalizeEyeSeeSurface();

        globalSurfaceConfig.oldScaleCoefficient = +document.querySelector(".js__surface-creation-scale").dataset.oldValue;

        /*
        * Intro Modal contains Intro Video & Details related to the Shelf Set;
        * Only toggle it on if inside a Shelf Set;
        * Only toggle for the first Shelf inside a Shelf Set, unless
        * explicitly configured the other way.
        */
        _toggleIntroModal();

        _initSessionCompletionControl();

        _surface = new fabric.Canvas("js__surface-static", {
            uniScaleTransform: false,
            preserveObjectStacking: true,
            allowTouchScrolling: true,
            targetFindTolerance: 2
        });
        _surface.setDimensions({
            width: globalSurfaceConfig.width,
            height: globalSurfaceConfig.height
        });

        /*
        * Same width determined for the Surface, may also be used for the upper controls bar;
        * This setting does not logically belong here, but keeping it for the time being.
        */
        $(".sequence-controls-inner").width(globalSurfaceConfig.width);

        globalSurfaceConfig.surface = _surface;
        globalSurfaceConfig.surfaceContainer = _surfaceContainer;

        _buildSurface();
        _processScreenResize();

        /*
        * In relation to SHEL-346 development and Shopcart InstrTime Manager:
        * 
        * This event is triggered on instructions close and is used to update
        * current instructions time (shelf-level for a given shelf opening/appearance)
        */
        $(document).on("surface-update-instrtime", function () {
            var e = arguments[0];
            _shelfInstrTime = e.detail.time;
        });

        $(document).on("surface-close", _closeSurface);

        /*
        * Bind the processor function to window exiting event;
        * In that moment, prior to leaving the current page,
        * emit the Shelf/Surface close event (including the 'ad', for duration).
        */
        window.addEventListener("beforeunload", _processCloseSurface);
    };

    /*
    * Central Surface Builder function;
    * Calculates screen size constraints and eventually old work (JSON) to set-up the Shelf Builder.
    */
    var _buildSurface = function () {

        var shelfJson = $(".js__surface-json").val();

        function htmlDecode(value) {
            return $("<div/>").html(value).text();
        }

        shelfJson = htmlDecode(shelfJson);

        /* 
        * Fire Surface open stat
        *
        * 18.06.2022.
        * SHEL-354
        * 
        * Extracted 'sh-open' event here (previously it was fired from _bootstrapSurface).
        * With the old setup, 'sh-loaded' would always follow up to 3 seconds after 'sh-open',
        * which was inaccurate - sometimes, shelves would have been loaded for even 10+ seconds, but the Report
        * would still say 2 or 3.
        * By pushing 'sh-open' all the way up, thus realistically extending the time gap between 'sh-open' & 'sh-loaded', 
        * the 'TIME TO LOAD' stat in the Report becomes accurate and meaningful.
        */
        require("./sequence/stats_factory");
        _surfaceOpeningTstamp = Math.floor(Date.now() / 1000);
        globalStatsFactory.fire({
            a: "sh-open",
            ats: _surfaceOpeningTstamp
        });
        globalCallbackHandler.handle("console-debug", {
            message: "Shelf opened",
            type: "DEBUG"
        });

        if (shelfJson == "") {
            _setBackgroundImage();
            _bootstrapSurface();
            globalCallbackHandler.handle("hide-loader");
            return;
        }

        _surface.loadFromJSON(JSON.parse(shelfJson), function () {
            _setBackgroundImage();

            /*
            * Sometimes, Surface remains blank (due to broken JSON load or similar cause);
            * In these cases, window reload resolves the issue;
            * Perform certain checks and reload the Window if necessary.
            */
            _reloadBrokenSurface();

            // Recalculate scale coefficient
            globalSurfaceConfig.scaleCoefficient = globalSurfaceConfig.oldScaleCoefficient / globalSurfaceConfig.bgScaleFactor;
            _bootstrapSurface();
        });
    };

    var _setBackgroundImage = function () {

        globalSurfaceConfig.bgScaleFactor = Math.max(
            globalSurfaceConfig.effectiveWidth / _shelfTemplateBg.naturalWidth,
            globalSurfaceConfig.height / _shelfTemplateBg.naturalHeight
        );

        $(".js__surface-creation-scale").val(globalSurfaceConfig.bgScaleFactor);

        fabric.Image.fromURL(
            _shelfTemplateBg.src,

            function (img) {

                img.set({
                    width: globalSurfaceConfig.effectiveWidth / globalSurfaceConfig.bgScaleFactor,
                    height: _surface.height / globalSurfaceConfig.bgScaleFactor,
                    left: _surface.getCenter().left,
                    top: _surface.getCenter().top,
                    originX: "center",
                    originY: "center",
                    offsetX: 0,
                    offsetY: 0,
                    scaleX: globalSurfaceConfig.bgScaleFactor,
                    scaleY: globalSurfaceConfig.bgScaleFactor,
                    crossOrigin: "anonymous"
                });

                _surface.setBackgroundImage(img);
                _surface.renderAll();

                globalCallbackHandler.handle("console-debug", {
                    message: _calcLog,
                    type: "LIGHT"
                });
            }
        );
    };

    var _bootstrapSurface = function () {

        var surfaceObjectsConstructor = require("./surface_objects_constructor");
        surfaceObjectsConstructor.setIsStaticDisplayOnly(true).construct();

        /*
        * Preset zoom controls & default zoom with no regard to
        * whether User is in the sequence;
        * Also init Zoom for static single Surface view.
        */
        require("./controls/surface_zoom_controls");

        if (!_isInSequence) {
            _hideLoader();
            return;
        }

        /*
        * Does not really belong here, but still it is a clear business;
        * Lock Products already existig in the ShoppingCart, if Sequence
        * configuration is such.
        * Perform this bind before initializing Cart stuff.
        */
        $(document).on("surface-multiblock-products", _multiObjectToggleBlock);

        /*
        * Include Tutorial first, and the other modules below;
        * It is important because Tutorial module contains a click/mouseover/mouseout event
        * propagation stopper, in case when Tutorial is launched.
        * 
        * To support this functionality, the event bound within the Tutorial module
        * must come first (and be the first click handler bound to targeted controls).
        */
        require("./sequence/tutorial");
        require("./sequence/survey_manager");
        require("./sequence/skip_reasons_modal");
        require("./sequence/shopcart_modal");
        require("./sequence/shopcart_instrtime_manager");
        require("./sequence/timespent_manager");
        require("./sequence/shopcart_controls");
        require("./sequence/shopcart_manager");
        require("./sequence/shopcart_hovercard");
        require("./sequence/intro_modal");
        require("./sequence/task_instructions_modal");

        /*
        * If not configured the opposite, there is Sidebar within the Sequence
        * in which all the belonging Shelves are listed for quick navigation purposes;
        * Initialize the behaviors of the sidebar.
        */
        _initSidebarNavigation();

        /*
        * Many of the Surface/Sequence configurations may be overriden using
        * URL hash parameters, e.g. Language, Currency...
        * Process these parameters first and override tpl embedded metadata inputs
        * with URL-hardcoded values, if any.
        */
        _processUrlOverrides();

        document.dispatchEvent(new CustomEvent("cart-sh-land"));

        _hideLoader();
    };

    var _hideLoader = function () {
        var hideLoaderTimeout = 500;
        setTimeout(function () {

            /*
            * If there are no VideoPanels on the Surface, emit hide-loader event;
            * But if there are VideoPanels embedded, don't hide the Loader here;
            * Instead, VP Manager will do it once all the Panels are loaded.
            */
            if (_surface.getVideoPanels().length === 0)
                globalCallbackHandler.handle("hide-loader");

            if (!_isInSequence)
                return;

            // Fire Surface loaded stat
            _surfaceLoadedTstamp = Math.floor(Date.now() / 1000);
            globalStatsFactory.fire({
                a: "sh-loaded",
                ats: _surfaceLoadedTstamp,
                ad: _surfaceLoadedTstamp - _surfaceOpeningTstamp
            });

            document.dispatchEvent(new CustomEvent("timespent-begin"));

            globalCallbackHandler.handle("console-debug", {
                message: `Shelf loaded / Cart start time (${_surfaceLoadedTstamp - _surfaceOpeningTstamp})`,
                type: "DEBUG"
            });

            /*
            * SHEL-346 & SHEL-360
            * 26.02.2022.
            * 
            * Shelf load time (obviously, of the 1st Shelf in a Sequence)
            * will be considered a Cart start time;
            * This will be a reference point for all Cart metrics.
            * 
            * Store this value (once) in LocalStorage.
            */
            var st = localStorage.getItem("VSV2_SEQ_CartStart");
            if (!st)
                localStorage.setItem("VSV2_SEQ_CartStart", _surfaceLoadedTstamp);

            document.dispatchEvent(new CustomEvent("cart-start"));

            document.dispatchEvent(new CustomEvent("sequence-tutorial-start"));

            /*
            * Save initial state of the Cart (empty Cart);
            * This save is important because actions are bound to Cart when building Report lines;
            * In that regard, if there were actions for considerations, but no Cart yet
            * (because no Cart action has yet been performed and Cart was not saved)
            * that case would result in "loose" actions (and lack of Report lines in the Excel file).
            * To prevent that data loss, save Cart initially immediatelly after emitting a
            * "sh-open" action.
            */
            document.dispatchEvent(new CustomEvent("cart-save"));

            /*
            * Cart limit(s) may have been exceeded, but User managed to workaround the
            * blocked/disabled Sequence navigation and open the Surface;
            * In those cases, re-check the limits and prevent further actions in regard
            * to freely navigating the Sequence (unless limits are respected and controls unlocked)
            */
            document.dispatchEvent(new CustomEvent("cart-validate-limits"));
        }, hideLoaderTimeout);
    };

    // Determine whether inside the eyesee standalone app
    var _inEyeSeeApp = function () {
        return window.location.href.indexOf("pt=true") >= 0;
    };

    /*
    * SHEL-251
    * 08.09.2021 
    * 
    * Determine whether inside the eyesee web app (web placeholder)
    */
    var _inEyeSeeWP = function () {
        return window.location.href.indexOf("wp=true") >= 0;
    };

    var _normalizeEyeSeeSurface = function () {
        var _eyeSeeScreenWidthLimitation = 1299;

        globalSurfaceConfig.width = _eyeSeeScreenWidthLimitation;
        if (globalSurfaceConfig.effectiveWidth > _eyeSeeScreenWidthLimitation) {
            globalSurfaceConfig.height /= (globalSurfaceConfig.effectiveWidth / _eyeSeeScreenWidthLimitation);
            globalSurfaceConfig.effectiveWidth = _eyeSeeScreenWidthLimitation;
        }

        var $panCtrls = $(".js__surface-panning-controls");
        $panCtrls.css({
            bottom: "auto",
            top: globalSurfaceConfig.height + document.querySelector(".js__surface-controls-container").clientHeight - $panCtrls.height() - 15
        });

        _calcLog += "1299-lmtd Surface width: " + globalSurfaceConfig.width + "\n";
        _calcLog += "1299-lmtd Surface height: " + globalSurfaceConfig.height + "\n";
        _calcLog += "1299-lmtd BG width: " + globalSurfaceConfig.effectiveWidth + "\n";
    };

    var _reloadBrokenSurface = function () {
        if (document
            .getElementById("js__surface-static")
            .toDataURL() === "data:,") {
            /*
             * This check and subsequent window reload are a fix for blank canvas bug.
             * Check if there is better way to handle FabricJS bug which causes
             * an empty surface to be displayed when data is loaded from JSON.
             */
            setTimeout(function () {
                window.location.reload();
            }, 2000);
        }

        if (document
            .getElementById("js__surface-static")
            .toDataURL()
            .length < 50000) {
            /*
             * More accurate condition would be === 35026.
             * Do a research on why this value of 35026 indicates broken canvas load.
             */
            setTimeout(function () {
                window.location.reload();
            }, 2000);
        }

        if (isNaN(globalSurfaceConfig.bgScaleFactor)) {
            /*
             * In case of huge shelves (with rotating ads or hundreds of products)
             * it happens that the first load breaks and user is served a blank page (even without the BG);
             * This is also a viable case for a reload.
             */
            setTimeout(function () {
                window.location.reload();
            }, 2000);
        }
    };

    var _processUrlOverrides = function () {

        if (!_isInSequence)
            return;

        var UrlParamsManager = require("./sequence/url_params_manager");
        UrlParamsManager.init();
    };

    var _toggleIntroModal = function () {
        var showIntroModal = false;
        if (document.querySelector(".js__seq-index") &&
            document.querySelector(".js__seq-index").value == 1)
            showIntroModal = true;

        if (document.querySelector(".js__seq-persist-intro") &&
            document.querySelector(".js__seq-persist-intro").value == 1)
            showIntroModal = true;

        if (!showIntroModal)
            return;

        var $modal = $("#js__seq-intro-modal");

        if ($modal.length === 0)
            return;

        /*
        * If Intro modal is completely empty
        * (no video inside and no text inside)
        * exit early and stop initializing;
        * Also, do not open modal on Sequence opening.
        */
        if ($modal.find(".modal-body").html().trim() === "")
            return;

        $modal.modal("show");

        var video = $(".js__seq-intro-modal-video").get(0);

        if (video) {

            var isPlaying = video.currentTime > 0 &&
                !video.paused &&
                !video.ended &&
                video.readyState > 2;

            if (!isPlaying)
                video.play();
        }

        $modal.on("hidden.bs.modal", function () {
            var video = $(".js__seq-intro-modal-video").get(0);

            if (video)
                video.pause();
        });
    };

    var _initSidebarNavigation = function () {
        $(document).on("click", ".js__seq-sidenav-btn", function () {

            /*
            * Clicked on the disabled control;
            * This control is disabled in case when Cart limits are exceeded,
            * and Sequence navigations need to be switched off.
            * Ignore the click if .disabled is present.
            */
            if ($(this).hasClass("disabled"))
                return;

            $(".js__sequence-sidenav").toggleClass("show");

            if ($(".js__sequence-sidenav").hasClass("show")) {
                import(/* webpackMode: 'lazy' */ "slick-carousel")
                    .then(Slick => {
                        $(".js__sequence-sidenav").slick({
                            infinite: false,
                            slidesToShow: 5,
                            slidesToScroll: 1,
                            dots: false,
                            arrows: true,
                            prevArrow: `<button type="button" class="slick-prev btn btn-sm btn-white btn-icon"><span class="icon-arrow-left2"></span></button>`,
                            nextArrow: `<button type="button" class="slick-next btn btn-sm btn-white btn-icon"><span class="icon-arrow-right2"></span></button>`,
                            responsive: [
                                {
                                    breakpoint: 1850,
                                    settings: {
                                        slidesToShow: 4
                                    }
                                },
                                {
                                    breakpoint: 1366,
                                    settings: {
                                        slidesToShow: 3
                                    }
                                },
                                ,
                                {
                                    breakpoint: 1100,
                                    settings: {
                                        slidesToShow: 2
                                    }
                                }
                            ]
                        });
                    });
                $(".js__sequence-sidenav").slideDown(250);
            } else {
                $(".js__sequence-sidenav").slideUp(250, () => { $(".js__sequence-sidenav").hide(); });
            }
        });
    };

    var _processCloseSurface = function (e) {

        if (!_isInSequence)
            return;

        /*
        * Shelf close was emitted from elsewhere, so no need to proceed here;
        * Exit early instead.
        */
        if (globalSurfaceConfig.isClosed)
            return;

        globalCallbackHandler.handle("console-debug", {
            message: `Closing Surface from the window beforeunload callback...`,
            type: "DEBUG"
        });

        _closeSurface();
    };

    var _closeSurface = function () {

        document.dispatchEvent(new CustomEvent("timespent-end"));

        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        // Fire Surface close stat
        var surfaceClosingTstamp = Math.floor(Date.now() / 1000);
        globalStatsFactory.fire({
            a: "sh-close",
            ats: surfaceClosingTstamp,
            ad: surfaceClosingTstamp - _surfaceLoadedTstamp - _shelfInstrTime
        }, isChrome);
        globalCallbackHandler.handle("console-debug", {
            message: `Shelf closed (time elapsed: ${surfaceClosingTstamp - _surfaceLoadedTstamp - _shelfInstrTime}s, instr. time: ${_shelfInstrTime}s)`,
            type: "DEBUG"
        });

        /*
        * Setting this property to true will make _closeSurface unreachable on window unload;
        * It is a global mutex for the sh-close action.
        */
        globalSurfaceConfig.isClosed = true;

        return null;
    };

    var _multiObjectToggleBlock = function () {

        var evt = arguments[0];
        var products = evt.detail.products;
        var blockOrUnblock = evt.detail.block;

        if (products.length === 0)
            return;

        /*
        * 04.08.2021
        * SHEL-233
        *
        * Targets may be either:
        * 1. All the copies of the same Product (same ID) anywhere on the Surface
        * 2. Copies of the Product, but also all the other Products with the same Product group,
        *   anywhere on the Surface
        * 
        * Prototype of the Surface/Canvas is extended to handle this sort of targeting;
        * Apply toggleBlockObject on every target object.
        */
        var targets = _surface.getBlockUnblockTargetsByProductIds(products);

        for (var productId in targets) {

            var target = targets[productId];

            /*
            * 02.09.2021
            * SHEL-243
            *
            * Each product within a group (or standalone product for that matter)
            * may be pinned more than on one place on the Surface;
            * Instead of only locking one instance of the Product,
            * lock them all.
            */
            if (target.constructor === Array) {
                target.forEach(function (subtarget) {
                    subtarget.toggleBlockObject(blockOrUnblock);
                });

            } else {
                target.toggleBlockObject(blockOrUnblock);
            }
        }

        _surface.renderAll();
    };

    var _initSessionCompletionControl = function () {

        if (!_isInSequence)
            return;

        $(document).on("click", ".js__seq-complete-btn", function () {
            /*
            * SHEL-250
            * 08.09.2021
            * 
            * Disabled because it breaks fullscreen mode when application
            * is embedded in an iframe in the wrapper application.
            */
            /*if (!confirm("Are you sure?\r\nThis will submit the Shopping Cart and close your Session."))
                return false;*/
        })

    };

    var _processScreenResize = function () {
        window.addEventListener("resize", function (e) {
            _surface.renderAll();
        });
    };

}