function CookieManager() {

    this.set = function (name, value, lifetimeDays) {
        var d = new Date();
        d.setTime(d.getTime() + (lifetimeDays * 24 * 60 * 60 * 1000));
        var expiry = "expires=" + d.toUTCString();
        document.cookie = name + "=" + value + ";" + expiry + ";path=/";
    };

    this.get = function (name) {
        name += "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var dc = decodedCookie.split(";");

        for (var i = 0; i < dc.length; i++) {

            var c = dc[i];

            while (c.charAt(0) == " ")
                c = c.substring(1);

            if (c.indexOf(name) == 0)
                return c.substring(name.length, c.length);
        }

        return null;
    };

    this.delete = function (name) {
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    };
}

module.exports = new CookieManager();