function ExtraConsiderationsManager() {

    this.init = function () {
        $(document).on("shlv-extra-considerations-register", _registerConsideration);
        $(document).on("shlv-extra-considerations-reset", _reset);
    };

    this.bootstrap = function () {
        _import();
    };

    var _reset = function () {
        _collection = {};
        _export();
    };

    var _registerConsideration = function () {
        var evt = arguments[0];
        var extraId = evt.detail.eid;
        var shelfId = evt.detail.shid;

        if (extraId === null || extraId == undefined ||
            shelfId === null || shelfId == undefined)
            return;

        if (!_collection.hasOwnProperty(shelfId))
            _collection[shelfId] = {};

        if (!_collection[shelfId].hasOwnProperty(extraId))
            _collection[shelfId][extraId] = { v: 0 };

        /*
        * Register a new consideration
        * by incrementing the previous amount of views
        * for the given ShelfId for the given ExtraId.
        */
        _collection[shelfId][extraId].v++;
        _export();
        /*
        * After a new Consideration was created,
        * re-export URL appendix decorations to 'Next' btn href.
        */
        document.dispatchEvent(new CustomEvent("sequence-appendix-allnavs"));
    };

    var _export = function () {
        localStorage.setItem("VSV2_SEQ_Extra_Considerations", JSON.stringify(_collection));
    };

    var _import = function () {
        var cons = localStorage.getItem("VSV2_SEQ_Extra_Considerations");

        /*
        * First, reset the collection;
        * Import operation below will re-populate it accordingly.
        */
        _collection = {};

        /*
        * If it did not exist in the LS, perform an 'empty' init;
        * This way init a new considerations collection.
        */
        if (!cons) {
            _export();
            return;
        }

        _collection = JSON.parse(cons);
    };

    var _collection = {};

}

var ecm = new ExtraConsiderationsManager();
ecm.init();
module.exports = ecm;