function BtnClone() {

    this.init = function () {

        $(document).on("click", ".js__btn-clone", function () {
            var message = "Please note: This will clone the existing entity in-depth.\r\n";
            message += "This means that copy action will also include dependancies, files...\r\n";
            message += "Click OK to proceed, or Cancel.";
            if (!confirm(message))
                return false;

            globalCallbackHandler.handle("show-loader");
        });

    };
}

var bc = new BtnClone();
bc.init();
module.exports = bc;