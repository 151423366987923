function VpSelectionModal() {

    this.init = function () {
        $("#js__vpanel-selection-modal").on("show.bs.modal", _fetchVideoPanelOptions);
    };

    var _fetchVideoPanelOptions = function () {
        globalCallbackHandler.handle("show-loader");

        $.ajax({
            url: "/utilities/video-panels",
            method: "GET",
            success: function (response) {
                response = JSON.parse(response);
                $(".js__vpanel-selection").html(response.html);
                globalCallbackHandler.handle("hide-loader");
            },
            error: function (response) {
                alert("ERROR while fetching data");
                globalCallbackHandler.handle("hide-loader");
            }
        });
    };

}

var vpm = new VpSelectionModal();
vpm.init();
module.exports = vpm;