function StatsFactory() {

    this.fire = function (data, async = true) {

        var statsSeqId = document.querySelector(".js__seq-id");

        /*
        * Do not emit events when in basic Static view;
        * Basic Static view is used to sanity check Shelves,
        * but should not emit any of the events as it would cause
        * analytics pollution;
        * 
        * Determine based on whether statsSeqId DOM element exists;
        * This element will only be embedded in the DOM if a proper 
        * Stats Sequence (Shelf Set) is running.
        */
        if (!statsSeqId)
            return;

        /*
        * Switched OFF here;
        * Exists already on the PHP side, Session-based;
        * If at some point FE becomes fully separated (and thus Session
        * becomes unavailable),
        * switch these ON here.
        */
        var data = $.extend(data, {
            /*
            * 25.07.2022.
            *
            * These props used to be decorated on the BE/PHP side, from the Session.
            * 
            * What caused bugs is that sometimes, although rarely, async requests
            * between this Factory and BE were stalled for long enough for Session reset
            * to happen on the BE;
            * 
            * Effectively, it would cause certain signals to be taken over by BE side and passed on
            * to API application with both SID and SSID empty.
            * 
            * Refer to SHEL-359, SHEL-361, PLP-56, PLP-57 for details on the bugs.
            */
            shid: $(".js__surface-shelf-id").val(),
            ssid: $(".js__seq-id").val(),
            sid: $(".js__seq-sessid").val()
        });

        $.ajax({
            url: "/stats",
            method: "POST",
            data: data,
            async: async,
            success: function (response) {
                /*globalCallbackHandler.handle("console-debug", {
                    message: data.a.toUpperCase() + (data.ad ? ` (${data.ad})` : ""),
                    type: "LIGHT"
                });*/
            },
            error: function (response) {
                globalCallbackHandler.handle("console-debug", {
                    message: `Stat emit failed (${data.a})`,
                    type: "ERROR"
                });
            }
        });

    };
}

global.globalStatsFactory = new StatsFactory();