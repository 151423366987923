function TaskInstructionsModal() {

    var _queryParams = "";
    var $_body = null;

    this.init = function () {

        $("#js__seq-info-modal").on("shown.bs.modal", () => {
            /*
            * Emit event in relation to SHEL-346;
            * Refer to ShopCart Manager for more information.
            */
            document.dispatchEvent(new CustomEvent("cart-instr-begin"));
        });

        $("#js__seq-info-modal").on("hidden.bs.modal", () => {
            /*
            * Emit event in relation to SHEL-346;
            * Refer to ShopCart Manager for more information.
            */
            document.dispatchEvent(new CustomEvent("cart-instr-end"));
        });

        if ($("#js__seq-info-modal").length === 0 && $(".js__seq-findability-label").length === 0)
            return;

        $_body = $("#js__seq-info-modal").find(".modal-body");

        _loadUriParams();

        /*
        * Limit needs to be handled here, above the _queryParams.length condition;
        * This is because limit can be provided via URL, but also via Shelfset settings.
        * cat & sku are URL-only.
        */
        _placeholder2Value("limit", _params, $(".js__seq-limit-items"));

        if (_queryParams.length === 0)
            return;

        _placeholder2Value("cat", _params, null);
        _placeholder2Value("sku", _params, null);

        // for findability, change what the body is and do a 'sku' replacement
        $_body = $(".js__seq-findability-label");
        _placeholder2Value("sku", _params, null);
    };

    var _loadUriParams = function () {

        if (window.location.search.length > 0)
            _queryParams = window.location.search.substring(1);

        // first, reload _params
        _params = {};

        if (_queryParams === "")
            return;

        _queryParams.split("&").map(function (param) {
            let temp = param.split("=");
            _params[temp[0]] = temp[1];
        });
    };

    var _placeholder2Value = function (phName, queryParams, $jqPlaceholder = null) {

        if ($_body.length === 0)
            return;

        var html = $_body.html();

        if ($jqPlaceholder !== null && $jqPlaceholder instanceof jQuery) {
            var phVal = decodeURIComponent($jqPlaceholder.val());
            /*
            * SHEL-372
            * 16.10.2022
            * 
            * If limit is not set, don't show "(maximum ---limit---)";
            * If limit is 0, don't show "(maximum 0)";
            * If limit is > 0, show "(maximum ---limit---)"
            */
            if (phName === "limit" && (isNaN(phVal) || +phVal === 0)) {
                html = html.replace(/\ \((.*?)\ ?---limit---\)/g, '');
            } else {
                html = _replaceAll(html, "---" + phName + "---", phVal);
            }
            $_body.html(html);
            return;
        }

        if (!(queryParams.hasOwnProperty(phName) && queryParams[phName] != undefined))
            return;

        var phVal = decodeURIComponent(queryParams[phName]);
        /*
        * SHEL-372
        * 16.10.2022
        * 
        * If limit is not set, don't show "(maximum ---limit---)";
        * If limit is 0, don't show "(maximum 0)";
        * If limit is > 0, show "(maximum ---limit---)"
        */
        if (phName === "limit" && (isNaN(phVal) || +phVal === 0)) {
            html = html.replace(/\ \((.*?)\ ?---limit---\)/g, '');
        } else {
            html = _replaceAll(html, "---" + phName + "---", phVal);
        }
        $_body.html(html);
    };

    var _replaceAll = function (str, find, replace) {
        return str.replace(new RegExp(find, "g"), replace);
    };
}

var tim = new TaskInstructionsModal();
tim.init();