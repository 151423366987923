function UrlAppendixManager() {

    var _module = this;

    this.init = function () {
        // stub
        var $nextBtn = $(".js__seq-next-btn");
        $nextBtn.each((i, nextBtn) => {
            $(document).on("sequence-appendix-next", _module.appendixForRedirectUrl.bind(nextBtn));
        });

        $(document).on("sequence-appendix-allnavs", function () {
            var $seqNavs = $(".js__seq-navlink");
            $seqNavs.each(function (i, $seqNav) {
                _module.appendixForRedirectUrl.bind($seqNav).call();
            });
        });
    };

    this.appendixForRedirectUrl = function () {
        var $redirBtn = $(this);
        var redirUrl = $redirBtn.attr("href");

        if (redirUrl == undefined)
            return;

        var si = _extractUrlParameter(redirUrl, "si");

        redirUrl = redirUrl.split("?")[0];

        if (si === null)
            redirUrl += "?si=1";
        else
            redirUrl += "?si=" + si;

        var concat = "?";
        if (redirUrl.indexOf("?") !== -1)
            concat = "&";

        var UrlParamsManager = require("./url_params_manager");
        var appendix = UrlParamsManager.getUriParams();

        if (appendix && appendix != undefined && appendix.length > 0)
            redirUrl += `${concat}${appendix}`;

        /*
        * Reset "nobuy" parameter; 
        * This one should not propagate to the next Shelf's nav btns.
        * Still, if encountered a speedrunner btn, add "nobuy" param explicitly to it.
        * 
        * Specifically, leave the "nobuy" parameter for the Completion page's Finish button.
        * More details in: SHEL-231
        */
        if (!$(this).hasClass("js__seq-redirect-url-btn"))
            redirUrl = _removeUrlParameter(redirUrl, "nobuy");

        var concat = "?";
        if (redirUrl.indexOf("?") !== -1)
            concat = "&";

        if ($redirBtn.hasClass("js__seq-speedrunner-btn"))
            redirUrl += `${concat}nobuy=`;

        /*
        * Remove whatever was previously assigned to these 3 query params;
        * Below an up-to-date override will be performed, so these old
        * values are redundant/deprecated.
        */
        redirUrl = _removeUrlParameter(redirUrl, "products");
        redirUrl = _removeUrlParameter(redirUrl, "considerations");
        redirUrl = _removeUrlParameter(redirUrl, "special-considerations");
        redirUrl = _removeUrlParameter(redirUrl, "timespent");

        concat = "?";
        if (redirUrl.indexOf("?") !== -1)
            concat = "&";

        var productsAppendix = _processProductsApendix();

        if (productsAppendix !== null && productsAppendix != undefined)
            redirUrl += `${concat}${productsAppendix}`;

        var timespentAppendix = _processTimespentAppendix();
        if (timespentAppendix !== null && timespentAppendix != undefined && timespentAppendix !== "")
            redirUrl += `${concat}${timespentAppendix}`;

        redirUrl = _sanitizeUrl(redirUrl);

        $redirBtn.attr("href", redirUrl);
        //$redirBtn.removeClass("btn-secondary");
        //$redirBtn.addClass("btn-success");

        if ($redirBtn.prop("disabled") === true)
            return;

        $redirBtn.removeClass("disabled");
    };

    var _processProductsApendix = function () {

        var cart = localStorage.getItem("VSV2_SEQ_ShoppingCart");
        cart = JSON.parse(cart);

        var productsPerShelves = localStorage.getItem("VSV2_SEQ_ProductsOnShelves");
        productsPerShelves = JSON.parse(productsPerShelves);

        if (!productsPerShelves || productsPerShelves === null)
            return null;

        if (!cart.hasOwnProperty("prods") ||
            cart.prods == undefined ||
            cart.prods.length === 0)
            return null;

        var appendix = "";

        if (Object.keys(cart.prods).length > 0) {
            appendix = "products=";

            //products=shelfid_productid_quantity
            for (var prodId in cart.prods) {

                if (!productsPerShelves.hasOwnProperty(prodId))
                    continue;

                for (var shelfId in productsPerShelves[prodId])
                    appendix += `${shelfId}_${prodId}_${productsPerShelves[prodId][shelfId].qtyChange};`;
            }
        }

        var productConsiderations = localStorage.getItem("VSV2_SEQ_Product_Considerations");

        if (productConsiderations === null)
            return;

        productConsiderations = JSON.parse(productConsiderations);

        if (Object.keys(productConsiderations).length > 0) {
            appendix += "&considerations=";

            //productConsiderations=shelfid_productid
            for (var shelfId in productConsiderations) {
                for (var productId in productConsiderations[shelfId]) {
                    appendix += `${shelfId}_${productId};`;
                }
            }
        }

        var extrasConsiderations = localStorage.getItem("VSV2_SEQ_Extra_Considerations");

        if (extrasConsiderations === null)
            return;

        extrasConsiderations = JSON.parse(extrasConsiderations);

        if (Object.keys(extrasConsiderations).length > 0) {
            appendix += "&special-considerations=";

            //special-considerations=shelfid_extraid
            for (var shelfId in extrasConsiderations) {
                for (var extraId in extrasConsiderations[shelfId]) {
                    appendix += shelfId + "_" + encodeURIComponent(extraId) + ";";
                }
            }
        }

        if (appendix === "")
            return null;

        return appendix;
    };

    var _processTimespentAppendix = function () {
        var appendix = "";

        var timespent = localStorage.getItem("VSV2_SEQ_Timespent");

        if (timespent === null)
            return null;

        timespent = JSON.parse(timespent);

        if (Object.keys(timespent).length === 0)
            return null;

        appendix += "&timespent=";

        for (var shelfId in timespent) {
            appendix += shelfId + "_" + timespent[shelfId] + ";";
        }

        return appendix;
    };

    var _removeUrlParameter = function (urlString, name) {

        if (!_validURL(urlString)) {
            /*
            * Javascript URL constructor expects a fully legit URL (protocol, domain...),
            * and will break if only pathname & query string are supplied;
            * Work around this limitation by prepending a mock domain (https).
            */
            urlString = "https://mock.com" + urlString;

            if (!_validURL(urlString))
                return;
        }

        var url = new URL(urlString);
        url.searchParams.delete(name);
        urlString = url.href.replace("https://mock.com", "");
        return urlString;
    };

    var _extractUrlParameter = function (url, key) {

        if (typeof (url) === "undefined")
            url = window.location.href;

        var match = url.match("[?&]" + key + "=([^&]+)");
        return match ? match[1] : null;
    }

    var _validURL = function (str) {
        let url;
        try {
            url = new URL(str);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    };

    var _sanitizeUrl = function (url) {

        url = url.replace(new RegExp("&&", "g"), "&"); // can be multiple occurrences 
        url = url.replace("??", "?");

        url = _stripDuplicateUrlParams(url);
        /*
        * rtrim "&" or "?" if nothing follows after one these symbols
        */
        url = url.replace(/\?+$/g, "");
        url = url.replace(/&+$/g, "");
        return url;
    };

    var _stripDuplicateUrlParams = function (url) {

        var urlparts = url.split("?");

        if (urlparts.length >= 2) {

            var stuff = urlparts[1];
            pars = stuff.split("&");
            var comps = {};
            for (i = pars.length - 1; i >= 0; i--) {
                spl = pars[i].split("=");
                comps[spl[0]] = spl[1];
            }
            pars = [];
            for (var a in comps)
                pars.push(a + "=" + comps[a]);

            url = urlparts[0] + "?" + pars.reverse().join("&");
            return url;
        } else {
            return url;
        }
    }

}

var urlam = new UrlAppendixManager();
urlam.init();
module.exports = urlam;