function ShopcartInstrTimeManager() {

    this.init = function () {
        /*
        * 22.05.2022.
        * SHEL-346
        * 
        * Time spent reading Tutorial and Shopping instructions should not be included
        * in the times spent on Shelf - it should be deducted from all the metric times;
        * 
        * Store times spent on Tutorials in the Instruction time (property name 'it'), 
        * and always deduct 'it' value from timestamp-type metrics.
        */
        $(document).on("cart-instr-begin", _begin);
        $(document).on("cart-instr-end", _end);

        _currentShelf = document.querySelector(".js__surface-shelf-id").value;

        _preloadIts();
    };

    var _preloadIts = function () {
        var its = localStorage.getItem("VSV2_SEQ_InstrTimes");

        /*
        * First, reset the collection;
        * Import operation below will re-populate it accordingly.
        */
        _instrTimes = {};

        /*
        * If it did not exist in the LS, perform an 'empty' init;
        * This way init a new considerations collection.
        */
        if (!its)
            return;

        _instrTimes = JSON.parse(its);

        Object.keys(_instrTimes).forEach(function (key) {
            _instrTimeTotal += _instrTimes[key];
        });
    };

    var _begin = function () {
        _instrTstampBegin = Math.floor(Date.now() / 1000);
        globalCallbackHandler.handle("console-debug", {
            message: `Instr. block begin ${_instrTstampBegin}`,
            type: "DEBUG"
        });
    };

    var _end = function () {

        if (_instrTstampBegin === 0)
            return;

        var instrTstampEnd = Math.floor(Date.now() / 1000);

        if (!_instrTimes.hasOwnProperty(_currentShelf))
            _instrTimes[_currentShelf] = 0;

        var it = instrTstampEnd - _instrTstampBegin;
        _instrTimes[_currentShelf] += it;
        _instrTimeTotal += it;
        _instrTimeCurrent += it;
        /*
        * Reset start time just in case;
        * This should anyhow ensure that it is impossible to step in the _end function block twice by mistake,
        * which would double increment the instruction times.
        */
        _instrTstampBegin = 0;

        globalCallbackHandler.handle("console-debug", {
            message: `Instr. block end: (new ${it}s, shelftotal: ${_instrTimes[_currentShelf]}s, sessiontotal: ${_instrTimeTotal}s)`,
            type: "DEBUG"
        });

        var event = new CustomEvent("cart-instr-update", {
            detail: {
                time: _instrTimeTotal
            }
        });
        document.dispatchEvent(event);

        var event = new CustomEvent("surface-update-instrtime", {
            detail: {
                time: _instrTimeCurrent
            }
        });
        document.dispatchEvent(event);

        localStorage.setItem("VSV2_SEQ_InstrTimes", JSON.stringify(_instrTimes));
    };

    this.getCurrent = function () {
        return _instrTimeCurrent;
    };

    this.getTotal = function () {
        return _instrTimeTotal;
    };

    var _currentShelf = null;
    var _instrTstampBegin = 0;
    var _instrTimes = {};
    var _instrTimeTotal = 0;
    var _instrTimeCurrent = 0;
}

var sitm = new ShopcartInstrTimeManager();
sitm.init();
module.exports = sitm;