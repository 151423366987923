const { default: tinymce } = require("tinymce");

function ShelfSetEnvelopeSelector() {

    this.init = function () {

        $(document).on("changed.bs.select", ".js__shelfset-envelope-selector", (event, clickedIndex, isSelected, previousValue) => {
            var envelopeId = $(event.target).selectpicker("val");
            $.ajax({
                url: "/envelopes/" + envelopeId + "/data",
                method: "GET",
                beforeSend: () => {
                    globalCallbackHandler.handle("show-loader");
                },
                success: (response) => {
                    var envelopeArray = JSON.parse(response);
                    _presetForm(envelopeArray);
                    globalCallbackHandler.handle("hide-loader");
                },
                error: () => {
                    alert("Error while selecting Envelope!");
                    globalCallbackHandler.handle("hide-loader");
                }
            })
        });
    };

    var _presetForm = function (envelope) {

        import(/* webpackMode: 'lazy' */ "tinymce")
            .then(_ => {
                $(".js__shelfset-language").val(envelope.Language);
                $(".js__shelfset-tasktype-selection").val(envelope.TaskType);
                $(".js__shelfset-sequencing").val(envelope.Sequencing);
                $(".js__shelfset-limit-qty").val(envelope.LimitItems);
                $(".js__shelfset-limit-funds").val(envelope.LimitFunds);
                $(".js__shelfset-limit-products").val(envelope.LimitProducts);
                $(".js__shelfset-limit-bulk-products").val(envelope.LimitBulkProducts);
                $(".js__shelfset-completion-redirect").val(envelope.CompletionRedirect);
                $(".js__shelfset-survey-url").val(envelope.SurveyUrl);

                _.get("js__shelfset-intm-text").setContent(envelope.IntroModalText);
                $(".js__shelfset-intm-autoplay").val(envelope.IntroModalAutoplay);

                $(".js__shelfset-infm-hidden").prop("checked", !!envelope.InfoModalHidden);
                $(".js__shelfset-infm-btn-label").val(envelope.InfoModalBtnLabel);
                $(".js__shelfset-infm-title").val(envelope.InfoModalTitle);
                _.get("js__shelfset-infm-instr1").setContent(envelope.ShoppingInstructions1);
                _.get("js__shelfset-infm-instr2").setContent(envelope.ShoppingInstructions2);
                _.get("js__shelfset-infm-instr3").setContent(envelope.ShoppingInstructions3);
                _.get("js__shelfset-infm-instr4").setContent(envelope.ShoppingInstructions4);
                _.get("js__shelfset-infm-instr5").setContent(envelope.ShoppingInstructions5);

                $(".js__shelfset-modal-stdw").val(envelope.ProductModalStandardWidth);
                $(".js__shelfset-modal-stcw").val(envelope.ProductModalStaticWidth);

                $(".js__shelfset-speedrun-show").prop("checked", !!envelope.SpeedrunnerBtn);
                $(".js__shelfset-speedrun-label").val(envelope.SpeedrunnerBtnLabel);

                $(".js__shelfset-aside-nav").prop("checked", !!envelope.CcfgAsideNavigation);
                $(".js__shelfset-backwards-nav").prop("checked", !!envelope.CcfgBackNavigation);
                $(".js__shelfset-cart-hidden").prop("checked", !!envelope.CartPanelHidden);
                $(".js__shelfset-cart-show-limitations").prop("checked", !!envelope.CartPanelShowLimitations);
                $(".js__shelfset-lock-added-product").prop("checked", !!envelope.CcfgLockAddedProduct);
                $(".js__shelfset-disable-tooltips").prop("checked", !!envelope.CcfgDisableProductTooltips);
                $(".js__shelfset-disable-tooltips").prop("checked", !!envelope.CcfgDisableProductTooltips);

            });

    };
}

var sses = new ShelfSetEnvelopeSelector();
sses.init();
module.exports = sses;