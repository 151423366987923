function SurveyManager() {

    var _surveyUrl = null;

    this.init = function () {

        if (!document.querySelector(".js__seq-id"))
            return;

        if (!document.querySelector(".js__seq-survey-url"))
            return;

        _surveyUrl = document.querySelector(".js__seq-survey-url").value;

        $(document).on("survey-product-consideration", _emitConsideration)
    };

    var _emitConsideration = function () {

        if (!_surveyUrl)
            return;

        var evt = arguments[0];

        var prodId = evt.detail.pid,
            quantity = evt.detail.qty;

        var concat = "?";
        if (_surveyUrl.indexOf("?") !== -1)
            concat = "&";

        var url = _surveyUrl + `${concat}product=${prodId}&quantity=${quantity}`;

        import(/* webpackMode: 'lazy' */ "./url_params_manager")
            .then(UrlParamsManager => {
                var appendix = UrlParamsManager.getUriParams();

                if (appendix && appendix.length > 0)
                    url += `&${appendix}`;

                try {
                    var win = window.open(url, "_blank", "width=1,height=1");
                    win.close();
                } catch (error) {
                    console.error("Invalid Survey URL");
                }
            });
    };

    this.open = function () {
        var win = window.open(_surveyUrl, "_blank");
        win.focus();
    };

}

var sm = new SurveyManager();
sm.init();
module.exports = sm;