require("datatables.net");
var TableState = require("./table_state");

function DataTables() {

    var table_;
    var entity_;
    var tableState_ = {};
    // preserve table state for 7 days
    var tableStateDuration_ = 7 * 24 * 60 * 60;

    this.init = function () {

        var $dt = $(".js__datatable");

        if ($dt.length === 0)
            return;

        $dt.each(function (i, v) {
            var $table = $(v);

            entity_ = $table.data("entity");
            tableState_ = TableState.popState(entity_);

            var ajaxConfig = {
                url: "/" + entity_,
                type: "GET"
            };

            _decorateAjaxConfigWithPresets(ajaxConfig);

            table_ = $table.DataTable({
                paging: true,
                ordering: true,
                searching: true,
                ajax: ajaxConfig,
                columnDefs: [
                    {
                        targets: 0,
                        searchable: false,
                        orderable: false
                    }
                ],
                order: [
                    [1, "asc"]
                ],
                sorting: [
                    [1, "asc"]
                ],
                serverSide: true,
                stateSave: true,
                stateDuration: tableStateDuration_,
                language: {
                    search: "",
                    searchPlaceholder: "Search Table...",
                    infoEmpty: "No Records to show",
                    emptyTable: "No Records to show",
                    zeroRecords: "0 Records matched the Filters"
                }
            });
        });

        _handleDtReloadEvent();
    };

    var _handleDtReloadEvent = function () {
        $(document).on("datatable-reload-event", function () {
            $(".js__datatable").DataTable().ajax.reload();
        });
    }

    var _decorateAjaxConfigWithPresets = function (ajaxConfig) {

        var filters = {};

        $(".js__datatable-filter").each(function (i, v) {
            var $filter = $(v),
                name = $(v).attr("name"),
                value = $(v).val();
            filters[name] = value;
        });

        ajaxConfig.data = filters;
    };
}

var dt = new DataTables();
dt.init();
module.exports = dt;