function TimespentManager() {

    this.init = function () {
        _currentShelf = document.querySelector(".js__surface-shelf-id").value;

        $(document).on("timespent-begin", _begin);
        $(document).on("timespent-end", _end);
        $(document).on("timespent-reset", _reset);

        _import();
    };

    var _reset = function () {
        _collection = {};
        _export();
    };

    var _begin = function () {
        _start = Math.floor(Date.now() / 1000);
        globalCallbackHandler.handle("console-debug", {
            message: `Timespan block begin ${_start}`,
            type: "DEBUG"
        });
    };

    var _end = function () {

        if (_start === 0)
            return;

        var end = Math.floor(Date.now() / 1000);

        if (!_collection.hasOwnProperty(_currentShelf))
            _collection[_currentShelf] = 0;

        var itm = require("./shopcart_instrtime_manager");

        var tspent = end - _start - itm.getCurrent();
        _collection[_currentShelf] += tspent;

        /*
        * Reset start time just in case;
        * This should anyhow ensure that it is impossible to step in the _end function block twice by mistake,
        * which would double increment the spent times.
        */
        _start = 0;

        globalCallbackHandler.handle("console-debug", {
            message: `Timespent block end: (new ${tspent}s)`,
            type: "DEBUG"
        });

        _export();
        /*
        * After a new Timespent was created,
        * re-export URL appendix decorations to 'Next' btn href.
        */
        document.dispatchEvent(new CustomEvent("sequence-appendix-allnavs"));
    };

    var _export = function () {
        /*
        * If Cart was reset from the LocalStorage, reset the collection too;
        * Otherwise, collection will be exported into LocalStorage even if Cart was reset,
        * because 'timespent-end' happens after Cart is already gone.
        */
        if (!localStorage.getItem("VSV2_SEQ_ShoppingCart"))
            _collection = {};

        localStorage.setItem("VSV2_SEQ_Timespent", JSON.stringify(_collection));
    };

    var _import = function () {
        var tss = localStorage.getItem("VSV2_SEQ_Timespent");

        /*
        * First, reset the collection;
        * Import operation below will re-populate it accordingly.
        */
        _collection = {};

        /*
        * If it did not exist in the LS, perform an 'empty' init;
        * This way init a new timespents collection.
        */
        if (!tss)
            return;

        _collection = JSON.parse(tss);
    };

    var _currentShelf = null;
    var _collection = {};
    var _start = 0;

}

var tsm = new TimespentManager();
tsm.init();
module.exports = tsm;