var $richtext = $("textarea.js__rte");

var tinyMCEToolbar1 = "undo redo | code | styleselect | fontsizeselect | cut copy paste | removeformat | searchreplace | bold italic underline | outdent indent | bullist numlist | link unlink | alignleft aligncenter alignright alignjustify | superscript subscript | forecolor | backcolor";
var tinyMCEToolbar2 = "charmap | visualchars visualblocks";

if ($richtext.length > 0) {
    import(/* webpackMode: 'lazy' */ "tinymce")
        .then(_ => {

            import(/* webpackMode: 'lazy' */ "tinymce/themes/silver").then(() => { });
            require("tinymce/icons/default");
            require("tinymce/plugins/textcolor");
            require("tinymce/plugins/paste");
            require("tinymce/plugins/link");
            require("tinymce/plugins/charmap");
            require("tinymce/plugins/searchreplace");
            require("tinymce/plugins/visualchars");
            require("tinymce/plugins/visualblocks");
            require("tinymce/plugins/code");
            require("tinymce/plugins/lists");

            tinymce.init({
                selector: "textarea.js__rte",
                height: 300,
                width: "100%",
                toolbar_items_size: "small",
                plugins: ["textcolor link charmap searchreplace visualchars visualblocks code lists"],
                toolbar1: tinyMCEToolbar1,
                toolbar2: tinyMCEToolbar2,
                menubar: "none",
                //by default, content is being pasted as clear (unformatted) text
                paste_as_text: true,
                setup: function (editor) {
                    editor.on("change input keyup", function () {
                        editor.save();
                    });
                }
            });
        });
}